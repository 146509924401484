import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Box,
  Stack,
  Typography,
  Tabs,
  Tab,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import {
  ButtonContainedGreen,
  FadeInSection,
  LogoItem,
} from "../../../components";

import BootcampCarousel from "./resource-carousel";

import EcosystemDiagram from "./utils/ecosystem-diagram/ecosystem-diagram";

import { Translate } from "react-auto-translate";

import "./home.css";
import GreenItalicText from "../../../components/common/green-italic-text/green-italic-text";
import CisoScrollSection from "../../../components/common/ciso-scrolling/ciso-scrolling";

import {
  customersDesktop,
  customersMobile,
  threats,
  categorySolutions,
  aiPoints,
  benefits,
  investors,
} from "../../../utils/constants/website/home";

function Home() {
  const [selectedTabSolution, setSelectedTabSolution] = useState(
    categorySolutions[0].solutions[0],
  );
  const [selectedTabSolutionTitle, setSelectedTabSolutionTitle] = useState(
    categorySolutions[0].solutions[0].title,
  );
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0);

  const [selectedAIPoint, setSelectedAIPoint] = useState(aiPoints[0]);
  const [hoveredThreat, setHoveredThreat] = useState(null);

  const productRef = useRef(null);
  const quantifyRiskRef = useRef(null);
  const remediateRiskRef = useRef(null);
  const adaptiveProtectionRef = useRef(null);
  const behavioralInsightsRef = useRef(null);

  const location = useLocation();

  const handleChangeSelectSolution = (event) => {
    setSelectedTabSolutionTitle(event.target.value);

    const allSolutions = categorySolutions
      .flatMap((category) => category.solutions)
      .sort((a, b) => a.idx - b.idx);

    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === event.target.value,
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }
  };

  const handleChangeTabSolution = (event, newValue) => {
    setSelectedTabSolutionTitle(newValue);

    const allSolutions = categorySolutions
      .flatMap((category) => category.solutions)
      .sort((a, b) => a.idx - b.idx);

    const filteredSolutions = allSolutions.filter(
      (solution) => solution.title === newValue,
    );

    if (filteredSolutions.length > 0) {
      setSelectedTabSolution(filteredSolutions[0]);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentCategorySolutions =
        categorySolutions[currentCategoryIndex].solutions;

      setCurrentSolutionIndex((prevSolutionIndex) => {
        const nextSolutionIndex =
          (prevSolutionIndex + 1) % currentCategorySolutions.length;

        setSelectedTabSolution(currentCategorySolutions[nextSolutionIndex]);
        setSelectedTabSolutionTitle(
          currentCategorySolutions[nextSolutionIndex].title,
        );

        return nextSolutionIndex;
      });

      setCurrentCategoryIndex((prevCategoryIndex) => {
        if (currentSolutionIndex === currentCategorySolutions.length - 1) {
          return (prevCategoryIndex + 1) % categorySolutions.length;
        }
        return prevCategoryIndex;
      });
    }, 10000000000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSolutionIndex, currentCategoryIndex]);

  useEffect(() => {
    const aiPointTimeout = setTimeout(() => {
      const currentAIPointID = selectedAIPoint.id;

      if (currentAIPointID === aiPoints.length - 1) {
        setSelectedAIPoint(aiPoints[0]);
      } else {
        const filteredAIPoints = aiPoints.filter(
          (aiPoint) => aiPoint.id === currentAIPointID + 1,
        );

        if (filteredAIPoints.length > 0) {
          setSelectedAIPoint(filteredAIPoints[0]);
        }
      }
    }, 5000);

    return () => clearTimeout(aiPointTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiPoints]);

  useEffect(() => {
    if (location.pathname === "/product/") {
      setTimeout(() => {
        productRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }

    const anchor = location.hash.replace("#", "");

    if (anchor) {
      const section = document.getElementById(anchor);
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [location]);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentage < 90) {
      const interval = setInterval(() => {
        setPercentage((prevPercentage) => prevPercentage + 1);
      }, 15);

      return () => clearInterval(interval);
    }
  }, [percentage]);

  const aiPointsRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrollingThroughAIPoints, setIsScrollingThroughAIPoints] =
    useState(false);
  const [aiPointsInView, setAiPointsInView] = useState(false);

  useEffect(() => {
    let debounceTimeout;

    const handleScroll = (event) => {
      event.preventDefault();

      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        if (event.deltaY > 0) {
          if (currentIndex < aiPoints.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        } else if (event.deltaY < 0) {
          if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
          } else {
            setIsScrollingThroughAIPoints(false);
          }
        }
      }, 0);
    };

    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    const handleScrollLock = () => {
      if (window.scrollY > 0 && !isScrollingThroughAIPoints) {
        setIsScrollingThroughAIPoints(true);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          setIsScrollingThroughAIPoints(true);
        } else {
          setIsScrollingThroughAIPoints(false);
        }
      },
      { threshold: 1.0 },
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    if (isScrollingThroughAIPoints) {
      window.addEventListener("wheel", handleScroll, { passive: false });
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("scroll", handleScrollLock);
    } else {
      window.removeEventListener("wheel", handleScroll, { passive: false });
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScrollLock);
    }

    return () => {
      clearTimeout(debounceTimeout);
      window.removeEventListener("wheel", handleScroll, { passive: false });
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", handleScrollLock);
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, [isScrollingThroughAIPoints, currentIndex, aiPointsInView]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isScrollingThroughAIPoints && aiPointsInView) {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          setIsScrollingThroughAIPoints(true);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isScrollingThroughAIPoints, aiPointsInView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAiPointsInView(entry.isIntersecting);
        setIsScrollingThroughAIPoints(entry.isIntersecting);
      },
      { threshold: 0.5 },
    );

    if (aiPointsRef.current) {
      observer.observe(aiPointsRef.current);
    }

    return () => {
      if (aiPointsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(aiPointsRef.current);
      }
    };
  }, []);

  return (
    <>
      <FadeInSection>
        <div
          className="flex justify-center px-1.875 bg-[image:var(--image-url)] bg-center bg-no-repeat"
          style={{
            "--image-url": `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png')`,
          }}
        >
          <div className="max-w-78.75 w-full">
            <div className="max-w-full w-full py-6.25 md:pt-11.25 pl-0 md:pl-1.75">
              <div role="heading" aria-level="1" className="text-white text-h1">
                <div>
                  <Translate>User Adaptive Risk Management, </Translate>
                </div>
                <div>
                  <GreenItalicText text="powered by AI" />
                </div>
              </div>
              <p
                role="heading"
                aria-level="4"
                className="mt-0.75 text-white md:max-w-43.75 text-h4"
              >
                <Translate>
                  Prevent sophisticated social engineering attacks with user
                  adaptive security awareness training, red team analysis, and
                  adaptive controls.
                </Translate>
              </p>
              <div className="mt-1.75 max-w-full">
                <Link to="/demo/">
                  <ButtonContainedGreen title="Learn More">
                    Learn More
                  </ButtonContainedGreen>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="py-2.5 px-1.875 flex justify-center">
          <div className="max-w-78.75 flex-wrap items-center justify-between hidden w-full lg:flex">
            {customersDesktop.map((customer, index) => (
              <div
                key={`customer-desktop-${index}`}
                className="h-3.375 my-1 flex justify-center items-center"
              >
                {customer.logo}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center lg:hidden">
            {customersMobile.map((customer, index) => (
              <div key={`customer-mobile-${index}`} className="h-3.375 my-1.5">
                {customer.logo}
              </div>
            ))}
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className="bg-green-background flex justify-center py-3.75 xs:mt-5 md:mt-3.75"
          ref={productRef}
        >
          <div className="max-w-full md:max-w-78.75 w-full">
            <div className="max-w-[760px] w-full mx-auto text-center">
              <div
                role="heading"
                aria-level="2"
                className="text-center leading-[1.2] h2"
                textAlign="center"
              >
                <GreenItalicText text={`${percentage}%`} />{" "}
                <Translate>of breaches start with human behavior</Translate>
              </div>
            </div>
            <div className="w-full lg:w-[65%] mx-auto">
              <p
                role="heading"
                aria-level="4"
                className="px-1 mt-1 text-center body md:px-0"
              >
                <Translate>
                  Historically the biggest challenge for enterprise security
                  teams, this trend is worsening with new trends that increase
                  the sophistication and scale of attacks on end users.
                </Translate>
              </p>
            </div>
            <div className="flex flex-col lg:flex-row justify-center lg:justify-between mt-3.75 w-full">
              {threats.map((threat, index) => {
                let paddingLeft = "";
                let paddingRight = "";

                // Mobile (xs)
                paddingLeft = "pl-0";
                paddingRight = "pr-0";

                // lg screens
                if (index % 3 === 2) {
                  paddingLeft += " lg:pl-2";
                } else if (index % 3 === 1) {
                  paddingLeft += " lg:pl-1";
                }
                if (index % 3 === 0) {
                  paddingRight += " lg:pr-2";
                } else if (index % 3 === 1) {
                  paddingRight += " lg:pr-1";
                }

                return (
                  <div
                    className={`flex flex-col items-center w-full ${index > 0 ? "mt-5" : "mt-0"} lg:mt-0 ${paddingLeft} ${paddingRight}`}
                    key={`threat-${index}`}
                  >
                    <div>
                      <img
                        className="bg-white rounded-small h-[96px]"
                        onMouseEnter={() => setHoveredThreat(index)}
                        onMouseLeave={() => setHoveredThreat(null)}
                        src={
                          hoveredThreat === index
                            ? threat.staticIcon
                            : threat.icon
                        }
                        alt={threat.title}
                      />
                    </div>
                    <div className="mt-1.25 max-w-[300px] w-full">
                      <div
                        role="heading"
                        aria-level="3"
                        className="h4 text-center font-medium leading-[1.2] flex"
                      >
                        <Translate>{threat.title}</Translate>
                      </div>
                    </div>
                    <div className="mt-0.5 max-w-[300px] w-full">
                      <p
                        role="heading"
                        aria-level="5"
                        className="text-center description"
                      >
                        <Translate>{threat.description}</Translate>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className="md:max-w-78.75 w-full mx-auto"
          ref={quantifyRiskRef}
          id="quantify-risk"
        >
          <Box
            display="flex"
            justifyContent="center"
            marginTop={{
              xs: "80px",
              md: "120px",
            }}
            padding="0 30px"
          >
            <Box width="100%">
              <div>
                <Typography
                  role="heading"
                  aria-level="2"
                  variant="h2"
                  component="h2"
                  className="home-text-header-secondary"
                  textAlign="center"
                >
                  <span role="heading" aria-level="1.5" className="text-h1.5">
                    <Translate>
                      <LogoItem />{" "}
                      <span>identifies and resolves risky behavior, </span>
                    </Translate>
                    <GreenItalicText text=" automatically" />
                  </span>
                </Typography>
              </div>
              <Box>
                <Typography
                  variant="body-gray"
                  component="p"
                  textAlign="center"
                  marginTop="12px"
                >
                  <span role="heading" aria-level="4" className="text-h4">
                    <Translate>
                      Our configurable solutions enable you to build a program
                      tailored to your organization's needs.
                    </Translate>
                  </span>
                </Typography>
              </Box>
              <Stack
                direction={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent="center"
                marginTop="60px"
              >
                <Box
                  className="home-box-solutions-tabs"
                  maxWidth={{
                    xs: "100%",
                    md: "364px",
                  }}
                  padding={{
                    xs: "0",
                    md: "28.3px",
                  }}
                  sx={{
                    marginBottom: {
                      xs: "40px",
                      md: "0px",
                    },
                  }}
                >
                  <Box display={{ xs: "none", md: "block" }}>
                    {categorySolutions.map(
                      (categoryItem, categoryItemIndex) => (
                        <>
                          <Box
                            marginTop={categoryItemIndex > 0 ? "50px" : 0}
                            key={`category-item-${categoryItemIndex}`}
                          >
                            <Typography
                              component="span"
                              className="home-text-category-solution-name"
                            >
                              <Translate>{categoryItem.category}</Translate>
                            </Typography>
                          </Box>
                          <Box marginTop="10px">
                            <Tabs
                              className="home-tabs"
                              orientation="vertical"
                              value={selectedTabSolution.title}
                              onChange={handleChangeTabSolution}
                            >
                              {categoryItem.solutions.map(
                                (solution, solutionIndex) => (
                                  <Tab
                                    className="home-tab"
                                    icon={solution.icon}
                                    iconPosition="start"
                                    label={
                                      <Translate>{solution.title}</Translate>
                                    }
                                    value={solution.title}
                                    key={`category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "5px",
                                    }}
                                  />
                                ),
                              )}
                            </Tabs>
                          </Box>
                        </>
                      ),
                    )}
                  </Box>
                  <Box display={{ xs: "block", md: "none" }}>
                    <Select
                      backgroundColor="black"
                      value={selectedTabSolutionTitle}
                      onChange={handleChangeSelectSolution}
                      fullWidth
                      variant="outlined"
                      displayEmpty
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          <ListItemIcon
                            sx={{ color: "white", minWidth: "40px" }}
                          >
                            {
                              categorySolutions
                                .flatMap((cat) => cat.solutions)
                                .find((sol) => sol.title === selected)?.icon
                            }
                          </ListItemIcon>
                          <ListItemText primary={selected} />
                        </Box>
                      )}
                      sx={{
                        color: "white",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {categorySolutions
                        .map((categoryItem, categoryItemIndex) => [
                          <ListSubheader
                            key={`subheader-${categoryItemIndex}`}
                            sx={{
                              backgroundColor: "black",
                              color: "#FFFFFF",
                              fontSize: "14px",
                              fontWeight: 400,
                              textTransform: "uppercase",
                              letterSpacing: "0.1em",
                              padding: "16px 16px 8px",
                              lineHeight: "20px",
                            }}
                          >
                            <Translate>{categoryItem.category}</Translate>
                          </ListSubheader>,
                          ...categoryItem.solutions.map(
                            (solution, solutionIndex) => (
                              <MenuItem
                                value={solution.title}
                                className="dune-menu-item"
                                key={`mobile-category-item-${categoryItemIndex}-solution-${solutionIndex}`}
                                sx={{
                                  backgroundColor: "black",
                                  color: "white",
                                  "&.Mui-selected": {
                                    backgroundColor: "#262525 !important",
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  },
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <ListItemIcon
                                    sx={{ color: "white", minWidth: "40px" }}
                                  >
                                    <div className="mx-0.625">
                                      {solution.icon}
                                    </div>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Translate>{solution.title}</Translate>
                                    }
                                    primaryTypographyProps={{
                                      variant: "body1",
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                            ),
                          ),
                        ])
                        .flat()}
                    </Select>
                  </Box>
                </Box>
                <Box
                  maxWidth="600px"
                  width="100%"
                  marginLeft={{
                    xs: 0,
                    md: "60px",
                  }}
                  marginTop={{
                    xs: "30px",
                    md: 0,
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    height="100%"
                  >
                    <Box
                      className="home-box-background-gray"
                      height={{
                        xs: "240px",
                        md: "385px",
                      }}
                      width="100%"
                      sx={{
                        background: "inherit !important",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          src={selectedTabSolution.imgUrl}
                          alt="solution"
                          className="w-full h-full"
                        />
                      </div>
                    </Box>
                    <Box
                      className="home-box-background-gray"
                      padding="20px"
                      marginTop={{
                        xs: "70px",
                        md: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          role="heading"
                          aria-level="3"
                          variant="h3"
                          component="h3"
                        >
                          {<Translate>{selectedTabSolutionTitle}</Translate>}
                        </Typography>
                      </Box>
                      <Box marginTop="10px">
                        <Typography variant="body-gray" component="p">
                          {
                            <Translate>
                              {selectedTabSolution.description}
                            </Translate>
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className="my-10"
          ref={adaptiveProtectionRef}
          id="adaptive-protection"
        >
          <div className="flex justify-center text-center text-h1.5 text-white">
            User Adaptive Risk Management
          </div>
          <div className="flex justify-center flex-wrap items-center text-center text-h4 text-white mt-0.75">
            <span>
              Dune Security is the solution for enterprises with the
              world&apos;s
            </span>
            <span>
              <span className="mx-0.313 text-center">
                <GreenItalicText text="first" />
              </span>{" "}
              and{" "}
              <span className="mx-0.313 text-center">
                <GreenItalicText text="best" />
              </span>
            </span>
            <span>User Adaptive Risk Management solution.</span>
          </div>
          <div className="flex justify-center mt-4">
            <img
              src="https://d3oo9a669kwmx7.cloudfront.net/user-adaptive-diagram.png"
              alt="User Adaptive Risk Management"
            />
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div id="remediate-risk" ref={remediateRiskRef}>
          <EcosystemDiagram />
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          className={`flex justify-center xs:mt-5 md:mt-7.5 px-0 py-1.875`}
          ref={behavioralInsightsRef}
          id="behavioral-insights"
        >
          <div className={`max-w-full w-[95%] md:w-full mx-auto`}>
            <div>
              <div
                role="heading"
                aria-level="1.5"
                className="text-center text-white text-h1.5"
              >
                <GreenItalicText text=" Secure " />
                <span className="ml-0.313">
                  <Translate>smarter,</Translate>
                </span>{" "}
                <GreenItalicText text=" operate " />
                <Translate> better, </Translate>
                <GreenItalicText text=" empower " />{" "}
                <Translate>your team.</Translate>
              </div>
              <div
                role="heading"
                aria-level="4"
                className="text-center mt-0.625 text-white text-h4"
              >
                <Translate>
                  See how AI can personalize testing and training at the
                  organization and user level.
                </Translate>
              </div>
            </div>
            <div className="max-w-78.75 w-full mx-auto">
              <div className="md:flex xs:flex-col justify-center items-center mt-1.875">
                {benefits.map((benefit, index) => (
                  <div
                    className="bg-[#111110] md:flex items-center xs:flex-col mb-1.25 md:mb-0  border border-[#E9E9E7] border-opacity-30 p-0.5 rounded-small my-0 mx-0.5"
                    key={index}
                  >
                    <div className="rounded-small w-full mb-1.25 md:mb-0 mr-0 flex justify-center items-center">
                      {benefit.icon}
                    </div>
                    <div>
                      <div
                        role="heading"
                        aria-level="3"
                        className="text-white text-h3"
                      >
                        {<Translate>{benefit.title}</Translate>}
                      </div>
                      <p
                        role="heading"
                        aria-level="5"
                        className="mt-0.75 md:mt-0 text-h5 text-white"
                      >
                        {<Translate>{benefit.description}</Translate>}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className="w-full font-semibold">
            <div>
              <p
                role="heading"
                aria-level="5"
                className="text-h5 text-white text-center tracking-[9.6px] uppercase leading-[1.2]"
              >
                <Translate>The CISO Advisory Council</Translate>
              </p>
            </div>
            <div className="mt-0.625">
              <div
                role="heading"
                aria-level="1.5"
                className="text-h1.5 leading-[1.2] text-white text-center"
              >
                <Translate>Built</Translate> <GreenItalicText text="for " />
                <span className="ml-0.313">
                  <Translate>enterprise CISOs,</Translate>{" "}
                </span>
                <GreenItalicText text="by " />
                <span className="ml-0.313">
                  <Translate>enterprise CISOs.</Translate>
                </span>
              </div>
            </div>
          </div>
        </div>
        <CisoScrollSection />
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-5 md:mt-7.5 py-1.875">
          <div className={`max-w-full md:max-w-78.75 w-full`}>
            <div className="mt-0.625">
              <div
                role="heading"
                aria-level="1.5"
                className="text-h1.5 text-white text-center leading-[1.2]"
              >
                <span>
                  <Translate>Best-in-class technology</Translate>{" "}
                </span>
                <GreenItalicText text="with " />
                <span className="ml-0.313">
                  {" "}
                  <Translate>best-in-class investors.</Translate>
                </span>
              </div>
            </div>
            <div className="mt-1.875">
              <div
                className="flex flex-wrap items-center justify-between"
                container
              >
                {investors.map((investor, index) => (
                  <div
                    className={`
                    flex flex-wrap justify-center
                    ${index > 0 ? "mt-10" : "mt-0"} 
                    ${index > 1 ? "sm:mt-10" : "sm:mt-0"} 
                    ${index > 2 ? "md:mt-10" : "md:mt-0"} 
                    lg:mt-0
                    w-full sm:w-1/2 md:w-1/3 lg:w-auto
                  `}
                  >
                    <img
                      className={`
                        ${index % 2 !== 0 ? "sm:ml-0" : "ml-0"}
                        ${index % 3 !== 0 ? "md:ml-0" : "ml-0"}
                        ${index > 0 ? "lg:ml-0" : "ml-0"}
                        xs:ml-0 h-2.5 w-auto
                      `}
                      src={investor.picture}
                      alt={investor.name}
                      key={`investor-${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div
          style={{
            "--image-url": `url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/meet-the-team.png')`,
          }}
          className="relative max-w-21.875 md:max-w-78.75 w-full mx-auto rounded-small border border-gray border-opacity-35 bg-[image:var(--image-url)] bg-center bg-cover bg-no-repeat px-1.25 py-2.5 mt-5"
        >
          <div className="relative z-10">
            <p
              role="heading"
              aria-level="1.5"
              className="text-white flex justify-center text-h1.5 md:text-center text-left"
            >
              Meet the experts behind our cybersecurity solutions
            </p>
            <p
              role="heading"
              aria-level="4"
              className="text-h4 md:text-h4 text-white flex justify-center mt-0.625 md:text-center text-left"
            >
              Learn more about the talent behind our innovative cybersecurity
              solutions.
            </p>
            <div className="flex justify-center mt-2 md:mt-1">
              <Link to="/company">
                <ButtonContainedGreen title="Meet the Team">
                  Meet the Team
                </ButtonContainedGreen>
              </Link>
            </div>
          </div>
          <div className="absolute inset-0 z-0 bg-black border border-transparent opacity-75 rounded-small"></div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <BootcampCarousel />
      </FadeInSection>
    </>
  );
}

export default Home;
