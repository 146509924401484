import { formatProfileData, getResponseGet } from "../utils";

const getInsightsUsersProfileData = async (email) => {
  let getInsightsUsersProfileDataResponse = {
    result: {
      profileData: {
        name: "",
        email: "",
        department: "",
        role: "",
        directManager: "",
      },
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/other-user-risk-data/user-information/?target_email=${email}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getInsightsUsersProfileDataResponse.result = {
        profileData: formatProfileData(data.user_information),
      };
    }
  } catch (error) {
    getInsightsUsersProfileDataResponse.error = error;
  }

  return getInsightsUsersProfileDataResponse;
};

export default getInsightsUsersProfileData;
