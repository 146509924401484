import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { PlayIcon } from "../../../../../utils/icons";
import "./training-video.css";

function TrainingVideo({
  selectedVideo,
  assignedTraining,
  pathname,
  onEndedVideo,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [supposedCurrentTime, setSupportedCurrentTime] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState("");

  const handleClickPlay = () => {
    setIsPlaying(true);
    const video = document.getElementById("training-video-video");
    if (video) {
      video.muted = true;
      video.play();
    }
  };

  const handleEndedVideo = async () => {
    onEndedVideo();
  };

  const handlePlayVideo = () => {
    setIsPlaying(true);
    window.addEventListener("blur", () => {
      const video = document.getElementById("training-video-video");
      if (video) {
        video.pause();
      }
    });
  };

  const handleRateChangeVideo = (event) => {
    const maxPlaybackRate = 1.25;
    const newRate = event.target.playbackRate;
    if (newRate > maxPlaybackRate) {
      event.target.playbackRate = maxPlaybackRate;
    }
  };

  const handleSeekingVideo = () => {
    const video = document.getElementById("training-video-video");
    const delta = video.currentTime - supposedCurrentTime;
    if (delta > 0 && Math.abs(delta) > 0.01) {
      video.currentTime = supposedCurrentTime;
    }
  };

  const handleTimeUpdateVideo = () => {
    const video = document.getElementById("training-video-video");
    if (!video.seeking) {
      setSupportedCurrentTime(video.currentTime);
    }
  };

  const handleVolumeChangeVideo = () => {
    const video = document.getElementById("training-video-video");
    video.muted = false;
    video.volume = 1;
  };

  useEffect(() => {
    if (!assignedTraining || assignedTraining.length === 0 || !selectedVideo)
      return;

    const matchedTraining = assignedTraining.find(
      (training) => training.assigned_training_id === selectedVideo.id,
    );

    if (matchedTraining) {
      const fetchedLanguage = Cookies.get("language") || "en_us";

      const fileName =
        fetchedLanguage === "en_us"
          ? matchedTraining.file_name
          : matchedTraining?.languages?.videos?.[fetchedLanguage] ||
            matchedTraining.file_name;

      const rawThumbnailFileName =
        fetchedLanguage === "en_us"
          ? matchedTraining.file_name_thumbnail
          : matchedTraining?.languages?.thumbnails?.[fetchedLanguage] ||
            matchedTraining.file_name_thumbnail;

      if (fileName) {
        if (fetchedLanguage === "en_us") {
          setVideoUrl(
            `https://d2v6yf73kbwpa3.cloudfront.net/general/languages/en_us/${encodeURIComponent(fileName)}`,
          );
        } else {
          setVideoUrl(
            `https://d2v6yf73kbwpa3.cloudfront.net/${encodeURIComponent(fileName)}`,
          );
        }
      }

      if (rawThumbnailFileName) {
        const encodedThumbnailURL = `https://d36cattz2ccgpt.cloudfront.net/${encodeURIComponent(
          rawThumbnailFileName,
        )}`;
        setThumbnailFile(encodedThumbnailURL);
      }
    }
  }, [assignedTraining, selectedVideo]);

  return (
    <div className="relative w-full h-full">
      {!isPlaying && (
        <div
          className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full cursor-pointer rounded-medium"
          aria-label="Play the video."
          title="Play the video."
          onClick={handleClickPlay}
        >
          <div
            className="absolute top-0 left-0 w-full h-full rounded-small"
            style={{
              backgroundImage: `url("${thumbnailFile}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.5,
            }}
          ></div>

          <div className="relative flex items-center justify-center w-full h-full training-video-box-thumbnail-background">
            <button
              className="p-0.625 rounded-full training-video-icon-button-play"
              aria-label="Play the video."
              title="Play the video."
            >
              <PlayIcon />
            </button>
          </div>
        </div>
      )}

      <video
        id="training-video-video"
        className={`w-full h-full rounded-small ${isPlaying ? "block" : "hidden"}`}
        controls
        onEnded={handleEndedVideo}
        onSeeking={handleSeekingVideo}
        onTimeUpdate={handleTimeUpdateVideo}
        onVolumeChange={handleVolumeChangeVideo}
        onPlay={handlePlayVideo} // Update state when video starts playing
        onRateChange={handleRateChangeVideo}
        controlsList="nodownload"
        disablePictureInPicture
        key={videoUrl}
      >
        <source
          src={
            pathname === "/dashboard/trainings/compliance/"
              ? `https://d2v6yf73kbwpa3.cloudfront.net/${selectedVideo.fileName}`
              : videoUrl
          }
          type="video/mp4"
        />
        Your browser does not support this video.
      </video>
    </div>
  );
}

export default TrainingVideo;
