import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getTestingCatalogueData, patchChangeAssetStatus } from "../../../api";
import {
  ConfigureLayout,
  ConfigureTestingCard,
  ConfigureTestingTable,
  SearchInput,
} from "../../../components/platform/configure";
import { GridIcon, ListIcon, LoadingSpinner } from "../../../utils";
import { Pagination } from "../../../components/common/data-table/utils";
import {
  ButtonOutlinedGreen,
  LoadingState,
  Tooltip,
} from "../../../components";
import { AssetViewer } from "../../../components/common/dashboard/utils/custom/card-test-tracker/utils";
import { companyNameMapping } from "../../../utils/helper-functions/company-name-mapping";
import { capitalizeOnlyFirstWords } from "../../../utils/helper-functions/capitalize";
import { Testing } from "./utils";

const TestingDashboard = () => {
  const [testingCatalogueData, setTestingCatalogueData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeDiv, setActiveDiv] = useState(2);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [contentHTML, setContentHTML] = useState();
  const [activeFilters, setActiveFilters] = useState([]);
  const [impersonationFilterOptions, setImpersonationFilterOptions] = useState(
    [],
  );
  const [showAllImpersonations, setShowAllImpersonations] = useState(false);
  const [isSmishing, setIsSmishing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestingCatalogueData = async () => {
      const response = await getTestingCatalogueData(1);
      console.log(response);
      const assets = response.all_assets;
      setTestingCatalogueData(assets);
      setFilteredData(assets);

      const uniqueImpersonations = assets.reduce((acc, item) => {
        if (item.impersonation && !acc.includes(item.impersonation)) {
          acc.push(item.impersonation);
        }
        return acc;
      }, []);

      setImpersonationFilterOptions(uniqueImpersonations);
    };

    fetchTestingCatalogueData();
  }, []);

  const defaultFilterOptions = [
    {
      categoryName: "Status",
      categories: [
        { category: "active", categoryDisplayName: "Active" },
        { category: "inactive", categoryDisplayName: "Inactive" },
      ],
    },
    {
      categoryName: "Impersonation",
      categories: impersonationFilterOptions.map((impersonation) => ({
        category: impersonation,
        categoryDisplayName: impersonation,
      })),
    },
    {
      categoryName: "Test Vector",
      categories: [
        { category: "phishing", categoryDisplayName: "Phishing" },
        { category: "smishing", categoryDisplayName: "Smishing" },
      ],
    },
  ];

  const handleFilter = (category, subCategory) => {
    setActiveFilters((prevFilters) => {
      const filterExists = prevFilters.find(
        (filter) =>
          filter.category === category && filter.subCategory === subCategory,
      );

      const updatedFilters = filterExists
        ? prevFilters.filter(
            (filter) =>
              !(
                filter.category === category &&
                filter.subCategory === subCategory
              ),
          )
        : [...prevFilters, { category, subCategory }];

      const filtered = testingCatalogueData.filter((item) => {
        return updatedFilters.every((filter) => {
          if (filter.category === "Status") {
            return updatedFilters
              .filter((f) => f.category === "Status")
              .some((f) =>
                f.subCategory === "active" ? item.is_active : !item.is_active,
              );
          } else if (filter.category === "Impersonation") {
            return updatedFilters
              .filter((f) => f.category === "Impersonation")
              .some((f) => item.impersonation === f.subCategory);
          } else if (filter.category === "Test Vector") {
            return updatedFilters
              .filter((f) => f.category === "Test Vector")
              .some((f) => item.vector === f.subCategory);
          } else {
            if (item[filter.category]) {
              return updatedFilters
                .filter((f) => f.category === filter.category)
                .some((f) => item[filter.category] === f.subCategory);
            }
            return true;
          }
        });
      });

      setFilteredData(filtered);
      return updatedFilters;
    });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const searchResults = testingCatalogueData.filter((item) => {
      const matchesTitle = item.title
        .toLowerCase()
        .includes(query.toLowerCase());
      const matchesStatus = item.is_active
        ? "active".includes(query.toLowerCase())
        : "inactive".includes(query.toLowerCase());
      const matchesImpersonation = item.impersonation
        ? item.impersonation.toLowerCase().includes(query.toLowerCase())
        : false;
      const matchesAttackVector = item.attack_vector
        ? item.attack_vector.toLowerCase().includes(query.toLowerCase())
        : false;

      return (
        matchesTitle ||
        matchesStatus ||
        matchesImpersonation ||
        matchesAttackVector
      );
    });

    if (activeFilters.length > 0) {
      const filteredResults = searchResults.filter((item) => {
        return activeFilters.every((filter) => {
          if (filter.category === "Status") {
            return filter.subCategory === "active"
              ? item.is_active
              : !item.is_active;
          } else if (filter.category === "Impersonation") {
            return item.impersonation === filter.subCategory;
          } else if (filter.category === "Test Vector") {
            return item.attack_vector === filter.subCategory;
          }
          return true;
        });
      });
      setFilteredData(filteredResults);
    } else {
      setFilteredData(searchResults);
    }
  };

  const handleChangeActiveDiv = (selectedDiv) => {
    if (selectedDiv === activeDiv) return;
    setActiveDiv(selectedDiv);
  };

  const handleOpenAssetViewer = (content, title) => {
    console.log(content, title);
  };

  const toggleSwitch = (index) => {
    const currentStatus = filteredData[index].is_active ? "Inactive" : "Active";
    handleStatusChange(currentStatus, index);
  };

  const handleStatusChange = async (status, index) => {
    const updatedFilteredData = [...filteredData];
    const selectedAsset = updatedFilteredData[index];
    const fileName = selectedAsset.file_name;

    try {
      const requestBody = {
        status: status.toLowerCase(),
        file_name: fileName,
      };

      if (
        (status === "Active" && selectedAsset.is_active) ||
        (status === "Inactive" && !selectedAsset.is_active)
      ) {
        return;
      }

      await patchChangeAssetStatus(requestBody);

      updatedFilteredData[index] = {
        ...selectedAsset,
        is_active: status === "Active",
      };
      setFilteredData(updatedFilteredData);

      const updatedTestingCatalogueData = testingCatalogueData.map((item) =>
        item.file_name === fileName
          ? { ...item, is_active: status === "Active" }
          : item,
      );
      setTestingCatalogueData(updatedTestingCatalogueData);
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };
  const itemsPerPage = 12;

  const paginatedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleCloseModal = () => {
    setTitle("");
    setFileName("");
    setIsOpen(false);
  };

  const handleOpenModal = (content, title, fileName) => {
    setIsSmishing(fileName.includes("smishing")); // Ensure it updates before setting state
    setTitle(title);
    setFileName(fileName);
    setContentHTML(content);
    setIsOpen(true);
  };

  const getFormattedCompanyName = (incomingName) => {
    if (!incomingName) return "";
    const mapping = companyNameMapping.find(
      (map) => map.incomingName?.toLowerCase() === incomingName.toLowerCase(),
    );
    return mapping
      ? mapping.formattedName
      : capitalizeOnlyFirstWords(incomingName);
  };

  const handleNavigateRequestNewAsset = () => {
    navigate("/configure/testing/request/");
  };

  if (paginatedData.length === 0) {
    <div className="flex justify-center w-screen h-screen text-white">
      <div>
        <LoadingSpinner />
      </div>
      <div>Loading your testing asset</div>
    </div>;
  }

  if (testingCatalogueData.length === 0) {
    <div className="flex justify-center w-screen h-screen text-white">
      <div>
        <LoadingSpinner />
      </div>
      <div>Loading your testing asset</div>
    </div>;
  }

  return (
    <div className="text-white">
      <Testing />
      <div className="flex justify-between items-center mt-1.75">
        <div className="flex justify-center items-center">
          <h3 className="h3 mr-0.25">Testing Library</h3>
          <Tooltip text="Customize phishing emails that users can receive." />
        </div>
        <ButtonOutlinedGreen onClick={handleNavigateRequestNewAsset}>
          Request New Asset
        </ButtonOutlinedGreen>
      </div>
      {testingCatalogueData === null && <LoadingState />}
      {!testingCatalogueData ||
        (testingCatalogueData.length === 0 && (
          <div className="my-1 text-white">Loading...</div>
        ))}
      {testingCatalogueData && testingCatalogueData.length > 0 && (
        <ConfigureLayout
          filters={
            <>
              {defaultFilterOptions.map((filterOption, index) => (
                <div key={index}>
                  <div
                    className={`flex items-center mb-0.75 text-gray uppercase tracking-custom-1.08 text-h6 ${index !== 0 && "mt-1"}`}
                  >
                    {filterOption.categoryName}
                  </div>
                  <div>
                    {filterOption.categoryName === "Impersonation" ? (
                      <>
                        {filterOption?.categories
                          .slice(
                            0,
                            showAllImpersonations
                              ? filterOption.categories.length
                              : 5,
                          )
                          .map((cname) => (
                            <div
                              className="flex items-center mb-0.5 ml-0.5"
                              key={cname.category}
                            >
                              <input
                                type="checkbox"
                                checked={activeFilters.some(
                                  (filter) =>
                                    filter.category ===
                                      filterOption.categoryName &&
                                    filter.subCategory === cname.category,
                                )}
                                onChange={() =>
                                  handleFilter(
                                    filterOption.categoryName,
                                    cname.category,
                                  )
                                }
                                className="mr-0.5 appearance-none border rounded border-gray min-h-1 min-w-1 text-black checked:bg-green checked:border-none focus:outline-none checked:shadow-[0px_0px_18px_0px_#028334]"
                              />
                              <div className="text-gray text-h5">
                                {getFormattedCompanyName(
                                  cname.categoryDisplayName,
                                )}
                              </div>
                            </div>
                          ))}

                        <button
                          className="text-green text-h6"
                          onClick={() =>
                            setShowAllImpersonations((prev) => !prev)
                          }
                        >
                          {showAllImpersonations ? "Show Less" : "Show More"}
                        </button>
                      </>
                    ) : (
                      filterOption.categories.map((cname) => (
                        <div
                          className="flex items-center mb-0.5 ml-0.5"
                          key={cname.category}
                        >
                          <input
                            type="checkbox"
                            checked={activeFilters.some(
                              (filter) =>
                                filter.category === filterOption.categoryName &&
                                filter.subCategory === cname.category,
                            )}
                            onChange={() =>
                              handleFilter(
                                filterOption.categoryName,
                                cname.category,
                              )
                            }
                            className="mr-0.5 appearance-none border rounded border-gray min-h-1 min-w-1 text-black checked:bg-green checked:border-none focus:outline-none checked:shadow-[0px_0px_18px_0px_#028334]"
                          />
                          <div className="text-gray text-h5">
                            {cname.categoryDisplayName}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              ))}
            </>
          }
          data={
            <>
              <div className="lg:flex items-center lg:justify-between justify-start  mb-2">
                <SearchInput
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  didFetchAllPages={true}
                  placeholderTextWhenLoaded="Type in a test's name"
                  placeholderTextWhenLoading="Loading all the tests for you"
                />
                <div className="flex mt-0.5 cursor-pointer lg:justify-center jusitfy-start ">
                  <div
                    onClick={() => handleChangeActiveDiv(1)}
                    className={`rounded-l-small p-0.5 cursor-pointer ${
                      activeDiv === 1
                        ? "border border-green text-black"
                        : "bg-transparent text-white border border-gray border-opacity-35"
                    }`}
                  >
                    <ListIcon isActive={activeDiv === 1} />
                  </div>
                  <div
                    onClick={() => handleChangeActiveDiv(2)}
                    className={`rounded-r-small p-0.5 cursor-pointer ${
                      activeDiv === 2
                        ? "border border-green text-black shadow-custom-int"
                        : "bg-transparent text-white border border-gray border-opacity-35"
                    }`}
                  >
                    <GridIcon isActive={activeDiv === 2} />
                  </div>
                </div>
              </div>

              <div>
                {activeDiv === 1 && (
                  <div>
                    <ConfigureTestingTable
                      filteredData={paginatedData}
                      sortData={sortData}
                      toggleSwitch={toggleSwitch}
                      toggleAssetViewer={handleOpenModal}
                    />
                  </div>
                )}
              </div>

              <div>
                {activeDiv === 2 && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1">
                    {paginatedData.map((item, index) => (
                      <ConfigureTestingCard
                        toggleAssetViewer={handleOpenModal}
                        key={item.title}
                        item={item}
                        index={index}
                        handleOpenModal={handleOpenAssetViewer}
                        toggleSwitch={toggleSwitch}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-start mt-2.25">
                <Pagination
                  page={currentPage}
                  setPage={setCurrentPage}
                  totalPages={totalPages}
                />
              </div>
            </>
          }
        />
      )}
      <AssetViewer
        isSmishing={isSmishing}
        isOpen={isOpen}
        onCloseModal={handleCloseModal}
        testHTML={contentHTML}
        title={title}
        fileName={fileName}
      />
    </div>
  );
};

export default TestingDashboard;
