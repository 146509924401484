import { CrowdStrikeBirdIcon, OktaIcon } from "../../icons";

export const userActivityHeatmapIcons = [
  {
    icon: <OktaIcon />,
  },
  {
    icon: <CrowdStrikeBirdIcon />,
  },
];

export const heatmapTabsData = [
  {
    name: "ANOMALOUS ACTIVITY",
    value: "anomalous-activity",
  },
  {
    name: "ALL ACTIVITY",
    value: "all-activity",
  },
];

export const anomalousActivityDataHeaders = [
  {
    id: "user_email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },

  {
    id: "policy",
    label: "Policy",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },

  {
    id: "workload",
    label: "Workload",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },

  {
    id: "severity",
    label: "Event Impact",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
];
