import React from "react";

const OSFLogo = () => {
  return (
    <img
      src="https://d3oo9a669kwmx7.cloudfront.net/logos/OSFHC.png"
      alt="HealthEdge Logo"
      className="max-w-10 w-full"
    />
  );
};

export default OSFLogo;
