import React from "react";

const RightArrowIcon = ({ fillColor }) => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.57258 3.10803L6.6961 3.98452L10.1647 7.45938H2.59961V8.70263H10.1647L6.6961 12.1775L7.57258 13.054L12.5456 8.08101L7.57258 3.10803Z"
        fill={fillColor ? fillColor : "white"}
      />
    </svg>
  );
};

export default RightArrowIcon;
