import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  APIProvider,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { getUser } from "../../../api";

const MAP_IDS = ["7a9e2ebecd32a903"];
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const INITIAL_CAMERA_STATE = {
  center: {
    lat: 36.6676,
    lng: -78.3875,
  },
  zoom: 4,
  heading: 0,
  tilt: 0,
};

const Heatmap = ({ data, radius, opacity }) => {
  const map = useMap();
  const visualization = useMapsLibrary("visualization");

  const heatmap = useMemo(() => {
    if (!visualization || !window.google?.maps?.visualization) return null;

    return new window.google.maps.visualization.HeatmapLayer({
      radius,
      opacity,
    });
  }, [visualization, radius, opacity]);

  const [organization, setOrganization] = useState("");

  useEffect(() => {
    const fetchOrgDetails = async () => {
      const response = await getUser();
      setOrganization(response.result.organization);
    };

    fetchOrgDetails();
  }, [organization]);

  //! TODO: Fix this before PR
  // useEffect(() => {
  //   if (!heatmap) return;

  //   heatmap.setData(
  //     data.coordinates.map((point) => {
  //       const { Latitude, Longitude } = point;
  //       return new window.google.maps.LatLng(Latitude, Longitude);
  //     }),
  //   );

  //   // organization === "Synovus"
  //   //   ? heatmap.setData(
  //   //       data.coordinates.map((point) => {
  //   //         const { Latitude, Longitude } = point;
  //   //         return new window.google.maps.LatLng(Latitude, Longitude);
  //   //       }),
  //   //     )
  //   //   : heatmap.setData(
  //   //       data.map((point) => {
  //   //         const { Latitude, Longitude } = point;
  //   //         return new window.google.maps.LatLng(Latitude, Longitude);
  //   //       }),
  //   //     );
  // }, [heatmap, data, organization]);

  useEffect(() => {
    if (!heatmap || !data?.coordinates?.length) return;

    heatmap.setData(
      data.coordinates.map((point) => {
        const { Latitude, Longitude } = point;
        return new window.google.maps.LatLng(Latitude, Longitude);
      }),
    );
  }, [heatmap, data, organization]);

  useEffect(() => {
    if (!heatmap || !map) return;

    heatmap.setMap(map);

    return () => {
      heatmap.setMap(null);
    };
  }, [heatmap, map]);

  return null;
};

const HeatMap = ({
  data,
  className,
  scrollwheel,
  center_lat,
  center_long,
  zoom,
}) => {
  const [cameraState, setCameraState] = useState(INITIAL_CAMERA_STATE);
  const [activeMap, setActiveMap] = useState(1);

  useEffect(() => {
    if (center_lat && center_long && zoom) {
      setCameraState((prevState) => ({
        ...prevState,
        center: {
          lat: center_lat,
          lng: center_long,
        },
        zoom: zoom,
      }));
    }

    if (data && data.center) {
      setCameraState((prevState) => ({
        ...prevState,
        center: {
          lat: data.center.Latitude,
          lng: data.center.Longitude,
        },
      }));
    }
  }, [data, center_lat, center_long, zoom]);

  const handleCameraChange = useCallback((ev) => {
    setCameraState(ev.detail);
  }, []);

  return (
    <APIProvider apiKey={API_KEY} libraries={["visualization"]}>
      <div className={`flex w-full h-22 ${className}`}>
        {[0].map((i) => {
          const isActive = activeMap === i;

          return (
            <Map
              key={i}
              id={`map-${i}`}
              mapId={MAP_IDS[i]}
              disableDefaultUI
              gestureHandling={"greedy"}
              onCameraChanged={isActive ? handleCameraChange : undefined}
              onMouseover={() => setActiveMap(i)}
              {...cameraState}
              maxZoom={14}
              minZoom={3}
              clickableIcons={false}
              disableDoubleClickZoom
              keyboardShortcuts={false}
              scrollwheel={scrollwheel}
              cameraControl={false}
              termsOfServiceLink={false}
              streetView={false}
            >
              <Heatmap data={data} radius={30} opacity={0.7} />
            </Map>
          );
        })}
      </div>
    </APIProvider>
  );
};

export default HeatMap;
