import { getResponsePostNoAuth } from "../utils/networking";

export const postHeadsUp = async (requestBody) => {
  let headsUp = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePostNoAuth("/heads-up/", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    headsUp.result = response.data;
  } catch (error) {
    headsUp.error = error;
  }

  return headsUp;
};

export default postHeadsUp;
