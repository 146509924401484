export const extractTitleFromKey = (key) => {
  const match = key.match(/\/([^/]+)\.svg$/);
  if (match && match[1]) {
    return match[1]
      .replace(/^[^_]*_/, "")
      .replace(/_/g, " ")
      .trim();
  }
  return "Untitled";
};
