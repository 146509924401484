import React, { useState, useEffect, useRef } from "react";
import { random, range, sample } from "lodash";
import "./confetti.css";

const shapes = [
  "circle",
  "square",
  "triangle",
  "star",
  "diamond",
  "hexagon",
  "pentagon",
  "heart",
  "parallelogram",
  "oval",
];

const colors = ["#00FF82", "#0A3D23", "#00CE2B", "#00974D33"];

const Confetti = ({ active, count = 150, duration = 3000 }) => {
  const [confetti, setConfetti] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;

    const newConfetti = range(count).map(() => ({
      x: random(0, containerWidth),
      y: random(-200, -50),
      size: random(8, 20),
      rotation: random(0, 360),
      color: sample(colors),
      animationDuration: random(2000, 4000),
      delay: random(0, 500),
      shape: sample(shapes),
      visible: false,
    }));

    setConfetti(newConfetti);

    if (active) {
      setTimeout(() => {
        setConfetti((prev) => prev.map((c) => ({ ...c, visible: true })));
      }, 50);
    } else {
      setConfetti([]);
    }
  }, [active, count, duration]);

  return (
    <div
      ref={containerRef}
      className="absolute inset-0 overflow-hidden pointer-events-none"
    >
      {confetti.map(
        (c, index) =>
          c.visible && (
            <div
              key={index}
              className={`absolute confetti-piece ${c.shape}`}
              style={{
                left: c.x,
                top: c.y,
                width: c.shape === "triangle" ? 0 : c.size,
                height: c.shape === "triangle" ? 0 : c.size,
                backgroundColor:
                  c.shape === "triangle" ? "transparent" : c.color,
                borderBottomColor:
                  c.shape === "triangle" ? c.color : "transparent",
                transform: `rotate(${c.rotation}deg)`,
                animation: active
                  ? `confetti-shower ${c.animationDuration}ms linear ${c.delay}ms forwards`
                  : "none",
              }}
            />
          ),
      )}
    </div>
  );
};

export default Confetti;
