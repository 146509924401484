const formatRiskScoreOverTime = (timeSeries) => {
  if (!timeSeries || timeSeries.length === 0) {
    return [];
  }

  let riskScoreOverTime = [];

  // Sort the time series data by date first.
  const sortedTimeSeries = [...timeSeries];
  sortedTimeSeries.sort((a, b) => {
    const timestampA = a.timestamp ? a.timestamp : a.date;
    const timestampB = b.timestamp ? b.timestamp : b.date;

    const dateA = new Date(timestampA);
    const dateB = new Date(timestampB);

    return dateA - dateB;
  });

  for (let i = 0; i < sortedTimeSeries.length; i++) {
    const element = sortedTimeSeries[i];

    const date = element.timestamp
      ? new Date(element.timestamp)
      : new Date(element.date);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    let count = 1;
    let riskScore = element.risk_score;

    if (i < sortedTimeSeries.length - 1) {
      let nextElement = sortedTimeSeries[i + 1];

      // Check if the next data point has the same date.
      while (i <= sortedTimeSeries.length - 1) {
        const dateNextElement = nextElement.timestamp
          ? new Date(nextElement.timestamp)
          : new Date(nextElement.date);

        if (
          date.getMonth() === dateNextElement.getMonth() &&
          date.getDate() === dateNextElement.getDate() &&
          date.getMonth() === dateNextElement.getMonth()
        ) {
          count++;
          riskScore += nextElement.risk_score;
          i++;
        } else {
          break;
        }
      }
    }

    riskScoreOverTime.push({
      date: formattedDate,
      riskScore: +(riskScore / count).toFixed(),
    });
  }

  return riskScoreOverTime;
};

export default formatRiskScoreOverTime;
