import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonOutlinedGreen } from "../../../../buttons";
import { CardWithLoadingState } from "../../../../cards";
import { CardHeader, DataTable } from "../../../../";
import { OktaIcon } from "../../../../../../utils";

const loginActivityheaders = [
  {
    id: "email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "142px",
  },
  {
    id: "anomalyReasoning",
    label: "Anomaly Cause",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "194px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];

const CardLoginActivity = ({ loginActivity }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  const handleClickRedirect = () => {
    navigate("/insights/organization/anomalous-activity/");
  };

  return (
    <CardWithLoadingState loadingVariable={loginActivity}>
      <div className="flex justify-between items-start">
        <CardHeader header="Anomalous Login Activity" />
        <div className="relative flex items-center">
          <div
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <OktaIcon />
          </div>
          {showTooltip && (
            <div
              className="absolute top-full left-1/2 mt-[10px] transform -translate-x-1/2 border border-border-color description py-[4px] px-[8px] z-50 bg-gray-tooltip max-w-[300px] w-max rounded-small"
              style={{
                boxShadow:
                  "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              Okta
            </div>
          )}
        </div>
      </div>
      <p className="max-w-40 description text-gray-surface-contrast">
        Monitors irregular login behavior, such as unauthorized locations,
        unusual times, or multiple failed attempts, using real-time IAM and EDR
        data to detect potential security threats.
      </p>
      <div className="mt-1.25">
        <DataTable
          data={loginActivity}
          headers={loginActivityheaders}
          defaultOrderBy="email"
          rowsPerPage={5}
          disableSearchBar
          disablePagination
          enableEmailLink={true}
          customFooter={
            <div className="flex justify-end mt-0.75 p-0.625">
              <ButtonOutlinedGreen onClick={handleClickRedirect} isSmall>
                View all
              </ButtonOutlinedGreen>
            </div>
          }
        />
      </div>
    </CardWithLoadingState>
  );
};

export default CardLoginActivity;
