import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardWithLoadingState } from "../../../../cards";
import { ButtonOutlinedGreen, CardHeader, DataTable } from "../../../../";
import { MicrosoftPurviewColorIcon } from "../../../../../../utils";

const anomalosFileSharingHeaders = [
  {
    id: "user_email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "232px",
  },

  {
    id: "policy",
    label: "Policy",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "212px",
  },
  {
    id: "severity",
    label: "Event Impact",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
];

const CardAnomalousFileSharing = ({ anomalousFileSharingActivity }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();

  const handleClickRedirect = () => {
    navigate("/insights/organization/anomalous-activity/");
  };

  return (
    <CardWithLoadingState loadingVariable={anomalousFileSharingActivity}>
      <div className="flex justify-between items-start">
        <CardHeader header="Anomalous File Sharing Activity" />
        <div className="relative flex items-center">
          <div
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <MicrosoftPurviewColorIcon />
          </div>
          {showTooltip && (
            <div
              className="absolute top-full left-1/2 mt-[10px] transform -translate-x-1/2 border border-border-color description py-[4px] px-[8px] z-50 bg-gray-tooltip max-w-[300px] w-max rounded-small"
              style={{
                boxShadow:
                  "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              Microsoft Purview
            </div>
          )}
        </div>
      </div>
      <p className="max-w-40 description text-gray-surface-contrast">
        Tracks sensitive documents shared within or outside the organization,
        monitored according to your Data Loss Prevention (DLP) policies.
      </p>
      <div className="mt-1.25">
        <DataTable
          data={anomalousFileSharingActivity}
          headers={anomalosFileSharingHeaders}
          defaultOrderBy="user_email"
          rowsPerPage={5}
          disableSearchBar
          enableEmailLink={true}
          disablePagination
          customFooter={
            <div className="flex justify-end mt-0.75 p-0.625">
              <ButtonOutlinedGreen onClick={handleClickRedirect} isSmall>
                View all
              </ButtonOutlinedGreen>
            </div>
          }
        />
      </div>
    </CardWithLoadingState>
  );
};

export default CardAnomalousFileSharing;
