import React from "react";

const AutoGraphOutlinedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.88278 4.19681L5.85278 3.72681L6.88278 3.25681L7.35278 2.22681L7.82278 3.25681L8.85278 3.72681L7.82278 4.19681L7.35278 5.22681L6.88278 4.19681ZM1.85278 6.22681L2.32278 5.19681L3.35278 4.72681L2.32278 4.25681L1.85278 3.22681L1.38278 4.25681L0.352783 4.72681L1.38278 5.19681L1.85278 6.22681ZM4.10278 3.72681L4.64778 2.52181L5.85278 1.97681L4.64778 1.43181L4.10278 0.226807L3.55778 1.43181L2.35278 1.97681L3.55778 2.52181L4.10278 3.72681ZM2.10278 9.47681L5.10278 6.47181L7.10278 8.47181L11.3528 3.69181L10.6478 2.98681L7.10278 6.97181L5.10278 4.97181L1.35278 8.72681L2.10278 9.47681Z"
        fill="white"
      />
    </svg>
  );
};

export default AutoGraphOutlinedIcon;
