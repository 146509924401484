import React from "react";
import { Link } from "react-router-dom";

const ViewAll = ({ href }) => {
  return (
    <div className="flex justify-end max-w-78.75 mt-2.25">
      <Link
        to={href}
        className="inline-flex items-center text-lg duration-100 ease-in-out transform text-green hover:text-green-hover"
      >
        View all <span className="ml-2">&rarr;</span>
      </Link>
    </div>
  );
};

export default ViewAll;
