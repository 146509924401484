const formatHeatmapData = (apiResponse) => {
  if (!apiResponse || apiResponse.length === 0) {
    return { center: null, coordinates: [] };
  }

  const formattedData = {
    center: apiResponse.center
      ? {
          Latitude: apiResponse.center.latitude,
          Longitude: apiResponse.center.longitude,
        }
      : null,
    coordinates: [],
  };

  if (apiResponse.all_coordinates && apiResponse.all_coordinates.length > 0) {
    formattedData.coordinates = apiResponse.all_coordinates.map(
      (coordinate) => ({
        Latitude: coordinate.latitude,
        Longitude: coordinate.longitude,
      }),
    );
  }

  return formattedData;
};

export default formatHeatmapData;
