import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardHeader,
  CardWithLoadingState,
  LineChartLogin,
} from "../../../..";

import { CustomLegend } from "./utils";

const CardAverageHourlyUserLogin = ({ averageHourlyUserLogin }) => {
  const navigate = useNavigate();

  const handleClickDetails = () => {
    navigate("/insights/organization/user-login-activity/");
  };

  return (
    <CardWithLoadingState
      loadingVariable={averageHourlyUserLogin}
      minHeight="517px"
    >
      <CardHeader header="Average Hourly User Login" />
      <div>
        <p className="description text-gray-surface-contrast">
          Track login trends throughout the day to detect anomalies and monitor
          peak usage times, helping identify potential security risks during
          non-standard hours. This chart shows the average user logins per hour
          throughout the week in UTC.
        </p>
      </div>
      <div className="mt-1.5 mb-0.5">
        <LineChartLogin data={averageHourlyUserLogin} />
      </div>
      <div className="flex items-center justify-between mt-1.5">
        <CustomLegend />
      </div>
      <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
          <ButtonOutlinedGreen isSmall onClick={handleClickDetails}>
            Details
          </ButtonOutlinedGreen>
        </div>
      </div>
    </CardWithLoadingState>
  );
};

export default CardAverageHourlyUserLogin;
