import { formatOktaLoginAnomalies, getResponseGet } from "../utils";

const getOktaLoginAnomalies = async () => {
  let oktaLoginAnomalies = {
    result: [],
    error: null,
  };

  try {
    const response = await getResponseGet("/okta-anomalies");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (Array.isArray(data)) {
      oktaLoginAnomalies.result = formatOktaLoginAnomalies(data);
    } else {
      console.error("API response is not an array, received:", data);
      oktaLoginAnomalies.result = [];
    }
  } catch (error) {
    console.error("Error fetching anomalies:", error);
    oktaLoginAnomalies.error = error.message;
  }

  return oktaLoginAnomalies;
};

export default getOktaLoginAnomalies;
