import React, { useEffect, useState } from "react";

import {
  CardHeader,
  CardWithLoadingState,
  LineChartLogin,
} from "../../../components";

import {
  AVERAGE_HOURLY_USER_LOGIN,
  USER_ACTIVITY_HEATMAP,
} from "../../../utils";

import { VisMap } from "../../../components/platform/maps-visualization";
import { CustomLegend } from "../../../components/common/dashboard/utils/custom/card-average-hourly-user-login/utils";
import { userActivityHeatmapIcons } from "../../../utils/constants/platform/login-activity";
import { getOktaHeatmap, getUser, getOktaHourlyUserLogin } from "../../../api";

const UserLoginActivity = ({ isInitialLoading, setBreadcrumbs }) => {
  const [heatMapData, setHeatMapData] = useState(null);
  const [organization, setOrganization] = useState("");

  const [oktaHeatMapData, setOktaHeatmapData] = useState(null);
  const [oktaHourlyLoginData, setOktaHourlyLoginData] = useState(null);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      const response = await getUser();
      setOrganization(response.result.organization);
    };

    fetchOrganizationData();
  }, []);

  useEffect(() => {
    const loadMapData = async () => {
      await setHeatMapData(USER_ACTIVITY_HEATMAP);
    };

    if (!isInitialLoading) {
      loadMapData();
    }
  }, [isInitialLoading, setHeatMapData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "User Login Activity",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  useEffect(() => {
    const fetchOktaHeatMapData = async () => {
      const response = await getOktaHeatmap();
      setOktaHeatmapData(response.result);
      console.log("response", response.result);
    };

    if (!isInitialLoading) {
      fetchOktaHeatMapData();
    }
  }, [isInitialLoading]);

  useEffect(() => {
    const fetchOktaHourlyLoginData = async () => {
      const response = await getOktaHourlyUserLogin();
      setOktaHourlyLoginData(response.result.logins);
    };

    if (!isInitialLoading) {
      fetchOktaHourlyLoginData();
    }
  }, [isInitialLoading]);

  return (
    <div className="text-white">
      <CardWithLoadingState
        loadingVariable={
          organization === "Synovus" ? oktaHeatMapData : heatMapData
        }
      >
        <CardHeader header="User Activity Heatmap" />
        <div className="flex justify-between items-center mb-2.125">
          <p className="text-gray-surface-contrast max-w-[55%]">
            Monitor suspicious activities, such as logins from unauthorized
            locations or devices. Data is provided by your IAM.
          </p>
          <div className="flex items-center justify-center">
            {userActivityHeatmapIcons.map((icon, index) => (
              <div key={index} className="flex items-center ml-1">
                {icon.icon}
              </div>
            ))}
          </div>
        </div>
        <div className="flex h-full">
          <VisMap
            center_lat={29.561236}
            center_long={121.833441}
            zoom={2}
            className={`min-h-[435px]`}
            data={oktaHeatMapData}
          />
        </div>
      </CardWithLoadingState>
      <div className="mt-3">
        <CardWithLoadingState
          loadingVariable={
            organization === "Synovus"
              ? oktaHourlyLoginData
              : AVERAGE_HOURLY_USER_LOGIN
          }
        >
          <CardHeader header="Average Hourly User Login" />
          <div className="text-h5 text-gray max-w-[55%]">
            This chart shows the average user logins per hour throughout the
            week in UTC, highlighting daily trends and peak activity times.
          </div>
          <div className="mt-2.125 mb-0.5">
            <LineChartLogin
              data={
                organization === "Synovus"
                  ? oktaHourlyLoginData
                  : AVERAGE_HOURLY_USER_LOGIN
              }
            />
          </div>
          <div className="flex items-center justify-between mt-1.5">
            <CustomLegend />
          </div>
        </CardWithLoadingState>
      </div>
    </div>
  );
};

export default UserLoginActivity;
