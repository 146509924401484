import { formatAssignedTraining, getResponseGet } from "../utils";

const getInsightsUserAssignedTraining = async (email) => {
  let getInsightsUserAssignedTrainingResponse = {
    result: {
      assignedTraining: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/other-user-risk-data/assigned-training/?target_email=${email}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getInsightsUserAssignedTrainingResponse.result = {
        assignedTraining: formatAssignedTraining(data.assigned_training),
      };
    }
  } catch (error) {
    getInsightsUserAssignedTrainingResponse.error = error;
  }

  return getInsightsUserAssignedTrainingResponse;
};

export default getInsightsUserAssignedTraining;
