const formatOktaMFAProtectionUsers = (apiResponse) => {
  if (!apiResponse || Object.keys(apiResponse).length === 0) {
    return [];
  }

  let formattedUsers = [];

  if (
    apiResponse.protected_users &&
    Object.keys(apiResponse.protected_users).length > 0
  ) {
    const protectedUsers = Object.entries(apiResponse.protected_users).map(
      ([email, details]) => ({
        email,
        firstName: details.first_name,
        lastName: details.last_name,
        initials: `${details.first_name.charAt(0)}${details.last_name.charAt(0)}`,
        riskScore: Math.floor(details.riskScore),
        status: "Protected",
        role: details.role,
      }),
    );
    formattedUsers = formattedUsers.concat(protectedUsers);
  }

  if (
    apiResponse.unprotected_users &&
    Object.keys(apiResponse.unprotected_users).length > 0
  ) {
    const unprotectedUsers = Object.entries(apiResponse.unprotected_users).map(
      ([email, details]) => ({
        email,
        firstName: details.first_name,
        lastName: details.last_name,
        initials: `${details.first_name.charAt(0)}${details.last_name.charAt(0)}`,
        riskScore: Math.floor(details.riskScore),
        status: "Unprotected",
        role: details.role,
      }),
    );
    console.log("unprotectedUsers", unprotectedUsers);
    formattedUsers = formattedUsers.concat(unprotectedUsers);
  }

  return formattedUsers;
};

export default formatOktaMFAProtectionUsers;
