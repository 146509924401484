import React from "react";
import { Translate } from "react-auto-translate/lib/commonjs";
import { RightArrowIcon } from "../../../../../components/common/dashboard/utils/custom/card-testing-calendar-for-user/utils";

const ResourceCard = ({
  title,
  date,
  imgSrc,
  alt = imgSrc,
  resourceId,
  resourceHref,
  handleClick,
  badge,
}) => {
  return (
    <a
      key={resourceId}
      href={resourceHref}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer group"
      onClick={handleClick}
    >
      <div className="text-white group rounded-medium bg-gray-card cursor-pointer h-28 flex flex-col">
        <div className="max-h-12 flex items-start justify-center overflow-hidden rounded-t-medium bg-black">
          <img
            src={imgSrc}
            alt={alt}
            className="object-cover object-top transition-transform duration-300 ease-in-out group-hover:scale-110"
          />
        </div>
        <div className="p-1 flex flex-col flex-grow">
          {badge && badge.title && badge.color && (
            <div
              className="w-fit px-0.75 py-0.25 rounded-full my-1 text-h6"
              style={{ backgroundColor: badge.color }}
            >
              {badge.title}
            </div>
          )}
          <div className="text-h4 min-h-4">
            <Translate>{title}</Translate>
          </div>
          <div className="flex-grow"></div>
          <div className="pt-1 flex justify-between items-center">
            <div>{date}</div>
            <div className="flex items-center text-green hover:text-green-hover">
              <span className="pr-0.25">Read more</span>
              <span>
                <RightArrowIcon fillColor="#00FF82" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ResourceCard;
