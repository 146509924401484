import { formatAreasToImproveUser, getResponseGet } from "../utils";

const getInsightsUserChangeFactors = async (email) => {
  let getInsightsUserChangeFactorsResponse = {
    result: {
      changeFactors: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/other-user-risk-data/change-factors/?target_email=${email}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getInsightsUserChangeFactorsResponse.result = {
        changeFactors: formatAreasToImproveUser(data.change_factors),
      };
    }
  } catch (error) {
    getInsightsUserChangeFactorsResponse.error = error;
  }

  return getInsightsUserChangeFactorsResponse;
};

export default getInsightsUserChangeFactors;
