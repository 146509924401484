import React, { useEffect, useRef, useState } from "react";

import {
  getMFAProtectionStatuses,
  getUsersByMFAProtectionStatus,
  getOktaMFAProtectionCount,
  getOktaMFAProtectionUsers,
  getUser,
} from "../../../api";

import { CardWithLoadingState, DonutChartWithTable } from "../../../components";

const MFAProtectionStatus = ({ isInitialLoading, email, setBreadcrumbs }) => {
  const [mfaProtectionStatuses, setMFAProtectionStatuses] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState({ name: "unprotected" });
  const [users, setUsers] = useState(null);

  const [organization, setOrganization] = useState(null);
  const [oktaMFAProtectionCount, setOktaMFAProtectionCount] = useState();
  const [oktaMFAProtectedUsers, setOktaMFAProtectedUsers] = useState();

  const usersCacheRef = useRef({});

  useEffect(() => {
    const loadMFAProtectionStatuses = async () => {
      const getMFAProtectionStatusesResponse = await getMFAProtectionStatuses();

      if (Object.keys(getMFAProtectionStatusesResponse.error).length > 0) {
        console.error(getMFAProtectionStatusesResponse.error.message);
      } else {
        const { mfaProtectionStatuses } =
          getMFAProtectionStatusesResponse.result;

        setMFAProtectionStatuses(mfaProtectionStatuses);
      }
    };

    if (!isInitialLoading && email) {
      loadMFAProtectionStatuses();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const loadUsersByMFAProtectionStatus = async () => {
      if (selectedStatus.name && usersCacheRef.current[selectedStatus.name]) {
        setUsers(usersCacheRef.current[selectedStatus.name].users);

        return;
      }

      const getUsersByMFAProtectionStatusResponse =
        await getUsersByMFAProtectionStatus(selectedStatus.name);

      if (Object.keys(getUsersByMFAProtectionStatusResponse.error).length > 0) {
        console.error(getUsersByMFAProtectionStatusResponse.error.message);
      } else {
        const { users } = getUsersByMFAProtectionStatusResponse.result;

        setUsers(users);

        usersCacheRef.current = {
          ...usersCacheRef.current,
          [selectedStatus.name]: {
            users,
          },
        };
      }
    };

    if (!isInitialLoading && email) {
      loadUsersByMFAProtectionStatus();
    }
  }, [isInitialLoading, email, selectedStatus]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "MFA Protection Status",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getUser();
      setOrganization(response.result.organization);
    };

    if (!isInitialLoading && email) {
      fetchOrganization();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const fetchOktaMFAProtectedCount = async () => {
      const response = await getOktaMFAProtectionCount();
      setOktaMFAProtectionCount(response.result);
    };

    if (!isInitialLoading && email) {
      fetchOktaMFAProtectedCount();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const fetchOktaMFAProtectedUsers = async () => {
      const response = await getOktaMFAProtectionUsers();
      setOktaMFAProtectedUsers(response.result);
    };

    if (!isInitialLoading && email) {
      fetchOktaMFAProtectedUsers();
    }
  }, [isInitialLoading, email]);

  return (
    <div>
      <CardWithLoadingState loadingVariable={mfaProtectionStatuses}>
        <DonutChartWithTable
          donutChartData={
            organization === "Synovus"
              ? oktaMFAProtectionCount
              : mfaProtectionStatuses
          }
          tableData={organization === "Synovus" ? oktaMFAProtectedUsers : users}
          selectedTab={selectedStatus}
          setSelectedTab={setSelectedStatus}
          title="Total Users"
          filterField="status"
          defaultOrderBy="email"
          headers={[
            {
              id: "name",
              label: "Name",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "email",
              label: "Email",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "riskScore",
              label: "Risk score",
              isSortable: true,
              isSearchable: false,
              align: "left",
            },
          ]}
        />
      </CardWithLoadingState>
    </div>
  );
};

export default MFAProtectionStatus;
