import React, { useCallback, useEffect, useState } from "react";

import { getInsightsEndUser, getTestHTML, getTestHTMLDemo } from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatformEndUser } from "../../../constants";

function InsightsEndUser({ isInitialLoading, email }) {
  // Insights
  const [showHeadsUp, setShowHeadsUp] = useState(false);
  const [riskScore, setRiskScore] = useState(null);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [pastTests, setPastTests] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All Time)

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All Time") {
        // If all time, use the master set riskScoreOverAllTime.
        setRiskScoreOverTime(riskScoreOverAllTime);

        // Update description data
        if (riskScoreOverAllTime.length >= 2) {
          const firstPoint = riskScoreOverAllTime[0];
          const lastPoint =
            riskScoreOverAllTime[riskScoreOverAllTime.length - 1];
          const points = lastPoint.riskScore - firstPoint.riskScore;

          setScoreChange(points);
        }
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          },
        );

        setRiskScoreOverTime(filteredRiskScoreOverTime);

        // Update description data
        if (filteredRiskScoreOverTime.length >= 2) {
          const firstPoint = filteredRiskScoreOverTime[0];
          const lastPoint =
            filteredRiskScoreOverTime[filteredRiskScoreOverTime.length - 1];
          const points = lastPoint.riskScore - firstPoint.riskScore;

          setScoreChange(points);
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [riskScoreOverAllTime],
  );

  const loadTestHTML = async (id) => {
    if (
      email === "michael.waite@dune.demo" ||
      email === "aaron.chavez@dune.demo"
    ) {
      // Demo account
      const testHTMLDemo = await getTestHTMLDemo(id);

      return testHTMLDemo;
    }

    const testHTML = await getTestHTML(id, email);

    return testHTML;
  };

  useEffect(() => {
    const loadInsightsEndUser = async () => {
      if (email === "michael.waite@dune.demo") {
        // Demo account for end user
        setShowHeadsUp(true);

        setRiskScore(97);

        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let riskScore = 50; // Start with a risk score of 50.

        const targetScore = 97;
        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options,
          );

          let stepChange = (targetScore - riskScore) / (totalDays - i);

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          riskScore += stepChange + variability + fluctuation;

          if (i === totalDays - 1 && riskScore < targetScore) {
            riskScore = targetScore;
          } else if (riskScore > targetScore) {
            riskScore = targetScore - Math.random();
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(riskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        setRiskScoreOverTime(riskScoreOverTime);
        setRiskScoreOverAllTime(riskScoreOverTime);

        setAreasToImprove([
          {
            name: "Display name deception",
          },
          {
            name: "Fake technical notifications",
          },
          {
            name: "Impersonation of internal processes",
          },
          {
            name: "Link manipulation",
          },
        ]);

        let pastTests = [
          {
            id: "1",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: true,
              isKeyDown: true,
              isDataEntered: true,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Adobe",
          },
          {
            id: "2",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "microsoft",
              isVisited: true,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: true,
            internalOrExternal: "Internal",
            difficulty: "Easy",
            attackVector: "Email phishing",
            methodOfAttack: "Impersonation of internal processes",
            impersonator: "Chase",
          },
          {
            id: "3",
            passed: true,
            source: {
              isURLClicked: null,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "ConEdison",
          },
          {
            id: "4",
            passed: true,
            source: {
              isURLClicked: null,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Easy",
            attackVector: "Email phishing",
            methodOfAttack: "Unexpected financial transactions",
            impersonator: "Delta",
          },
          {
            id: "5",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: true,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Display name deception",
            impersonator: "Google",
          },
          {
            id: "6",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: true,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Link manipulation",
            impersonator: "Intuit",
          },
          {
            id: "7",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: true,
              isKeyDown: true,
              isDataEntered: true,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Microsoft",
          },
          {
            id: "8",
            passed: true,
            source: {
              isURLClicked: null,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Display name deception",
            impersonator: "PayPal",
          },
          {
            id: "9",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: true,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Ticketmaster",
          },
          {
            id: "10",
            passed: false,
            source: {
              isURLClicked: true,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "microsoft",
              isVisited: true,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Request for action",
            impersonator: "1Password",
          },
        ];

        const dateForActivity = new Date();
        dateForActivity.setDate(dateForActivity.getDate() - 60);

        const optionsForActivity = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };

        for (let i = 0; i < pastTests.length; i++) {
          const randomDays = Math.floor(Math.random() * 3 + 3); // Generates 3, 4 or 5 days.
          dateForActivity.setDate(dateForActivity.getDate() + randomDays);

          const dayOfWeek = dateForActivity.getDay();

          if (dayOfWeek === 0 || dayOfWeek === 6) {
            dateForActivity.setDate(
              dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2),
            );
          }

          pastTests[i].date = dateForActivity.toLocaleDateString(
            "en-US",
            optionsForActivity,
          );
        }

        // Make the date of the last test today's date.
        const today = new Date();
        pastTests[pastTests.length - 1].date = today.toLocaleDateString(
          "en-US",
          optionsForActivity,
        );

        setPastTests(pastTests);
      } else if (email === "aaron.chavez@dune.demo") {
        // Demo account for manager
        setShowHeadsUp(false);

        const date90DaysAgo = new Date(); // Start with today's date.
        date90DaysAgo.setDate(date90DaysAgo.getDate() - 90);

        setRiskScore(14);

        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let riskScore = 78; // Start with a risk score of 78.

        const targetScore = 14;
        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options,
          );

          let stepChange = (riskScore - targetScore) / (totalDays - i);

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          riskScore -= stepChange + variability + fluctuation;

          if (i === totalDays - 1 && riskScore > targetScore) {
            riskScore = targetScore;
          } else if (riskScore < targetScore) {
            riskScore = targetScore + Math.random();
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(riskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        setRiskScoreOverTime(riskScoreOverTime);
        setRiskScoreOverAllTime(riskScoreOverTime);

        setAreasToImprove([]);

        let pastTests = [
          {
            id: "1",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Adobe",
          },
          {
            id: "2",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "microsoft",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "Internal",
            difficulty: "Easy",
            attackVector: "Email phishing",
            methodOfAttack: "Impersonation of internal processes",
            impersonator: "Chase",
          },
          {
            id: "3",
            passed: true,
            source: {
              isURLClicked: null,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "ConEdison",
          },
          {
            id: "4",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Easy",
            attackVector: "Email phishing",
            methodOfAttack: "Unexpected financial transactions",
            impersonator: "Delta",
          },
          {
            id: "5",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Display name deception",
            impersonator: "Google",
          },
          {
            id: "6",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Link manipulation",
            impersonator: "Intuit",
          },
          {
            id: "7",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Microsoft",
          },
          {
            id: "8",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Display name deception",
            impersonator: "PayPal",
          },
          {
            id: "9",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "google",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Hard",
            attackVector: "Email phishing",
            methodOfAttack: "Fake technical notifications",
            impersonator: "Ticketmaster",
          },
          {
            id: "10",
            passed: true,
            source: {
              isURLClicked: false,
              isQRScanned: null,
            },
            imposterPortal: {
              subdomain: "microsoft",
              isVisited: false,
              isKeyDown: false,
              isDataEntered: false,
            },
            hasReplied: false,
            internalOrExternal: "External",
            difficulty: "Medium",
            attackVector: "Email phishing",
            methodOfAttack: "Request for action",
            impersonator: "1Password",
          },
        ];

        const dateForActivity = new Date();
        dateForActivity.setDate(dateForActivity.getDate() - 60);

        const optionsForActivity = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };

        for (let i = 0; i < pastTests.length; i++) {
          const randomDays = Math.floor(Math.random() * 3 + 3); // Generates 3, 4 or 5 days.
          dateForActivity.setDate(dateForActivity.getDate() + randomDays);

          const dayOfWeek = dateForActivity.getDay();

          if (dayOfWeek === 0 || dayOfWeek === 6) {
            dateForActivity.setDate(
              dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2),
            );
          }

          pastTests[i].date = dateForActivity.toLocaleDateString(
            "en-US",
            optionsForActivity,
          );
        }

        // Make the date of the last test today's date.
        const today = new Date();
        pastTests[pastTests.length - 1].date = today.toLocaleDateString(
          "en-US",
          optionsForActivity,
        );

        setPastTests(pastTests);
      } else {
        const insightsEndUser = await getInsightsEndUser();

        if (Object.keys(insightsEndUser.error).length > 0) {
          console.error(insightsEndUser.error.message);
        } else {
          const {
            showHeadsUp,
            riskScore,
            scoreChange,
            riskScoreOverTime,
            areasToImprove,
            recentTestingActivity,
          } = insightsEndUser.result;

          setShowHeadsUp(showHeadsUp);
          setRiskScore(riskScore);
          setScoreChange(scoreChange);
          setRiskScoreOverAllTime(riskScoreOverTime);

          if (email === "kaila@dunesecurity.io") {
            // Temporary fix for Loom
            setAreasToImprove([]);
          } else {
            setAreasToImprove(areasToImprove);
          }

          setPastTests(recentTestingActivity);
        }
      }
    };

    if (!isInitialLoading && email) {
      loadInsightsEndUser();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  return (
    <Dashboard
      pageTitle={pagesPlatformEndUser.INSIGHTS}
      showHeadsUp={showHeadsUp}
      riskScore={riskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      pastTests={pastTests}
      selectedTest={selectedTest}
      setSelectedTest={setSelectedTest}
      onChangeSelectedDays={handleChangeSelectedDays}
      onLoadTestHTML={loadTestHTML}
    />
  );
}

export default InsightsEndUser;
