import React from "react";

const ChatBubbleAssetViewer = ({ content, title }) => {
  if (!content || typeof content !== "string") {
    return <div className="text-center">No content found</div>;
  }

  const messages = content
    .split("\n\n——————————————————\n\n")
    .filter((msg) => msg.trim());

  return (
    <div className="bg-white p-2 space-y-2">
      <div className="text-black">
        The <span className="font-medium">{title}</span> test is a coordinated
        attack that spans multiple payloads delivered over a specific period of
        time.
      </div>
      {messages.map((message, index) => (
        <div
          key={index}
          className="bg-[#E9E9EB] p-1 text-black rounded-medium w-full shadow-md text-h5"
        >
          <div
            dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br>") }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default ChatBubbleAssetViewer;
