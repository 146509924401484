const formatAssignedTraining = (assignedTraining) => {
  if (!assignedTraining || assignedTraining.length === 0) {
    return [];
  }

  let formattedAssignedTraining = [];

  const complianceFrameworks = [
    "GDPR",
    "GLBA",
    "HIPAA",
    "ISO 27001",
    "NIST",
    "SOC 2 Type 2",
  ];

  assignedTraining.forEach((training) => {
    if (!complianceFrameworks.includes(training.category)) {
      const learningModuleActivity = {
        id: training.assigned_training_id,
        trainingName: training.title,
        completionDate: training.completion_date,
        justificationSentiment: "",
        justificationUnderstanding: "",
        scoreOverall: training.score,
        scoreSentiment: 0,
        status: "",
        userAnswer: "",
      };

      if (training.status) {
        // Format the status of the assigned training.
        let status = training.status.replace(/_/g, " ");
        status = status
          .split(" ")
          .map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
          )
          .join(" ");

        learningModuleActivity.status = status;
      }

      if (
        training.response_s3_content &&
        Array.isArray(training.response_s3_content) &&
        training.response_s3_content.length > 0
      ) {
        learningModuleActivity.scoreSentiment =
          training.response_s3_content[0].sentiment_score;
        learningModuleActivity.userAnswer =
          training.response_s3_content[0].user_answer;

        // Just in case ChatGBT wants to use different variable names

        if (training.response_s3_content[0].sentiment_justification) {
          learningModuleActivity.justificationSentiment =
            training.response_s3_content[0].sentiment_justification;
        } else if (training.response_s3_content[0].justification_sentiment) {
          learningModuleActivity.justificationSentiment =
            training.response_s3_content[0].justification_sentiment;
        }

        if (training.response_s3_content[0].understanding_justification) {
          learningModuleActivity.justificationUnderstanding =
            training.response_s3_content[0].understanding_justification;
        } else if (
          training.response_s3_content[0].justification_understanding
        ) {
          learningModuleActivity.justificationUnderstanding =
            training.response_s3_content[0].justification_understanding;
        }
      }

      formattedAssignedTraining.push(learningModuleActivity);
    }
  });

  return formattedAssignedTraining;
};

export default formatAssignedTraining;
