import React from "react";

const WarningIconSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_4525_50325"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4525_50325)">
        <path
          d="M11.25 17.25H12.75V11.5H11.25V17.25ZM12 9.7885C12.2288 9.7885 12.4207 9.71108 12.5755 9.55625C12.7303 9.40142 12.8077 9.20958 12.8077 8.98075C12.8077 8.75192 12.7303 8.56008 12.5755 8.40525C12.4207 8.25058 12.2288 8.17325 12 8.17325C11.7712 8.17325 11.5793 8.25058 11.4245 8.40525C11.2697 8.56008 11.1923 8.75192 11.1923 8.98075C11.1923 9.20958 11.2697 9.40142 11.4245 9.55625C11.5793 9.71108 11.7712 9.7885 12 9.7885ZM12.0017 22C10.6877 22 9.45267 21.7507 8.2965 21.252C7.14033 20.7533 6.13467 20.0766 5.2795 19.2218C4.42433 18.3669 3.74725 17.3617 3.24825 16.206C2.74942 15.0503 2.5 13.8156 2.5 12.5017C2.5 11.1877 2.74933 9.95267 3.248 8.7965C3.74667 7.64033 4.42342 6.63467 5.27825 5.7795C6.13308 4.92433 7.13833 4.24725 8.294 3.74825C9.44967 3.24942 10.6844 3 11.9983 3C13.3123 3 14.5473 3.24933 15.7035 3.748C16.8597 4.24667 17.8653 4.92342 18.7205 5.77825C19.5757 6.63308 20.2528 7.63833 20.7518 8.794C21.2506 9.94967 21.5 11.1844 21.5 12.4983C21.5 13.8123 21.2507 15.0473 20.752 16.2035C20.2533 17.3597 19.5766 18.3653 18.7218 19.2205C17.8669 20.0757 16.8617 20.7528 15.706 21.2518C14.5503 21.7506 13.3156 22 12.0017 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z"
          fill="#F6F6F6"
        />
      </g>
    </svg>
  );
};

export default WarningIconSmall;
