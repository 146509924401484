import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createClient } from "contentful";

import { Grid, Link } from "@mui/material";
import { Translate } from "react-auto-translate/lib/commonjs";

import { ResourceCard, ViewAll } from "../../../pages/website/resources/utils";
import "./resources-group.css";
import FadeInSection from "../fade-in-section/fade-in-section";
import {
  formatDateFive,
  formatWhitepaperDate,
} from "../../../utils/helper-functions/formatDate";

function ResourcesGroup({ resources }) {
  const location = useLocation();

  const [fetchedTeam, setFetchedTeam] = useState([]);

  const filteredTeam = fetchedTeam.filter((resource) =>
    resource.title.toLowerCase(),
  );

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
      const client = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      });

      const fetchResources = async () => {
        try {
          const teamResponse = await client.getEntries({
            content_type: "resourcesTeam",
            limit: 3,
            order: "-fields.date,fields.title",
          });

          const teamResources = teamResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: item.fields.resourceURL,
          }));

          setFetchedTeam(teamResources);
        } catch (error) {
          console.error(error);
        }
      };

      fetchResources();
    }
  }, []);

  return (
    <>
      <Grid container>
        {resources.map((resource, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            lg={4}
            marginTop={{
              xs: index !== 0 ? "80px" : 0,
              sm: index !== 0 && index !== 1 ? "80px" : 0,
              lg: index > 2 ? "80px" : 0,
            }}
            paddingLeft={{
              xs: 0,
              sm: index % 2 === 1 ? "20px" : 0,
              lg:
                index % 3 === 2
                  ? `${(60 / 3) * 2}px`
                  : index % 3 === 1
                    ? `${60 / 3}px`
                    : 0,
            }}
            paddingRight={{
              xs: 0,
              sm: index % 2 === 0 ? "20px" : 0,
              lg:
                index % 3 === 0
                  ? `${(60 / 3) * 2}px`
                  : index % 3 === 1
                    ? `${60 / 3}px`
                    : 0,
            }}
          >
            <Link
              className="resources-group-link"
              href={resource.href}
              title={`Go to the resource named "${(
                <Translate>{resource.title}</Translate>
              )}".`}
              target="_blank"
            >
              {console.log("resource", resource.href)}
              <ResourceCard
                badge={{
                  title: resource.href.includes("whitepaper")
                    ? "Whitepaper"
                    : resource.href.includes("bootcamp")
                      ? "Bootcamp"
                      : resource.href.includes("webinar-recap-blog")
                        ? "Webinar"
                        : "From the team",
                  color: resource.href.includes("whitepaper")
                    ? "#7B3F61"
                    : resource.href.includes("bootcamp")
                      ? "#31708E"
                      : resource.href.includes("webinar-recap-blog")
                        ? "#A67C3B"
                        : "#4A7C59",
                }}
                imgSrc={resource.thumbnailURL}
                title={resource.title}
                date={formatWhitepaperDate(resource.date)}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
      {location.pathname === "/resources/bootcamp/" && (
        <FadeInSection>
          <div
            role="heading"
            aria-level="2"
            className="text-white mt-10 mb-5 text-h2 flex justify-center"
          >
            From the team
          </div>
          {filteredTeam.length > 0 ? (
            <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
              {filteredTeam.map((resource) => (
                <ResourceCard
                  badge={{ title: "From the team", color: "#4A7C59" }}
                  resourceHref={resource.href}
                  resourceId={resource.id}
                  imgSrc={resource.thumbnailURL}
                  title={resource.title}
                  date={formatDateFive(resource.date)}
                />
              ))}
            </div>
          ) : (
            <div>No resources found</div>
          )}
          <ViewAll href="/resources/team" />
        </FadeInSection>
      )}
    </>
  );
}

export default ResourcesGroup;
