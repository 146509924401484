import { getResponseGet } from "../utils";

const getInfographics = async () => {
  let infographics = {
    result: {},
    erro: {},
  };

  try {
    const response = await getResponseGet("/fetch-infographics/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    infographics.result = data;
  } catch (error) {
    infographics.error = error;
  }

  return infographics;
};

export default getInfographics;
