import React from "react";

const EcosystemEclipseSVG = ({ isMobile }) => {
  return (
    <svg
      role="presentation"
      width={isMobile ? "345" : "720"}
      height={isMobile ? "82.5" : "209"}
      viewBox="0 0 720 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        role="presentation"
        width="720"
        height="210"
        viewBox="0 0 720 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M717.134 104.812C717.134 118.027 707.883 131.12 689.918 143.438C672.027 155.704 645.985 166.844 613.596 176.237C548.847 195.014 459.261 206.66 360.21 206.66C261.158 206.66 171.572 195.014 106.824 176.237C74.4342 166.844 48.3927 155.704 30.502 143.438C12.5363 131.12 3.28531 118.027 3.28531 104.812C3.28531 91.5975 12.5363 78.5047 30.502 66.1868C48.3927 53.9202 74.4342 42.7803 106.824 33.3872C171.572 14.6102 261.158 2.96432 360.21 2.96432C459.261 2.96432 548.847 14.6102 613.596 33.3872C645.985 42.7803 672.027 53.9202 689.918 66.1868C707.883 78.5047 717.134 91.5975 717.134 104.812Z"
          fill="#00FF82"
          fillOpacity="0.02"
          stroke="#0A3D23"
          opacity={0.3}
          strokeWidth="4.67638"
        />
        <rect
          x="555.305"
          y="15.9525"
          width="17.6818"
          height="6.78018"
          transform="rotate(11.837 555.305 15.9525)"
          fill="#0C0C0B"
        />
        <path
          d="M555.008 10.8446C554.559 9.93682 554.925 8.83712 555.829 8.3799C556.739 7.91958 557.849 8.28381 558.31 9.19345L561.501 15.4995C562.811 18.0896 563.467 19.3846 563.519 20.6296C563.577 21.9927 563.149 23.332 562.311 24.4088C561.546 25.3924 560.261 26.067 557.691 27.4162L551.285 30.7789C550.336 31.277 549.163 30.902 548.679 29.9458C548.197 28.9932 548.585 27.8304 549.542 27.3573L554.178 25.0658C555.249 24.5368 555.784 24.2723 556.191 23.9725C558.139 22.5399 558.984 20.0435 558.307 17.7225C558.166 17.2368 557.901 16.7016 557.372 15.6312L555.008 10.8446Z"
          fill="#00FF82"
        />
        <path
          d="M555.009 10.8466C554.567 9.95233 554.939 8.86342 555.842 8.40619C556.752 7.9459 557.857 8.29909 558.31 9.19508L562.522 17.5177C563.559 19.566 564.077 20.5902 564.073 21.5809C564.069 22.4526 563.79 23.3045 563.274 24.0167C562.688 24.8263 561.656 25.3679 559.593 26.4511L551.324 30.7915C550.361 31.2972 549.176 30.9283 548.692 29.9721C548.21 29.0196 548.61 27.8508 549.581 27.3707L554.453 24.9628C556.572 23.9159 557.631 23.3924 558.239 22.5863C558.775 21.8773 559.072 21.0212 559.088 20.1408C559.106 19.1399 558.591 18.0965 557.56 16.0096L555.009 10.8466Z"
          fill="#00FF82"
        />
        <rect
          x="553.73"
          y="8.09985"
          width="3.59263"
          height="3.59263"
          transform="rotate(-26.842 553.73 8.09985)"
          fill="#00FF82"
        />
        <rect
          x="548.482"
          y="32.2026"
          width="3.89482"
          height="3.52937"
          transform="rotate(-116.638 548.482 32.2026)"
          fill="#00FF82"
        />
        <rect
          x="570.868"
          y="189.32"
          width="17.3991"
          height="6.78018"
          transform="rotate(164.966 570.868 189.32)"
          fill="#0C0C0B"
        />
        <path
          d="M574.193 196.15C575.003 196.757 575.173 197.903 574.574 198.72C573.971 199.541 572.815 199.718 571.993 199.115L566.297 194.932C563.957 193.214 562.787 192.355 562.177 191.268C561.51 190.079 561.286 188.69 561.547 187.351C561.785 186.128 562.626 184.945 564.309 182.58L568.504 176.685C569.125 175.812 570.341 175.616 571.205 176.251C572.065 176.883 572.245 178.095 571.605 178.949L568.505 183.089C567.789 184.045 567.431 184.523 567.203 184.974C566.113 187.133 566.488 189.741 568.14 191.506C568.486 191.875 568.964 192.233 569.92 192.949L574.193 196.15Z"
          fill="#00FF82"
        />
        <path
          d="M574.191 196.149C574.989 196.747 575.149 197.886 574.55 198.702C573.947 199.524 572.801 199.708 571.992 199.114L564.473 193.594C562.623 192.235 561.698 191.556 561.254 190.67C560.863 189.891 560.727 189.005 560.865 188.136C561.022 187.149 561.698 186.2 563.049 184.301L568.463 176.692C569.093 175.805 570.317 175.599 571.181 176.233C572.041 176.865 572.213 178.088 571.564 178.955L568.306 183.305C566.889 185.197 566.181 186.143 566.003 187.137C565.846 188.011 565.968 188.909 566.351 189.702C566.787 190.603 567.719 191.3 569.582 192.696L574.191 196.149Z"
          fill="#00FF82"
        />
        <rect
          x="576.573"
          y="198.021"
          width="3.59263"
          height="3.59263"
          transform="rotate(126.287 576.573 198.021)"
          fill="#00FF82"
        />
        <rect
          x="570.361"
          y="174.148"
          width="3.89482"
          height="3.52937"
          transform="rotate(36.4916 570.361 174.148)"
          fill="#00FF82"
        />
        <rect
          x="169.266"
          y="193.246"
          width="18.75"
          height="6.78018"
          transform="rotate(-169.557 169.266 193.246)"
          fill="#0C0C0B"
        />
        <path
          d="M169.909 200.456C170.38 201.353 170.04 202.461 169.148 202.94C168.25 203.422 167.131 203.085 166.648 202.187L163.305 195.961C161.931 193.403 161.245 192.124 161.162 190.881C161.071 189.52 161.466 188.17 162.278 187.074C163.019 186.072 164.287 185.366 166.824 183.955L173.146 180.437C174.082 179.916 175.264 180.262 175.771 181.207C176.276 182.147 175.917 183.319 174.972 183.815L170.392 186.219C169.335 186.774 168.806 187.051 168.406 187.361C166.494 188.84 165.71 191.357 166.443 193.66C166.596 194.143 166.874 194.671 167.428 195.728L169.909 200.456Z"
          fill="#00FF82"
        />
        <path
          d="M169.908 200.454C170.371 201.337 170.026 202.435 169.134 202.914C168.236 203.396 167.123 203.07 166.647 202.185L162.234 193.968C161.148 191.945 160.605 190.934 160.585 189.944C160.568 189.072 160.826 188.214 161.325 187.489C161.891 186.665 162.909 186.099 164.946 184.966L173.106 180.425C174.056 179.897 175.25 180.236 175.757 181.181C176.262 182.121 175.891 183.299 174.932 183.803L170.12 186.329C168.027 187.427 166.981 187.976 166.392 188.796C165.875 189.518 165.599 190.381 165.604 191.262C165.61 192.263 166.15 193.294 167.232 195.355L169.908 200.454Z"
          fill="#00FF82"
        />
        <rect
          x="171.253"
          y="203.169"
          width="3.59263"
          height="3.59263"
          transform="rotate(151.764 171.253 203.169)"
          fill="#00FF82"
        />
        <rect
          x="175.913"
          y="178.946"
          width="3.89482"
          height="3.52937"
          transform="rotate(61.9678 175.913 178.946)"
          fill="#00FF82"
        />
        <rect
          x="162.601"
          y="17.4128"
          width="17.2582"
          height="7.23562"
          transform="rotate(-13.4242 162.601 17.4128)"
          fill="#0C0C0B"
        />
        <path
          d="M158.911 12.4045C158.053 11.8668 157.789 10.7382 158.318 9.8751C158.851 9.00609 159.988 8.73377 160.857 9.26684L166.881 12.9624C169.356 14.4802 170.593 15.2392 171.291 16.2716C172.055 17.402 172.392 18.7669 172.244 20.1231C172.108 21.3618 171.368 22.6102 169.887 25.107L166.197 31.3296C165.65 32.2514 164.454 32.5473 163.541 31.9869C162.631 31.4287 162.351 30.2353 162.918 29.3306L165.664 24.9477C166.298 23.936 166.615 23.4301 166.804 22.9611C167.711 20.72 167.121 18.1512 165.328 16.5299C164.953 16.1906 164.447 15.8736 163.435 15.2396L158.911 12.4045Z"
          fill="#00FF82"
        />
        <path
          d="M158.914 12.4055C158.068 11.8758 157.814 10.7536 158.343 9.89043C158.876 9.02148 160.002 8.74272 160.858 9.2678L168.809 14.1451C170.766 15.3455 171.745 15.9457 172.261 16.7915C172.715 17.5356 172.923 18.4074 172.858 19.2843C172.784 20.281 172.189 21.2832 171 23.2877L166.237 31.3196C165.682 32.2553 164.479 32.5626 163.566 32.0023C162.656 31.4441 162.383 30.2396 162.958 29.3212L165.844 24.7159C167.099 22.7134 167.726 21.7122 167.821 20.7067C167.905 19.8223 167.709 18.9377 167.261 18.1796C166.752 17.3177 165.766 16.6997 163.793 15.4636L158.914 12.4055Z"
          fill="#00FF82"
        />
        <rect
          x="156.384"
          y="10.7375"
          width="3.59263"
          height="3.59263"
          transform="rotate(-58.4738 156.384 10.7375)"
          fill="#00FF82"
        />
        <rect
          x="164.556"
          y="34.0119"
          width="3.89482"
          height="3.52937"
          transform="rotate(-148.27 164.556 34.0119)"
          fill="#00FF82"
        />
        <defs>
          <linearGradient
            id="paint0_linear_232_14423"
            x1="0.947113"
            y1="104.812"
            x2="719.473"
            y2="104.812"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FF82" stopOpacity="0.1" />
            <stop offset="0.24519" stopColor="#00FF82" stopOpacity="0.9" />
            <stop offset="0.455067" stopColor="#00FF82" stopOpacity="0.1" />
            <stop offset="0.72" stopColor="#00FF82" stopOpacity="0.9" />
            <stop offset="0.96" stopColor="#00FF82" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>

      <defs>
        <linearGradient id="fadeDash" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="rgba(0, 255, 130, 0)" />
          <stop offset="20%" stopColor="rgba(0, 255, 130, 1)" />
          <stop offset="80%" stopColor="rgba(0, 255, 130, 1)" />
          <stop offset="100%" stopColor="rgba(0, 255, 130, 0)" />
        </linearGradient>
      </defs>

      <path
        d="M717.134 104.812C717.134 118.027 707.883 131.12 689.918 143.438C672.027 155.704 645.985 166.844 613.596 176.237C548.847 195.014 459.261 206.66 360.21 206.66C261.158 206.66 171.572 195.014 106.824 176.237C74.4342 166.844 48.3927 155.704 30.502 143.438C12.5363 131.12 3.28531 118.027 3.28531 104.812C3.28531 91.5975 12.5363 78.5047 30.502 66.1868C48.3927 53.9202 74.4342 42.7803 106.824 33.3872C171.572 14.6102 261.158 2.96432 360.21 2.96432C459.261 2.96432 548.847 14.6102 613.596 33.3872C645.985 42.7803 672.027 53.9202 689.918 66.1868C707.883 78.5047 717.134 91.5975 717.134 104.812Z"
        stroke="url(#fadeDash)"
        strokeWidth="4.59846"
        fill="none"
        strokeDasharray="200 1600"
        strokeDashoffset="0"
        style={{
          animation: "dash 5s linear infinite",
        }}
      />

      <style>
        {`
          @keyframes dash {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: -1800; // Adjust to move the dash
            }
          }
        `}
      </style>
    </svg>
  );
};

export default EcosystemEclipseSVG;
