import React, { cloneElement, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import { useMediaQuery, useTheme } from "@mui/material";

import { useDescope, useSession, useUser } from "@descope/react-sdk";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import {
  getCurrentMSSPOrganization,
  getMSSPOrganizations,
  getUser,
} from "../../../api";

import { Avatar, Breadcrumbs } from "../../common";

import { NavigationPlatformSide, NavigationPlatformTop } from "../../layout";

import { MSSP_ORGANIZATIONS } from "../../../utils";
import LanguageSelector from "../../../utils/helper-functions/language-selector";
import { videoLanguages } from "../../../utils/constants/common/languages";

function PlatformContainer({ title, mainPage, tracker }) {
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("language") || "en_us",
  );

  const handleLanguageChange = (language) => {
    if (language !== selectedLanguage) {
      setSelectedLanguage(language);
      Cookies.set("language", language, { expires: 999 });
      window.location.reload(); // Reload the page to set the language in cookies and pass it down to the video player.
    }
  };

  const theme = useTheme();
  let isScreenDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLogOut, setIsLoadingLogOut] = useState(false);
  const [isPlatformLoading, setIsPlatformLoading] = useState(false);

  // Messages (error and success)
  const [messageError, setMessageError] = useState("");

  // Cognito user access
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperadmin, setIsSuperadmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  // User
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [signedInEmail, setSignedInEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [isOnboarded, setIsOnboarded] = useState(null);
  const [isInCampaign, setIsInCampaign] = useState(false);
  const [hasBRAStarted, setHasBRAStarted] = useState(false);
  const [isBRACompleted, setIsBRACompleted] = useState(false);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [pictureFileName, setPictureFileName] = useState(""); // For company logo
  const [msspOrganizations, setMSSPOrganizations] = useState([]);
  const [selectedMSSPOrganization, setSelectedMSSPOrganization] = useState({});

  const [email, setEmail] = useState("");

  // Other
  const [newTitle, setNewTitle] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const location = useLocation();

  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  const { logout } = useDescope();

  const handleClickLogOut = async () => {
    logOut();
  };

  const handleClickMSSPOrganization = async (msspOrganization) => {
    console.log(msspOrganization);

    setSelectedMSSPOrganization(msspOrganization.name);

    if (email !== "david@dune.demo") {
      const currentMSSPOrganizationData = await getCurrentMSSPOrganization(
        msspOrganization.id,
      );

      if (Object.keys(currentMSSPOrganizationData.error).length > 0) {
        console.error(currentMSSPOrganizationData.error.message);
      } else {
        setIsLoading(true);

        loadUserInfo().finally(() => {
          setIsLoading(false);
        });
      }
    }
  };

  const logOut = useCallback(async () => {
    setIsLoadingLogOut(true);
    await logout();
    setIsLoadingLogOut(false);
  }, [logout]);

  const loadDescopeUser = useCallback(async () => {
    const { email, roleNames, userTenants } = user;

    setSignedInEmail(email);

    let tenantRoles = [];

    if (userTenants && userTenants.length > 0 && userTenants[0].roleNames) {
      tenantRoles = userTenants[0].roleNames;
    }

    if (
      roleNames.includes("Superadmin") ||
      tenantRoles.includes("Superadmin")
    ) {
      setIsSuperadmin(true);
    }

    if (roleNames.includes("Admin") || tenantRoles.includes("Admin")) {
      setIsAdmin(true);
    }

    if (roleNames.includes("Manager") || tenantRoles.includes("Manager")) {
      setIsManager(true);
    }

    // Set the user ID for OpenReplay.
    if (tracker) {
      tracker.setUserID(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracker, isUserLoading, user]);

  const loadUserInfo = useCallback(async () => {
    const userInfo = await getUser();

    if (Object.keys(userInfo.error).length > 0) {
      setMessageError(userInfo.error.message);

      // Log out if the user is not in the database.
      await logOut();
    } else {
      const {
        firstName,
        lastName,
        email,
        initials,
        organization,
        isOnboarded,
        isInCampaign,
        hasBRAStarted,
        isBRACompleted,
        startTimestamp,
        pictureFileName,
      } = userInfo.result;

      setFirstName(firstName);
      setLastName(lastName);
      setInitials(initials);
      setOrganization(organization);
      setIsOnboarded(isOnboarded);
      setIsInCampaign(isInCampaign);
      setHasBRAStarted(hasBRAStarted);
      setIsBRACompleted(isBRACompleted);
      setStartTimestamp(startTimestamp);
      setPictureFileName(pictureFileName);

      setEmail(email);

      console.log(isPlatformLoading); // To delete.
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut, isUserLoading, user]);

  useEffect(() => {
    const updateComponent = async () => {
      // Remove isFederatedSignIn from localStorage.
      const isFederatedSignIn = localStorage.getItem("isFederatedSignIn");

      if (isFederatedSignIn) {
        localStorage.removeItem("isFederatedSignIn");
      }
    };

    updateComponent();
  }, [mainPage]);

  useEffect(() => {
    const loadMSSPOrganizations = async () => {
      if (email === "david@dune.demo") {
        // Demo account
        setMSSPOrganizations(MSSP_ORGANIZATIONS);
      } else {
        const msspOrganizationsData = await getMSSPOrganizations();

        if (Object.keys(msspOrganizationsData.error).length > 0) {
          console.error(msspOrganizationsData.error.message);
        } else {
          const { msspOrganizations } = msspOrganizationsData.result;

          setMSSPOrganizations(msspOrganizations);
        }
      }
    };

    const loadFingerprintJS = async () => {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY,
        endpoint: ["https://fp.dunesecurity.io", FingerprintJS.defaultEndpoint],
        scriptUrlPattern: [
          "https://fp.dunesecurity.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJS.defaultScriptUrlPattern,
        ],
      });

      fpPromise
        .then((fp) =>
          fp.get({
            linkedId: email,
          }),
        )
        .catch((error) => {
          console.error("Error tagging user with FingerprintJS:", error);
        });
    };

    if (!isLoadingLogOut) {
      if (!isSessionLoading && !isAuthenticated) {
        setIsLoggedIn(false);
      } else if (!isSessionLoading && !isUserLoading) {
        setIsLoading(true);

        (async () => {
          await loadDescopeUser();
          await loadMSSPOrganizations();

          if (email === "david@dune.demo") {
            setSelectedMSSPOrganization(MSSP_ORGANIZATIONS[0]);
          } else {
            const filteredMSSPOrganizationsByEmail = msspOrganizations.filter(
              (organization) => organization.email === email,
            );

            if (filteredMSSPOrganizationsByEmail.length > 0) {
              setSelectedMSSPOrganization(filteredMSSPOrganizationsByEmail[0]);
            } else if (msspOrganizations.length > 0) {
              setSelectedMSSPOrganization(msspOrganizations[0]);
            }
          }

          loadUserInfo().finally(() => {
            setIsLoading(false);

            loadFingerprintJS();
          });
        })();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isSessionLoading,
    isUserLoading,
    loadDescopeUser,
    loadUserInfo,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNewTitle(null);
    setBreadcrumbs([]);
  }, [location.pathname]);

  if (!isLoggedIn) {
    if (messageError) {
      return <Navigate to="/error/" />;
    }

    return <Navigate to="/login/" />;
  }

  const routesOnboarding = [
    "/onboarding/",
    "/onboarding/launch-initial-testing/",
    "/onboarding/setup-dmi/",
    "/onboarding/setup-dmi/exchange/",
    "/onboarding/setup-dmi/google-workspace/",
    "/onboarding/setup-sso-and-scim/",
    "/onboarding/setup-sso-and-scim/entra-id/",
    "/onboarding/setup-sso-and-scim/google-workspace/",
    "/onboarding/setup-sso-and-scim/okta/",
    "/onboarding/setup-sso-and-scim/post-setup-verification/",
    "/onboarding/setup-watchtower/google/",
    "/onboarding/setup-watchtower/microsoft/",
  ];

  const routesInitialTesting = [
    "/account-settings/",
    "/configure/integrations/",
    "/configure/testing/",
    "/configure/testing/request/",
    "/configure/testing/schedule/",
    "/configure/trainings/",
    "/configure/trainings/frequency/",
    "/configure/trainings/request/",
    "/insights/initial-testing/",
    "/insights/users/search/",
    ...routesOnboarding,
  ];

  const routesNotAllowedAfterBRA = [
    // Pages should not be accessible after the BRA is completed.
    "/welcome/",
    "/compliance-onboarding/",
    "/employees/",
    "/campaigns/",
  ];

  // List of pages only admins/superadmins are able to see
  const routesAdmin = [
    ...routesInitialTesting,
    "/adaptive-controls/",
    "/campaigns/",
    "/compliance-onboarding/",
    "/compliance/",
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/completed/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/learning/",
    "/employees/",
    "/insights/departments/",
    "/insights/departments/search/",
    "/insights/organization/",
    "/insights/organization/device-protection-status/",
    "/insights/organization/mfa-protection-status/",
    "/insights/organization/training-status/",
    "/insights/organization/user-login-activity/",
    "/insights/organization/anomalous-activity/",
    "/insights/users/", // "/insights/users/search/" is part of routesInitialTesting.
    "/integrations/",
    "/reporting/",
    "/settings/dune-nudge/",
    "/sso-settings/",
    "/welcome/",
  ];

  // List of pages only managers are able to see
  const routesManager = [
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/completed/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/learning/",
    "/insights/users/",
    "/insights/your-reports/",
  ];

  // List of pages end users are only able to see
  const routesEndUser = [
    "/dashboard/insights/",
    "/dashboard/trainings/",
    "/dashboard/trainings/learning/",
    "/dashboard/trainings/compliance/",
    "/dashboard/trainings/completed/",
  ];

  if (
    !isLoading &&
    (isSuperadmin || isAdmin) &&
    !isBRACompleted &&
    !routesInitialTesting.includes(window.location.pathname)
  ) {
    return <Navigate to="/insights/initial-testing/" />;
  }

  if (
    !isLoading &&
    ((!isSuperadmin &&
      !isAdmin &&
      !isManager &&
      !routesEndUser.includes(window.location.pathname)) ||
      ((isSuperadmin || isAdmin) &&
        !routesAdmin.includes(window.location.pathname) &&
        // Special case for ConfifgureIntegrationsService:
        !window.location.pathname.includes("/configure/integrations/")) ||
      (isManager && !routesManager.includes(window.location.pathname)))
  ) {
    return <Navigate to="/error/" />;
  }

  // Admin needs to finish onboarding flow before proceeding to the rest of the platform.
  if (
    isOnboarded === false &&
    !routesOnboarding.includes(window.location.pathname)
  ) {
    return <Navigate to="/onboarding/" />;
  }

  if (
    isBRACompleted &&
    routesNotAllowedAfterBRA.includes(window.location.pathname)
  ) {
    return (
      <Navigate
        to={
          isSuperadmin || isAdmin
            ? "/insights/organization/"
            : isManager
              ? "/insights/your-reports/"
              : "/dashboard/insights/"
        }
      />
    );
  }

  // Special case: Onboarding screen does not use the regular template.
  if (window.location.pathname.startsWith("/onboarding/")) {
    return (
      <>
        <div className="sticky top-0 z-40 w-full bg-gray-dark">
          <div className="flex flex-col-reverse justify-between w-full border-b border-border-color md:items-center md:flex-row min-h-[63px]">
            <div className="p-0.75">
              <div className="w-full mx-auto max-w-platform">
                <div className="flex items-center">
                  <div className="mr-0.25">
                    <Avatar
                      size="40px"
                      padding="p-0"
                      backgroundColor="inherit"
                      borderColor="rgba(194, 191, 184, 0.35)"
                    >
                      <img
                        className="rounded-full"
                        src="https://d3oo9a669kwmx7.cloudfront.net/company-logos/dune-security.png"
                        alt="Dune Security"
                      />
                    </Avatar>
                  </div>
                  <div>
                    <h2 className="h2">Dune Security</h2>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <NavigationPlatformTop
                email={signedInEmail}
                firstName={firstName}
                initials={initials}
                lastName={lastName}
                isOnboarded={isOnboarded}
                onLogOut={handleClickLogOut}
              />
            </div>
          </div>
        </div>
        <div className="w-full max-w-full">
          <div className="flex">
            <div
              className="flex flex-col items-center w-full min-w-0"
              role="main"
            >
              <div className="w-full">
                <div className="mx-auto">
                  {cloneElement(mainPage, {
                    isInitialLoading: isLoading,
                    email,
                    organization,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="sticky top-0 z-40 w-full bg-gray-dark">
        <div className="flex flex-col-reverse justify-between w-full border-b border-border-color md:items-center md:flex-row min-h-[63px]">
          <div className="p-0.75">
            <div className="w-full mx-auto max-w-platform">
              <div className="flex items-center">
                {pictureFileName && (
                  <div className="mr-0.25">
                    <Avatar
                      size="40px"
                      padding="p-0"
                      backgroundColor="inherit"
                      borderColor="rgba(194, 191, 184, 0.35)"
                    >
                      <img
                        className="rounded-full"
                        src={`https://d3oo9a669kwmx7.cloudfront.net/company-logos/${pictureFileName}`}
                        alt={organization}
                      />
                    </Avatar>
                  </div>
                )}
                <div role="heading" aria-level="2" className="h2">
                  {organization}
                </div>
              </div>
            </div>
          </div>
          <div>
            <NavigationPlatformTop
              email={signedInEmail}
              firstName={firstName}
              initials={initials}
              lastName={lastName}
              isOnboarded={isOnboarded}
              onLogOut={handleClickLogOut}
            />
          </div>
        </div>
      </div>

      <div className="w-full max-w-full">
        <div className="flex min-h-screen">
          {isScreenDesktop && (
            <div className="max-w-[200px] w-full border-r border-r-border-color">
              <NavigationPlatformSide
                email={email}
                organization={organization}
                isInitialLoading={isLoading}
                isBRACompleted={isBRACompleted}
                isAdmin={isSuperadmin || isAdmin}
                isManager={isManager}
                msspOrganizations={msspOrganizations}
                selectedMSSPOrganization={selectedMSSPOrganization}
                onClickMSSPOrganization={handleClickMSSPOrganization}
              />
            </div>
          )}
          <div
            className="flex flex-col items-center w-full min-w-0"
            role="main"
          >
            <div className="w-full p-2">
              <div className="pb-8.25 mx-auto max-w-platform">
                {breadcrumbs && breadcrumbs.length > 0 && (
                  <div className="mb-0.5">
                    <Breadcrumbs pages={breadcrumbs} />
                  </div>
                )}
                {(title || newTitle) && (
                  <div className="flex justify-between">
                    {
                      // Special case: Do not include this for /dashboard/insights/
                      window.location.pathname !== "/dashboard/insights/" && (
                        <div role="heading" aria-level="2" className="mb-2 h2">
                          {newTitle ? newTitle : title}
                        </div>
                      )
                    }
                    {email.includes("dune.dynamics") &&
                      location.pathname ===
                        "/dashboard/trainings/learning/" && (
                        <div className="pl-0 md:pl-0.5 md:mr-3 mr-1.5">
                          <LanguageSelector
                            initialLanguage={selectedLanguage}
                            onChange={handleLanguageChange}
                            selectedLanguage={selectedLanguage}
                            showLanguageLabel={true}
                            showSearchBar={false}
                            languages={videoLanguages}
                            dropdownText=""
                            showLanguageSelectionText={true}
                            languageSelectionText="Language:"
                          />
                        </div>
                      )}
                  </div>
                )}
                <div>
                  {cloneElement(mainPage, {
                    isInitialLoading: isLoading,
                    email,
                    organization: organization,
                    setIsPlatformLoading: setIsPlatformLoading,
                    setNewTitle: setNewTitle,
                    setBreadcrumbs: setBreadcrumbs,

                    // TODO: Check if we are still using the following.
                    firstName: firstName,
                    lastName: lastName,
                    hasBRAStarted: hasBRAStarted,
                    isBRACompleted: isBRACompleted,
                    isInCampaign: isInCampaign,
                    startTimestamp: startTimestamp,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlatformContainer;
