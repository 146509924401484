import React from "react";

import {
  ButtonOutlinedWhiteTransparent,
  ButtonTextWhite,
  Card,
} from "../../../..";

import { CloseIcon, WarningIconSmall } from "../../../../../../utils";

const CardHeadsUp = ({ onClickClose }) => {
  const handleClickSeeDetails = () => {
    const element = document.getElementById("card-test-calendar");

    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.scrollY - 100; // Adjust scroll position 400px before the element

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <Card backgroundColor="rgba(255, 43, 0, 0.20)" borderColor="#b22222">
      <div className="flex justify-between gap-4">
        <div className="flex w-full px-1 md:flex-col">
          <div className="flex flex-col w-full gap-1 md:gap-2 md:items-center md:justify-between md:flex-row">
            <div className="flex flex-col gap-1 md:gap-2 md:items-center md:flex-row">
              <div className="w-[24px] h-[24px]">
                <WarningIconSmall />
              </div>
              <div className="flex flex-col gap-0.5 max-w-[648px] w-full">
                <p className="font-medium h4">
                  Heads up! You clicked on a link in a phishing test.
                </p>
                <p className="body">This increased your risk score slightly.</p>
              </div>
            </div>
            <div>
              <ButtonOutlinedWhiteTransparent
                title="View the details of the failed phishing test."
                onClick={handleClickSeeDetails}
              >
                See Details
              </ButtonOutlinedWhiteTransparent>
            </div>
          </div>
        </div>
        {/* TODO */}
        <div className="-mt-0.5 -mr-0.5">
          <ButtonTextWhite
            title="Close this card."
            isSmall
            onClick={onClickClose}
          >
            <div className="w-[24px] h-[24px]">
              <CloseIcon />
            </div>
          </ButtonTextWhite>
        </div>
      </div>
    </Card>
  );
};

export default CardHeadsUp;
