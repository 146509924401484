import { getResponseGet } from "../utils";

const getCrowdstrikeLogCount = async () => {
  const crowdStrikeLogCount = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponseGet("/crowdstrike-log-count");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    crowdStrikeLogCount.result = data;
  } catch (error) {
    crowdStrikeLogCount.error = error;
  }

  return crowdStrikeLogCount;
};

export default getCrowdstrikeLogCount;
