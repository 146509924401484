import React from "react";

const HugoBossLogo = () => {
  return (
    <img
      src="https://d3oo9a669kwmx7.cloudfront.net/logos/HUGO-BOSS_black 1.png"
      alt="HealthEdge Logo"
      className="max-w-12 w-full"
    />
  );
};

export default HugoBossLogo;
