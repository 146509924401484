import {
  WarnerMusicGroupLogo,
  SaveTimeLogo,
  SaveMoneyLogo,
  ReduceRiskLogo,
  PersonIcon,
  InvisibleLogo,
  ViewInArOutlinedIcon,
  VerifiedUserOutlinedIcon,
  CulliganLogo,
  AssignmentOutlinedIcon,
} from "../../icons/";

import { HealthEdgeLogo, HugoBossLogo, OSFLogo } from "../../images/logos";

import {
  AdsClickOutlined,
  AnalyticsOutlined,
  FeedbackOutlined,
  StarBorderOutlined,
} from "@mui/icons-material";

const benefitsIconClassName = "home-icon-benefit";

export const customersDesktop = [
  {
    name: "OSF Healthcare",
    logo: <OSFLogo />,
  },
  {
    name: "Culligan",
    logo: <CulliganLogo />,
  },
  {
    name: "Hugo Boss",
    logo: <HugoBossLogo />,
  },
  {
    name: "Warner Music Group",
    logo: <WarnerMusicGroupLogo />,
  },
  {
    name: "HealthEdge",
    logo: <HealthEdgeLogo />,
  },
  {
    name: "Invisible",
    logo: <InvisibleLogo />,
  },
];

export const customersMobile = [
  {
    name: "OSF Healthcare",
    logo: <OSFLogo />,
  },
  {
    name: "Culligan",
    logo: <CulliganLogo />,
  },
  {
    name: "Hugo Boss",
    logo: <HugoBossLogo />,
  },
  {
    name: "Warner Music Group",
    logo: <WarnerMusicGroupLogo />,
  },
  {
    name: "HealthEdge",
    logo: <HealthEdgeLogo />,
  },
  {
    name: "Invisible",
    logo: <InvisibleLogo />,
  },
];

export const threats = [
  {
    title: "Increased Use of Generative AI for Attacks",
    icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/connection.gif",
    staticIcon: "https://d3oo9a669kwmx7.cloudfront.net/icons/connection.png",
    description:
      "Cybercriminals use AI tools like ChatGPT to create highly convincing phishing attacks, increasing both sophistication and scale.",
  },
  {
    title: "Advanced Persistent Threats and State-Sponsored Attacks",
    icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.gif",
    staticIcon:
      "https://d3oo9a669kwmx7.cloudfront.net/icons/cyberterrorism.png",
    description:
      "APTs and state-sponsored attacks use complex social engineering techniques to gain and maintain long-term access to targeted systems.",
  },
  {
    title: "Rise of Business Email Compromise (BEC)",
    icon: "https://d3oo9a669kwmx7.cloudfront.net/icons/message.gif",
    staticIcon: "https://d3oo9a669kwmx7.cloudfront.net/icons/message.png",
    description:
      "BEC attacks manipulate users into transferring funds or disclosing sensitive information by impersonating executives or trusted partners.",
  },
];

export const categorySolutions = [
  {
    category: "Security Awareness Training",
    solutions: [
      {
        idx: 1,
        title: "User-Adaptation",
        icon: <PersonIcon />,
        description:
          "Training modules adjust based on user behavior and risk level, enhancing learning effectiveness and engagement.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation.png",
      },
      {
        idx: 2,
        title: "Real Time Feedback",
        icon: <FeedbackOutlined />,
        description:
          "Immediate, personalized interventions reinforce positive behaviors and address potential vulnerabilities, proactively mitigating risks.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_2.png",
      },
      {
        idx: 3,
        title: "Comprehensive Reporting",
        icon: <AssignmentOutlinedIcon />,
        description:
          "Comprehensive analytics provide actionable intelligence on user progress, risk reduction trends, and overall security posture improvements, facilitating strategic decision-making and simplified compliance reporting.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_3.png",
      },
      {
        idx: 4,
        title: "Automatic Compliance Training",
        icon: <VerifiedUserOutlinedIcon />,
        description:
          "Automate and optimize adherence to industry standards and regulations, ensuring thorough coverage while reducing administrative burden.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_4.png",
      },
    ],
  },
  {
    category: "Red Team Social Engineering",
    solutions: [
      {
        idx: 1,
        title: "Hyper-Realistic Simulations",
        icon: <ViewInArOutlinedIcon />,
        description:
          "Test defenses with sophisticated, real-world attack scenarios customized to your organization, proactively identifying and addressing critical vulnerabilities.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_5.png",
      },
      {
        idx: 2,
        title: "Sophisticated and Specific",
        icon: <StarBorderOutlined />,
        description:
          "Utilize advanced techniques like phishing, smishing, vishing, and deepfakes to evaluate responses and gain strategic insights.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Group_48095492.png",
      },
      {
        idx: 3,
        title: "Comprehensive Assessments",
        icon: <AnalyticsOutlined />,
        description:
          "Detailed, actionable reports outline vulnerabilities and remediation strategies, enhancing overall security posture.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Dune_Security_User_Adaptation_6.png",
      },
      {
        idx: 4,
        title: "Direct Engagement",
        icon: <AdsClickOutlined />,
        description:
          "Work with SOCs and CISOs to customize threat scenarios and enhance security measures, delivering tailored and impactful insights.",
        imgUrl:
          "https://d3oo9a669kwmx7.cloudfront.net/website-home-categorySolutions/Group_48095491.png",
      },
    ],
  },
];

export const aiPoints = [
  {
    id: 0,
    title: "Quantify Employee Risk",
    description:
      "Comprehensively quantify user risk using a variety of input source data, and aggregate the results at organizational, departmental, manager, and other levels.",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-quantify.png",
  },
  {
    id: 1,
    title: "Reduce Employee Risk",
    description:
      "Quantifiably and automatically reduce risk for users through adaptive, timely training tailored to individual and departmental needs in real-time. Ensure compliance and robust protection by continuously enhancing employee awareness and behavior.",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-reduce.png",
  },
  {
    id: 2,
    title: "Adapt Security Controls Around Risk",
    description:
      "For the highest-risk users, seamlessly integrate with your IDP and existing tech stack to dynamically apply restrictions, adjust permissions, and enhance security controls, such as email filters.",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/ai-adapt.png",
  },
];

export const benefits = [
  {
    title: "Save Time",
    icon: <SaveTimeLogo />,
    description:
      "Automate cybersecurity and reduce manual security tasks like campaign scheduling, training assignments, security controls and reporting.",
  },
  {
    title: "Reduce Risk",
    icon: <ReduceRiskLogo />,
    description:
      "Leverage AI to detect and mitigate risks by analyzing user behavior, offering tailored training and dynamic security to prevent breaches.",
  },
  {
    title: "Save Money",
    icon: <SaveMoneyLogo className={benefitsIconClassName} />,
    description:
      "Lower security costs with targeted interventions for high-risk users, cutting down on breaches and inefficient training programs.",
  },
];

export const investors = [
  {
    name: "Craft",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png",
  },
  {
    name: "Alumni Ventures",
    picture:
      "https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png",
  },
  {
    name: "Antler",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png",
  },
  {
    name: "Firestreak",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png",
  },
  {
    name: "Sequoia Scout",
    picture:
      "https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png",
  },
];
