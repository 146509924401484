import React, { useState, useEffect } from "react";

import {
  getPostUserGuardRails,
  getBraTestingSchedule,
  postBraTestingSchedule,
  postUserGuardRails,
} from "../../../../../api";

import { ButtonOutlinedGreen, DropdownIcon } from "../../../../../components";

import {
  DeleteDumpsterIcon,
  DoubleArrowDropdownIcon,
} from "../../../../../utils/icons";
import { capitalizeFirstWords } from "../../../../../utils/helper-functions/capitalize";

import "./configure-testing-frequency.css";

const ConfigureTestingFrequency = ({ setBreadcrumbs }) => {
  const [editMode, setEditMode] = useState(false);
  const [editInitialMode, setEditInitialMode] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [riskGroupCount, setRiskGroupCount] = useState([]);
  const [selectUserGroupDropdown, setSelectUserGroupDropdown] = useState(false);
  const [selectedGroupMinTest, setSelectedGroupMinTest] = useState("");
  const [selectedGroupMaxTest, setSelectedGroupMaxTest] = useState("");
  const [error, setError] = useState("");
  const [resData, setResData] = useState();
  const [organizationMinTests, setOrganizationMinTests] = useState(3);
  const [organizationMaxTests, setOrganizationMaxTests] = useState(5);
  const [initialTestDuration, setInitialTestDuration] = useState(0);
  const [socialEngineeringTests, setSocialEngineeringTests] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPostUserGuardRails();

        if (data && data.Result) {
          setResData(data.Result);

          const orgTests =
            data.Result.test_frequency_groups?.frequency_data?.all || [];
          setOrganizationMinTests(orgTests[0]);
          setOrganizationMaxTests(orgTests[1]);

          const frequencyData =
            data.Result.test_frequency_groups?.frequency_data || {};
          const initialRiskGroupCount = Object.entries(frequencyData)
            .filter(([key]) => key !== "all")
            .map(([key, value]) => ({
              [key.replace("_", " ")]: value,
            }));
          setRiskGroupCount(initialRiskGroupCount);
        } else {
          console.error("Invalid API response:", data);
          setResData(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setResData(null);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      { name: "Testing", path: "/configure/testing/" },
      { name: "Configure Testing" },
    ]);
  }, [setBreadcrumbs]);

  const groupOrder = ["severe", "high", "medium", "low"];

  const validateInputs = (minValue, maxValue) => {
    const trimmedMin = String(minValue)?.trim();
    const trimmedMax = String(maxValue)?.trim();

    if (trimmedMin === "" || trimmedMax === "") {
      return "All inputs must be filled.";
    }

    if (isNaN(trimmedMin) || isNaN(trimmedMax)) {
      return "All inputs must be valid numbers.";
    }

    if (Number(trimmedMin) < 0 || Number(trimmedMax) < 0) {
      return "Values must be non-negative.";
    }

    if (Number(trimmedMin) > Number(trimmedMax)) {
      return "Minimum value must be less than or equal to the maximum value.";
    }

    if (Number(trimmedMin) > 70 || Number(trimmedMax) > 70) {
      return "Values must be more than 70.";
    }

    return null;
  };

  const sortedRiskGroupCount = [...riskGroupCount].sort((a, b) => {
    const groupA = Object.keys(a)[0];
    const groupB = Object.keys(b)[0];
    return groupOrder.indexOf(groupA) - groupOrder.indexOf(groupB);
  });

  const availableUserGroups = groupOrder.filter(
    (group) =>
      !riskGroupCount.some(
        (existingGroup) => Object.keys(existingGroup)[0] === group,
      ),
  );

  const toggleSelectUserGroupDropdown = () =>
    setSelectUserGroupDropdown(!selectUserGroupDropdown);

  const handleSelectUserGroup = (group) => {
    setRiskGroupCount((prev) => [
      ...prev,
      { [group]: [selectedGroupMinTest, selectedGroupMaxTest] },
    ]);
    setError("");
    setSelectedGroupMinTest("");
    setSelectedGroupMaxTest("");
  };

  const handleDeleteRiskGroup = (index) => {
    setRiskGroupCount((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const handleCancelButton = () => {
    setEditMode(false);
  };

  const handleSaveButton = async () => {
    const errorMsg = validateInputs(organizationMinTests, organizationMaxTests);
    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    const isUserGroupValid = riskGroupCount.every((group) => {
      const [min, max] = Object.values(group)[0];
      return validateInputs(min, max) === null;
    });

    if (!isUserGroupValid) {
      setError("Values must be more than 70.");
      return;
    }

    try {
      const userGroupData = riskGroupCount.reduce((acc, group) => {
        const [key] = Object.keys(group);
        const [minValue, maxValue] = group[key];

        acc[key.replace(" ", "_")] = [Number(minValue), Number(maxValue)];
        return acc;
      }, {});

      const updatedFrequencyData = {
        ...userGroupData,
        all: [Number(organizationMinTests), Number(organizationMaxTests)],
      };

      const requestBody = { test_frequency: updatedFrequencyData };

      setResData((prev) => ({
        ...prev,
        test_frequency_groups: {
          ...prev.test_frequency_groups,
          frequency_data: updatedFrequencyData,
        },
      }));

      const response = await postUserGuardRails(requestBody);

      console.log("Data saved successfully", response);
      setEditMode(false);
      setError("");
    } catch (error) {
      console.error("Error saving data:", error);
      setError("Failed to save data. Please try again.");
    }
  };

  useEffect(() => {
    const fetchBraData = async () => {
      const braTestingResponse = await getBraTestingSchedule();

      if (braTestingResponse.error.length > 0) {
        console.log("Error fetching data:", braTestingResponse.error);
      } else {
        setInitialTestDuration(braTestingResponse.result.num_weeks);
        setSocialEngineeringTests(braTestingResponse.result.tests_per_week);
      }
    };

    fetchBraData();
  }, []);

  const handleUpdateBraTesting = async () => {
    const response = await postBraTestingSchedule({
      tests_per_week: socialEngineeringTests,
      num_weeks: initialTestDuration,
    });

    if (response.error.length > 0) {
      console.log("Error saving data:", response.error);
    } else {
      setEditInitialMode(false);
    }
  };

  const handleWheelDisable = (e) => e.preventDefault();

  useEffect(() => {
    if (!resData?.test_frequency_groups) {
      setEditMode(true);
      setEditInitialMode(true);
      setIsAccordionOpen(true);
      setRiskGroupCount([]);
      setOrganizationMinTests(0);
      setOrganizationMaxTests(1);
    }
  }, [resData]);

  if (!resData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-white">
      <div>
        <div className="flex justify-between items-cente mb-0.5">
          <div className="text-h4">Configure Initial Testing</div>
          {!editInitialMode && (
            <ButtonOutlinedGreen onClick={() => setEditInitialMode(true)}>
              Modify
            </ButtonOutlinedGreen>
          )}
        </div>
        <div className="p-1 mb-2 border border-gray border-opacity-35 rounded-medium bg-gray-background">
          <div className="lg:flex-row flex flex-col lg:items-center justify-between my-3">
            <div className="flex items-center justify-between lg:w-1/2 lg:mb-0 mb-2">
              <div>
                <div className="text-h5">Social Engineering Tests per Week</div>
                <div className="w-12 text-h6">
                  Number of emails sent to new employees each week
                </div>
              </div>
              {editInitialMode ? (
                <input
                  aria-label="Social Engineering Tests per Week"
                  className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                  value={socialEngineeringTests}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNaN(value) && value !== "") {
                      setError("Please enter a valid number.");
                      return;
                    }
                    setSocialEngineeringTests(value);
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener("wheel", handleWheelDisable)
                  }
                  onBlur={(e) =>
                    e.target.removeEventListener("wheel", handleWheelDisable)
                  }
                  required
                />
              ) : (
                <div className="mr-8 text-h2">{socialEngineeringTests}</div>
              )}
            </div>
            <div className="flex items-center justify-between lg:w-1/2">
              <div>
                <div className="text-h5">Initial Test Duration</div>
                <div className="w-12 text-h6">
                  Number of weeks the initial assessment lasts
                </div>
              </div>
              {editInitialMode ? (
                <input
                  aria-label="Initial Test Duration"
                  className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-5"
                  value={initialTestDuration}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNaN(value) && value !== "") {
                      setError("Please enter a valid number.");
                      return;
                    }
                    setInitialTestDuration(value);
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener("wheel", handleWheelDisable)
                  }
                  onBlur={(e) =>
                    e.target.removeEventListener("wheel", handleWheelDisable)
                  }
                  required
                />
              ) : (
                <div className="mr-8 text-h2">{initialTestDuration}</div>
              )}
            </div>
          </div>
          {editInitialMode && (
            <div className="flex items-center justify-end space-x-1">
              <ButtonOutlinedGreen onClick={() => setEditInitialMode(false)}>
                Cancel
              </ButtonOutlinedGreen>
              <ButtonOutlinedGreen onClick={handleUpdateBraTesting}>
                Save
              </ButtonOutlinedGreen>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between mb-0.5">
          <div className="mb-0.5 text-h4">Configure Ongoing Testing</div>
          {!editMode && (
            <ButtonOutlinedGreen
              onClick={() => {
                setEditMode(!editMode);
                setIsAccordionOpen(true);
              }}
            >
              Modify/Add
            </ButtonOutlinedGreen>
          )}
        </div>

        <div className="p-1 border bg-gray-background border-gray border-opacity-35 min-h-2 rounded-medium">
          <div className="font-medium text-h5">Organization rules</div>
          <div className="lg:flex-row flex flex-col lg:items-center mt-3.625 mb-3.125">
            <div className="flex items-center justify-between lg:w-1/2 lg:mb-0 mb-2">
              <div className="text-h5">Minimum tests per quarter</div>
              {editMode ? (
                <input
                  aria-label="Minimum tests per quarter"
                  className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                  value={organizationMinTests || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNaN(value) && value !== "") {
                      setError("Please enter a valid number.");
                      return;
                    }
                    setOrganizationMinTests(value);
                    setError(""); // Clear error if valid
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener("wheel", handleWheelDisable)
                  }
                  onBlur={(e) =>
                    e.target.removeEventListener("wheel", handleWheelDisable)
                  }
                />
              ) : (
                <div className="mr-8 text-h2">
                  {resData.test_frequency_groups?.frequency_data?.all?.[0]}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between lg:w-1/2">
              <div className="text-h5">Maximum tests per quarter</div>
              {editMode ? (
                <input
                  aria-label="Maximum tests per quarter"
                  className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-5"
                  value={organizationMaxTests || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNaN(value) && value !== "") {
                      setError("Please enter a valid number.");
                      return;
                    }
                    setOrganizationMaxTests(value);
                    setError("");
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener("wheel", handleWheelDisable)
                  }
                  onBlur={(e) =>
                    e.target.removeEventListener("wheel", handleWheelDisable)
                  }
                />
              ) : (
                <div className="mr-8 text-h2">
                  {resData?.test_frequency_groups?.frequency_data?.all?.[1]}
                </div>
              )}
            </div>
          </div>

          <div
            className={`${editMode ? "" : "p-1 cursor-pointer border-y border-gray border-opacity-35"}`}
          >
            <div
              className="flex items-center justify-between"
              onClick={editMode ? null : handleAccordion}
            >
              <div className="font-medium">Custom rules for User Groups</div>
              <div
                className={`duration-300 ${isAccordionOpen ? "rotate-180" : ""}`}
              >
                {!editMode && <DropdownIcon />}
              </div>
            </div>
          </div>

          {isAccordionOpen && (
            <div>
              <div>
                {sortedRiskGroupCount.map((group, index) => {
                  const riskLevel = Object.keys(group)[0];
                  const [minValue, maxValue] = group[riskLevel];
                  return (
                    <div key={index}>
                      <div className="flex-row lg:flex lg:lex-col items-center mt-2.5">
                        <div className="lg:w-1/2">
                          <div className="flex items-center justify-between lg:mb-0 mb-2">
                            <div>
                              <div className="capitalize text-h5">
                                {capitalizeFirstWords(riskLevel)} Risk Users
                              </div>
                              <div className="text-h6">
                                Minimum tests per Quarter
                              </div>
                            </div>
                            {editMode ? (
                              <input
                                aria-label="Minimum tests per quarter"
                                value={minValue}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (isNaN(value) && value !== "") {
                                    setError("Please enter a valid number.");
                                    return;
                                  }

                                  setRiskGroupCount((prev) =>
                                    prev.map((g, i) =>
                                      i === index
                                        ? {
                                            ...g,
                                            [riskLevel]: [value, maxValue],
                                          }
                                        : g,
                                    ),
                                  );
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    handleWheelDisable,
                                  )
                                }
                                onBlur={(e) =>
                                  e.target.removeEventListener(
                                    "wheel",
                                    handleWheelDisable,
                                  )
                                }
                                className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                              />
                            ) : (
                              <div className="pr-4 mr-4 text-h2">
                                {minValue}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="lg:w-1/2">
                          <div className="flex items-center justify-between">
                            <div>
                              <div className="capitalize text-h5">
                                {capitalizeFirstWords(riskLevel)} Risk Users
                              </div>
                              <div className="text-h6">
                                Maximum tests per Quarter
                              </div>
                            </div>
                            {editMode ? (
                              <div className="flex items-center">
                                <input
                                  aria-label="Maximum tests per quarter"
                                  value={maxValue}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (isNaN(value) && value !== "") {
                                      setError("Please enter a valid number.");
                                      return;
                                    }

                                    setRiskGroupCount((prev) =>
                                      prev.map((g, i) =>
                                        i === index
                                          ? {
                                              ...g,
                                              [riskLevel]: [minValue, value],
                                            }
                                          : g,
                                      ),
                                    );
                                  }}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      handleWheelDisable,
                                    )
                                  }
                                  onBlur={(e) =>
                                    e.target.removeEventListener(
                                      "wheel",
                                      handleWheelDisable,
                                    )
                                  }
                                  className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                                />
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleDeleteRiskGroup(index)}
                                >
                                  <DeleteDumpsterIcon />
                                </div>
                              </div>
                            ) : (
                              <div className="pr-3 mr-5 text-h2">
                                {maxValue}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 text-gray text-opacity-35">
                        <hr />
                      </div>
                    </div>
                  );
                })}

                {editMode && availableUserGroups.length > 0 && (
                  <div className="relative lg:flex items-center justify-between mt-4">
                    <div className="flex items-center justify-between lg:w-1/2 lg:mb-0 mb-2">
                      <div>
                        <button
                          onClick={toggleSelectUserGroupDropdown}
                          className="flex items-center border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                        >
                          <div className="text-h5 mr-2 text-[#848483]">
                            Select User Group
                          </div>
                          <div className="mr-1">
                            <DoubleArrowDropdownIcon />
                          </div>
                        </button>

                        {selectUserGroupDropdown && (
                          <div className="absolute z-10 px-1 bg-black border border-gray border-opacity-35 rounded-medium w-14">
                            {availableUserGroups.map((group) => (
                              <div
                                key={group}
                                onClick={() => handleSelectUserGroup(group)}
                                className="py-0.5 cursor-pointer border-y border-gray border-opacity-35"
                              >
                                {capitalizeFirstWords(group)} Risk Users
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <input
                        disabled
                        aria-label="Minimum tests"
                        placeholder="Minimum tests"
                        value={selectedGroupMinTest}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (isNaN(value) && value !== "") {
                            setError("Please enter a valid number.");
                            return;
                          }
                          setSelectedGroupMinTest(value);
                          setError("");
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener("wheel", handleWheelDisable)
                        }
                        onBlur={(e) =>
                          e.target.removeEventListener(
                            "wheel",
                            handleWheelDisable,
                          )
                        }
                        className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                      />
                    </div>

                    <div className="flex items-center justify-between lg:w-1/2">
                      <div>
                        <div className="text-h5 text-[#848483]">
                          Select User Group
                        </div>

                        <div className="text-h6">Maximum tests per Quarter</div>
                      </div>
                      <input
                        disabled
                        aria-label="Maximum tests"
                        placeholder="Maximum tests"
                        value={selectedGroupMaxTest}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (isNaN(value) && value !== "") {
                            setError("Please enter a valid number.");
                            return;
                          }
                          setSelectedGroupMaxTest(e.target.value);
                          setError("");
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener("wheel", handleWheelDisable)
                        }
                        onBlur={(e) =>
                          e.target.removeEventListener(
                            "wheel",
                            handleWheelDisable,
                          )
                        }
                        className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-5"
                      />
                    </div>
                  </div>
                )}
              </div>
              {editMode && (
                <div className="flex items-center justify-end mt-3.875 space-x-1">
                  <ButtonOutlinedGreen onClick={handleCancelButton}>
                    Cancel
                  </ButtonOutlinedGreen>
                  <ButtonOutlinedGreen onClick={handleSaveButton}>
                    Save
                  </ButtonOutlinedGreen>
                </div>
              )}
            </div>
          )}
        </div>

        {error && <div className="mt-1 text-red">{error}</div>}
      </div>
    </div>
  );
};

export default ConfigureTestingFrequency;
