import {
  formatAreasToImproveUser,
  formatRecentTestingActivity,
  formatRiskScore,
  formatRiskScoreOverTime,
  formatScoreChange,
  formatShowHeadsUp,
  getResponseGet,
} from "../utils";

const getInsightsEndUser = async () => {
  let insightsEndUser = {
    result: {
      showHeadsUp: false,
      riskScore: 0,
      scoreChange: 0,
      riskScoreOverTime: [],
      areasToImprove: [],
      recentTestingActivity: [],
      compliance: {},
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      "/personal_user_risk_data/?area_to_improve=method",
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    insightsEndUser.result = {
      showHeadsUp: formatShowHeadsUp(data?.past_attacks),
      riskScore: formatRiskScore(data?.risk_score?.current_risk_score),
      scoreChange: formatScoreChange(
        data.risk_score.current_risk_score,
        data.time_series,
      ),
      userAverage: formatRiskScore(data.user_average),
      riskScoreOverTime: formatRiskScoreOverTime(data?.time_series),
      areasToImprove: formatAreasToImproveUser(data.areas_to_improve),
      recentTestingActivity: formatRecentTestingActivity(data?.past_attacks),
    };
  } catch (error) {
    insightsEndUser.error = error;
  }

  return insightsEndUser;
};

export default getInsightsEndUser;
