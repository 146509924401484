import React, { useEffect, useState } from "react";

import { ButtonTextWhite } from "../buttons";

import { BackIcon, NextIcon } from "../../../utils/icons";

const Calendar = ({ testingActivities, selectedTest, setSelectedTest }) => {
  const defaultCurrentYear = new Date().getFullYear();
  const defaultCurrentMonth = new Date().getMonth();

  const [currentYear, setCurrentYear] = useState(defaultCurrentYear);
  const [currentMonth, setCurrentMonth] = useState(defaultCurrentMonth);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const handleClickCalendarDay = (test) => {
    setSelectedTest(test);
  };

  const handleClickNext = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleClickPrevious = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfWeek = firstDayOfMonth.getDay();

    const calendarDays = [];

    // Add empty slots for days before the first day of the month.
    for (let i = 0; i < firstDayOfWeek; i++) {
      calendarDays.push(<div key={`empty-${i}`} />);
    }

    // Add the days of the month.
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedCurrentDate = `${currentYear}/${String(
        currentMonth + 1,
      ).padStart(2, "0")}/${String(day).padStart(2, "0")}`; // Format: YYYY/MM/DD

      const testsOnDate =
        testingActivities?.filter((test) => {
          const testDate = new Date(test.date);
          const formattedActivityDate = `${testDate.getFullYear()}/${String(
            testDate.getMonth() + 1,
          ).padStart(
            2,
            "0",
          )}/${String(testDate.getDate()).padStart(2, "0")}`;
          return formattedActivityDate === formattedCurrentDate;
        }) || [];

      const badgeCount = testsOnDate.length;

      // Check if this date has an test.
      let test = null;

      if (testingActivities) {
        test = testingActivities.find((test) => {
          const testDate = new Date(test.date);

          // Format the test date to YYYY/MM/DD for comparison.
          const formattedActivityDate = `${testDate.getFullYear()}/${String(
            testDate.getMonth() + 1,
          ).padStart(2, "0")}/${String(testDate.getDate()).padStart(
            2,
            "0",
          )}`;

          return formattedActivityDate === formattedCurrentDate;
        });
      }

      let calendarDay = <></>;

      if (test && selectedTest) {
        const currentDate = new Date();

        const isFuture = new Date(test.date) > currentDate;

        calendarDay = (
          <div
            button="role"
            className={`relative description-emphasized text-center p-[10px] ${
              test ? "cursor-pointer font-bold" : ""
            } ${
              selectedTest?.date !== test.date && isFuture
                ? "outline-[#00974D] outline-dashed outline-2 -outline-offset-2"
                : "outline-none"
            }`}
            style={{
              background:
                selectedTest?.date === test.date
                  ? test.passed
                    ? "#00ff82"
                    : "#ef3c1f"
                  : isFuture
                    ? "transparent"
                    : test.passed
                      ? "rgba(0, 255, 130, 0.20)"
                      : "rgba(239, 60, 31, 0.20)",
              color:
                selectedTest?.date === test.date
                  ? test.passed
                    ? "#0c0c0b"
                    : "#0c0c0b"
                  : isFuture
                    ? "#00974d"
                    : test.passed
                      ? "#00974d"
                      : "#ef3c1f",
            }}
            onClick={() => handleClickCalendarDay(test)}
            key={`calendar-day-${day}`}
          >
            {day}
            {badgeCount > 1 && (
              <div
                className="absolute top-0.25 right-0.25 bg-green-background text-white rounded-full w-[15px] h-[15px] flex items-center justify-center text-h6.5 z-10"
                style={{
                  transform: "translate(50%, -50%)",
                }}
              >
                {badgeCount}
              </div>
            )}
          </div>
        );
      } else {
        calendarDay = (
          <div
            className="description-emphasized text-center font-bold p-[10px]"
            style={{
              color: "#737272",
            }}
            key={`calendar-day-${day}`}
          >
            {day}
            {badgeCount > 1 && (
              <div
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-[20px] h-[20px] flex items-center justify-center text-sm font-bold"
                style={{
                  transform: "translate(50%, -50%)",
                }}
              >
                {badgeCount}
              </div>
            )}
          </div>
        );
      }

      calendarDays.push(calendarDay);
    }

    return calendarDays;
  };

  useEffect(() => {
    // Set the current month and current year to the recent testing test that is selected.
    if (selectedTest) {
      const selectedDate = selectedTest.date;

      if (selectedDate) {
        const dateObject = new Date(selectedDate);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth();

        setCurrentYear(year);
        setCurrentMonth(month);
      }
    }
  }, [selectedTest]);

  return (
    <div className="flex min-h-[326px] w-[310.23px]">
      <div className="">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="font-semibold body">
              {monthNames[currentMonth]} {currentYear}
            </h4>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex">
              <ButtonTextWhite
                isSmall
                title="Go to the previous month."
                onClick={handleClickPrevious}
                className="detailed"
              >
                <BackIcon />
              </ButtonTextWhite>
            </div>
            <div className="flex">
              <ButtonTextWhite
                isSmall
                title="Go to the next month."
                onClick={handleClickNext}
                className="detailed"
              >
                <NextIcon />
              </ButtonTextWhite>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-[4px] mt-[10px]">
          {daysOfWeek.map((day, index) => (
            <div
              key={`days-of-week-${index}`}
              className="description-emphasized text-center p-[10px]"
            >
              {day}
            </div>
          ))}
          {generateCalendar()}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
