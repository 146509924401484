import React from "react";

const MicrosoftPurviewColorIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.116 17.6049C19.3477 17.6049 17.9135 16.1721 17.9113 14.4038C15.752 14.4276 14.0204 16.198 14.0442 18.3573C14.0679 20.5166 15.8384 22.2482 17.9977 22.2244C20.157 22.2007 21.8886 20.4302 21.8648 18.2709C21.8619 18.0225 21.836 17.7756 21.7863 17.5322C21.566 17.5797 21.3414 17.6042 21.116 17.6049Z"
        fill="url(#paint0_linear_1339_29550)"
      />
      <path
        d="M25.6314 22.4084C25.4881 20.9986 25.046 19.635 24.3354 18.4088C24.1287 21.7575 21.356 24.3704 18.0001 24.3762C17.8482 24.3762 17.7056 24.3423 17.5573 24.3308C14.1985 24.2869 10.9801 22.9736 8.54866 20.6552C6.4585 18.6471 4.90834 16.1437 4.04146 13.3774C2.89738 14.4171 1.8145 15.5216 0.797136 16.6851C0.0684958 17.3598 0.0238558 18.4974 0.698496 19.226C0.730176 19.2606 0.763296 19.293 0.797136 19.3246C2.98378 21.9469 5.56066 24.2185 8.43633 26.0588C11.3286 28.041 14.7529 29.1022 18.2593 29.1037C20.5381 29.1166 22.7867 28.5817 24.8156 27.5449C24.9438 27.4858 25.0547 27.4066 25.1814 27.3476C25.6307 25.7427 25.7833 24.068 25.6314 22.4084Z"
        fill="url(#paint1_linear_1339_29550)"
      />
      <path
        d="M12.3841 20.9644C11.0349 18.4797 11.5129 15.4002 13.552 13.4418C15.154 11.2854 17.3989 9.69425 19.9643 8.89721C21.3625 8.47025 22.8141 8.24129 24.2757 8.21753C23.0992 7.70777 21.868 7.33625 20.6065 7.11089C17.1513 6.52913 13.6002 7.11521 10.515 8.77697C8.78918 9.67265 7.15982 10.744 5.6543 11.9745C6.36494 14.6874 7.79918 17.1563 9.80366 19.1176C10.5769 19.8455 11.4453 20.4669 12.3841 20.9644Z"
        fill="url(#paint2_linear_1339_29550)"
      />
      <path
        d="M35.2507 16.7732C33.3196 14.4599 31.0898 12.4143 28.6195 10.6892C26.0361 9.98363 23.3095 9.99731 20.7333 10.7288C19.96 10.965 19.2119 11.2818 18.5063 11.6771C19.6101 11.7548 20.6743 12.1199 21.593 12.7362C21.6751 12.7599 21.7543 12.7952 21.8263 12.8406C24.8999 14.9048 26.9714 18.1607 27.5387 21.8197C27.7195 23.2395 27.6971 24.6767 27.4732 26.09C30.4015 24.2288 33.0223 21.9248 35.2427 19.2587C35.9311 18.6179 35.9699 17.54 35.3284 16.8517C35.3032 16.8243 35.2773 16.7984 35.2507 16.7732Z"
        fill="url(#paint3_linear_1339_29550)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1339_29550"
          x1="17.9545"
          y1="22.2247"
          x2="17.9545"
          y2="14.4038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29BADE" />
          <stop offset="1" stopColor="#50E6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1339_29550"
          x1="12.9543"
          y1="29.1039"
          x2="12.9543"
          y2="6.86995"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.041" stopColor="#32BEDD" />
          <stop offset="0.495" stopColor="#50E6FF" />
          <stop offset="0.92" stopColor="#C3F1FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1339_29550"
          x1="14.965"
          y1="20.069"
          x2="14.965"
          y2="0.718073"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.244" stopColor="#0078D4" />
          <stop offset="0.6" stopColor="#2E8CE1" />
          <stop offset="0.933" stopColor="#589EED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1339_29550"
          x1="27.1459"
          y1="29.1171"
          x2="27.1459"
          y2="8.50148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005BA1" />
          <stop offset="0.399" stopColor="#0078D4" />
          <stop offset="0.857" stopColor="#5EA0EF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MicrosoftPurviewColorIcon;
