import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import { useNavigate } from "react-router-dom";
import { formatDateTwo } from "../../../utils/helper-functions/formatDate";
import { ButtonOutlinedGray } from "../../../components";
import { BackIcon, NextIcon } from "../../../utils";
import { ResourceCard } from "../resources/utils";

const BootcampCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bootCampData, setBootCampData] = useState([]);

  const navigate = useNavigate();

  const cardWidth = 384;
  const visibleCards = 3;

  const handleClickViewAll = () => {
    navigate("/resources/");
  };

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollRight = () => {
    if (currentIndex < bootCampData.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const fetchedBootcamp = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        await client
          .getEntries({
            content_type: "resourcesBootcamp",
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            const data = response.items.map((item) => {
              const { fields, sys } = item;
              return {
                id: sys.id,
                title: fields.title,
                href: `/resources/bootcamp/${fields.resourceID}`,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
              };
            });
            setBootCampData(data);
          })
          .catch(console.error);
      }
    };

    fetchedBootcamp();
  }, []);

  return (
    <div className="my-8 max-w-78.75 mx-auto text-white">
      <div className="mx-1.25">
        <p role="heading" aria-level="1.5" className="text-h2">
          Resources
        </p>
        <div className="flex flex-wrap items-center justify-between">
          <p className="text-h4 mt-0.625 mb-2.75 md:mb-0">
            Access expert insights, whitepapers, and tools to boost your
            cybersecurity.
          </p>
          <div className="flex items-center space-x-0.5">
            <ButtonOutlinedGray
              title="Scroll left."
              isSmall
              onClick={scrollLeft}
              disabled={currentIndex === 0}
            >
              <BackIcon />
            </ButtonOutlinedGray>
            <ButtonOutlinedGray
              title="Scroll right."
              isSmall
              onClick={scrollRight}
              disabled={currentIndex >= bootCampData.length - visibleCards}
            >
              <NextIcon />
            </ButtonOutlinedGray>
            <ButtonOutlinedGray
              title="View all."
              isSmall
              onClick={handleClickViewAll}
            >
              <div className="text-white">View All</div>
            </ButtonOutlinedGray>
          </div>
        </div>
      </div>

      <div className="mt-2.5 mb-2.75 ml-1.25">
        <div className="overflow-x-auto no-scrollbar">
          <div
            className="flex space-x-2 transition-transform duration-300"
            style={{
              transform: `translateX(-${currentIndex * cardWidth}px)`,
              width: `${bootCampData.length * cardWidth}px`,
            }}
          >
            {bootCampData.map((item, index) => (
              <div key={index} style={{ width: `${cardWidth}px` }}>
                <ResourceCard
                  badge={{
                    title: "Bootcamp",
                    color: "#31708E",
                  }}
                  key={index}
                  resourceHref={item.href}
                  imgSrc={item.thumbnailURL}
                  title={item.title}
                  date={formatDateTwo(item.date)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampCarousel;
