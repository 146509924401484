import React, { useEffect, useState } from "react";

import { Box, Link, Typography } from "@mui/material";

import "./banner-notification.css";

function BannerNotification() {
  const [notifications] = useState([
    {
      description:
        "Dune Security raises $6M Seed Round from Toba Capital, Craft Ventures, MassMutual Ventures, Alumni Ventures, and Firestreak Ventures",
      link: "Read more",
    },
  ]);
  const [selectedNotification, setSelectedNotification] = useState({
    description: "",
  });

  useEffect(() => {
    if (notifications.length > 0) {
      setSelectedNotification(notifications[0]);
    }
  }, [notifications]);

  return (
    <Box
      className="banner-notification-box-main"
      display="flex"
      flexWrap={["wrap", "nowrap"]}
      justifyContent="center"
      position="sticky"
      padding="12px 30px"
    >
      <Typography
        variant="description-emphasized"
        component="p"
        className="banner-notification-text-description"
        textAlign="center"
        marginBottom={{
          xs: "10px",
          md: "0",
        }}
      >
        {selectedNotification.description}
      </Typography>
      <Link
        href="https://www.linkedin.com/feed/update/urn:li:share:7292913983387299840/"
        title="Read about Dune Security raising $2MM from Craft, Alumni Ventures, Firestreak, Antler, Sequoia Scout."
        target="_blank"
        sx={{
          color: "#000000",
        }}
      >
        <Typography
          variant="description-emphasized"
          component="p"
          className="banner-notification-text-link"
          textAlign="center"
        >
          {selectedNotification.link}
        </Typography>
      </Link>
    </Box>
  );
}

export default BannerNotification;
