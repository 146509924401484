import React, { useEffect, useState } from "react";

import { CautionIcon } from "./utils";

import {
  ButtonOutlinedGray,
  Calendar,
  Card,
  CardHeader,
  CardWithLoadingState,
} from "../../../..";

import {
  BackIcon,
  CheckmarkWithCircleIcon,
  FailIcon,
  NextIcon,
} from "../../../../../../utils";

function CardTestingCalendarForUser({
  pastTests,
  firstName,
  selectedTest,
  setSelectedTest,
  onLoadTestHTML,
}) {
  // const [selectedTest, setSelectedTest] = useState(null);
  const [testHTML, setTestHTML] = useState("");

  const getHighlights = () => {
    const highlights = [];

    if (selectedTest) {
      if (!selectedTest.passed) {
        // Fail

        // Replied
        if (selectedTest.hasReplied) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } responded to the message.`,
          });
        }

        // Clicked
        if (selectedTest.source && selectedTest.source.isURLClicked) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } clicked on the link.`,
          });
        }

        // QR code scanned
        if (selectedTest.source && selectedTest.source.isQRScanned) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } scanned the QR code.`,
          });
        }

        // Download attachemnt
        if (selectedTest.source && selectedTest.source.hasDownloaded) {
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } downloaded the attachment.`,
          });
        }

        // Credentials
        if (
          selectedTest.imposterPortal &&
          selectedTest.imposterPortal.isKeyDown
        ) {
          highlights.push({
            icon: <FailIcon />,
            description: `${firstName ? firstName : "You"} entered ${
              firstName ? "their" : "your"
            } credentials.`,
          });
        }

        // MFA code
        if (
          selectedTest.imposterPortal &&
          selectedTest.imposterPortal.isMFAEntered
        ) {
          highlights.push({
            icon: <FailIcon />,
            description: `${firstName ? firstName : "You"} entered ${
              firstName ? "their" : "your"
            } MFA code.`,
          });
        }
      } else {
        // Pass

        if (!selectedTest.hasReported) {
          // Ignore
          highlights.push({
            icon: <CheckmarkWithCircleIcon />,
            description: `${
              firstName ? firstName : "You"
            } ignored the message.`,
          });
        } else {
          // Reported
          highlights.push({
            icon: <CheckmarkWithCircleIcon />,
            description: `${
              firstName ? firstName : "You"
            } successfully identified the simulated attack and reported it.`,
          });
        }
      }

      if (highlights.length < 2) {
        // Add additional highlights if there are less than 2 highlights.
        if (!selectedTest.passed) {
          // Fail
          highlights.push({
            icon: <FailIcon />,
            description: `${
              firstName ? firstName : "You"
            } failed to successfully identify this simulated attack and did not report it.`,
          });
        } else {
          // Pass
          highlights.unshift({
            icon: <CautionIcon />,
            description:
              "The attack used a domain associated with a phishing simulation email. ",
          });
        }
      }
    }

    return highlights;
  };

  const getFormattedDate = (date, includeYear = true) => {
    const dateObject = new Date(date);

    const options = { month: "long", day: "numeric" };

    if (includeYear) {
      options.year = "numeric";
    }

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObject,
    );

    // Add ordinal suffix to the day
    const day = dateObject.getDate();
    let daySuffix;
    if (day % 10 === 1 && day !== 11) {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const dayWithSuffix = day + daySuffix;

    return formattedDate.replace(day, dayWithSuffix);
  };

  const canNavigate = (direction) => {
    if (!pastTests || pastTests.length === 0) {
      return false;
    }

    const currentIndex = pastTests.findIndex(
      (activity) => activity === selectedTest,
    );

    if (direction === "left") {
      return currentIndex > 0;
    } else if (direction === "right") {
      return currentIndex < pastTests.length - 1;
    }

    return false;
  };

  const handleClickLeftArrow = () => {
    if (canNavigate("left")) {
      const currentIndex = pastTests.findIndex(
        (activity) => activity === selectedTest,
      );

      const newIndex =
        currentIndex === 0 ? pastTests.length - 1 : currentIndex - 1;

      setSelectedTest(pastTests[newIndex]);
    }
  };

  const handleClickRightArrow = () => {
    if (canNavigate("right")) {
      const currentIndex = pastTests.findIndex(
        (activity) => activity === selectedTest,
      );

      const newIndex =
        currentIndex === pastTests.length - 1 ? 0 : currentIndex + 1;

      setSelectedTest(pastTests[newIndex]);
    }
  };

  useEffect(() => {
    if (
      !selectedTest &&
      pastTests &&
      Array.isArray(pastTests) &&
      pastTests.length > 0
    ) {
      setSelectedTest(pastTests[pastTests.length - 1]);
    }
  }, [selectedTest, pastTests, setSelectedTest]);

  useEffect(() => {
    const loadTestHTML = async () => {
      const testHTML = await onLoadTestHTML(selectedTest.id);

      const { testHTMLCode } = testHTML.result;
      setTestHTML(testHTMLCode);
    };

    if (selectedTest && selectedTest.id) {
      loadTestHTML();
    }
  }, [selectedTest, onLoadTestHTML]);

  const currentDate = new Date();

  const getActivityTestPosition = () => {
    if (!selectedTest || !pastTests) {
      return "";
    }

    const selectedDate = new Date(selectedTest.date).toDateString();
    const activitiesOnSameDay = pastTests.filter(
      (activity) => new Date(activity.date).toDateString() === selectedDate,
    );

    const activityIndex = activitiesOnSameDay.findIndex(
      (activity) => activity.id === selectedTest.id,
    );

    return `Test ${activityIndex + 1} of ${activitiesOnSameDay.length}`;
  };

  return (
    <div id="card-test-calendar">
      <CardWithLoadingState loadingVariable={pastTests} minHeight="431px">
        <CardHeader header="Test Calendar" />
        <div className="flex flex-row">
          <div className="flex flex-row">
            <div>
              <Calendar
                testingActivities={pastTests}
                selectedTest={selectedTest}
                setSelectedTest={setSelectedTest}
              />
            </div>
          </div>
          {selectedTest && (
            <div className="flex flex-row justify-between w-full">
              <div className="ml-2">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="flex justify-between">
                      <div className="flex flex-row items-center">
                        {selectedTest.passed ? (
                          <div className="flex bg-green rounded-[4px] mr-[8px] py-[2px] px-[8px]">
                            <p className="font-semibold uppercase text-gray-dark detailed">
                              Pass
                            </p>
                          </div>
                        ) : (
                          <div className="flex bg-red rounded-[8px] mr-[8px] py-[2px] px-[8px]">
                            <p className="font-semibold uppercase text-gray-dark detailed">
                              Fail
                            </p>
                          </div>
                        )}
                        <div className="flex">
                          <div
                            role="heading"
                            aria-level="4"
                            className="body-emphasized"
                          >
                            {getFormattedDate(selectedTest.date, false)} | Test
                            Details
                          </div>
                        </div>
                      </div>
                      <div className="text-white text-h6.5">
                        {getActivityTestPosition()}
                      </div>
                    </div>
                    {selectedTest.passed ? (
                      <div className="flex flex-row items-center mt-1">
                        <div className="flex flex-col items-center">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background: !selectedTest.hasReported
                                ? "#00ff82"
                                : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color: !selectedTest.hasReported
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              Ignored
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center ml-1">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background: selectedTest.hasReported
                                ? "#00ff82"
                                : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color: selectedTest.hasReported
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              Reported
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center mt-0.75">
                        <div className="flex flex-col items-center">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background: selectedTest.hasReplied
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color: selectedTest.hasReplied
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              Replied
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center ml-1">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background:
                                selectedTest.source &&
                                selectedTest.source.isURLClicked
                                  ? "#ef2c1f"
                                  : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color:
                                  selectedTest.source &&
                                  selectedTest.source.isURLClicked
                                    ? "#ffffff"
                                    : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              Clicked
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center ml-1">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background:
                                selectedTest.source &&
                                selectedTest.source.isQRScanned
                                  ? "#ef2c1f"
                                  : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color:
                                  selectedTest.source &&
                                  selectedTest.source.isQRScanned
                                    ? "#ffffff"
                                    : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              QR Code
                            </p>
                          </div>
                        </div>
                        {/* <div className="flex flex-col items-center ml-1">
                        <div
                          className="rounded-[2px] w-[60px] h-[3px]"
                          style={{
                            background:
                              selectedTest.source &&
                              selectedTest.source.hasDownloaded
                                ? "#ef2c1f"
                                : "rgba(194, 191, 184, 0.35)",
                          }}
                        ></div>
                        <div className="flex mt-0.25">
                          <p
                            className="detailed"
                            style={{
                              color:
                                selectedTest.source &&
                                selectedTest.source.hasDownloaded
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.50)",
                            }}
                          >
                            Download
                          </p>
                        </div>
                      </div> */}
                        <div className="flex flex-col items-center ml-1">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background:
                                selectedTest.imposterPortal &&
                                selectedTest.imposterPortal.isKeyDown
                                  ? "#ef2c1f"
                                  : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color:
                                  selectedTest.imposterPortal &&
                                  selectedTest.imposterPortal.isKeyDown
                                    ? "#ffffff"
                                    : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              Creds
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center ml-1">
                          <div
                            className="rounded-[2px] w-[60px] h-[3px]"
                            style={{
                              background:
                                selectedTest.imposterPortal &&
                                selectedTest.imposterPortal.isMFAEntered
                                  ? "#ef2c1f"
                                  : "rgba(194, 191, 184, 0.35)",
                            }}
                          ></div>
                          <div className="flex mt-0.25">
                            <p
                              className="detailed"
                              style={{
                                color:
                                  selectedTest.imposterPortal &&
                                  selectedTest.imposterPortal.isMFAEntered
                                    ? "#ffffff"
                                    : "rgba(255, 255, 255, 0.50)",
                              }}
                            >
                              MFA
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-2">
                      <p className="body">
                        On {getFormattedDate(selectedTest.date)}, a social
                        engineering test{" "}
                        {selectedTest.impersonator
                          ? `impersonating ${
                              selectedTest.impersonator
                                .charAt(0)
                                .toUpperCase() +
                              selectedTest.impersonator.substring(1)
                            }`
                          : ""}{" "}
                        {new Date(selectedTest.date) < currentDate
                          ? "was"
                          : "will be"}{" "}
                        administered.
                      </p>
                    </div>

                    {selectedTest.domain && selectedTest.domain !== "N/A" && (
                      <div className="my-1.5">
                        <p className="body">
                          Sent from Domain: {selectedTest.domain}
                        </p>
                      </div>
                    )}

                    <div className="mt-2">
                      <div>
                        <p className="description-emphasized">
                          Below are highlights from the test:
                        </p>
                      </div>
                      <div className="mt-0.75">
                        {getHighlights().map((highlight, index) => (
                          <div
                            className={`flex flex-row items-center ${index > 0 && "mt-0.5"}`}
                            key={`highlight-${index}`}
                          >
                            <div className="flex-shrink-0 w-[16px] h-[16px]">
                              {highlight.icon}
                            </div>
                            <div className="flex ml-[6px]">
                              <p className="description">
                                {highlight.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center mt-1.25">
                    <div className="flex">
                      <ButtonOutlinedGray
                        title="View the previous testing activity."
                        isRounded
                        onClick={handleClickLeftArrow}
                      >
                        <BackIcon />
                      </ButtonOutlinedGray>
                    </div>
                    <div className="flex ml-0.5">
                      <ButtonOutlinedGray
                        title="View the next testing activity."
                        isRounded
                        onClick={handleClickRightArrow}
                      >
                        <NextIcon />
                      </ButtonOutlinedGray>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-2">
                <Card padding="p-0">
                  {testHTML && (
                    <iframe
                      className="bg-white rounded-[10px] border-none"
                      title={`${getFormattedDate(selectedTest.date)} test`}
                      srcDoc={testHTML}
                      height="344px"
                    ></iframe>
                  )}
                </Card>
              </div>
            </div>
          )}
        </div>
      </CardWithLoadingState>
    </div>
  );
}

export default CardTestingCalendarForUser;
