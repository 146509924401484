import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getInsightsUserAssignedTraining,
  getInsightsUserChangeFactors,
  getInsightsUserPastTests,
  getInsightsUserProfileData,
  getInsightsUserRiskScore,
  getTestHTML,
  getTestHTMLDemo,
  getUserActivityTicker,
} from "../../../api";

import { Dashboard, TitleWithBadge } from "../../../components";

import { pagesPlatform } from "../../../constants";

import {
  ADMIN_INSIGHTS_USER_HIGH_RISK_ASSIGNED_TRAINING,
  ADMIN_INSIGHTS_USER_HIGH_RISK_CHANGE_FACTORS,
  ADMIN_INSIGHTS_USER_HIGH_RISK_COMPLIANCE_FRAMEWORKS,
  ADMIN_INSIGHTS_USER_HIGH_RISK_PAST_TESTS,
  ADMIN_INSIGHTS_USER_HIGH_RISK_PROFILE_DATA,
  ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE,
  ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE_OVER_TIME,
  ADMIN_INSIGHTS_USER_LOW_RISK_ASSIGNED_TRAINING,
  ADMIN_INSIGHTS_USER_LOW_RISK_CHANGE_FACTORS,
  ADMIN_INSIGHTS_USER_LOW_RISK_COMPLIANCE_FRAMEWORKS,
  ADMIN_INSIGHTS_USER_LOW_RISK_PAST_TESTS,
  ADMIN_INSIGHTS_USER_LOW_RISK_PROFILE_DATA,
  ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE,
  ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE_OVER_TIME,
  DEMO_HIGH_RISK_END_USER,
  DEMO_LOW_RISK_END_USER,
  DEVICE_LIST,
  PROTECTION_STATUSES,
} from "../../../utils";

/**
 * Component for displaying user-level insights.
 * Fetches relevant insights data of a user.
 */
function InsightsUsers({
  isInitialLoading,
  email,
  setNewTitle,
  setBreadcrumbs,
}) {
  // Insights
  const [profileData, setProfileData] = useState(null);
  const [riskScore, setRiskScore] = useState(0);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [changeFactors, setChangeFactors] = useState(null);
  const [userActivities, setUserActivities] = useState(null);
  const [protectionStatuses, setProtectionStatuses] = useState(null);
  const [deviceList, setDeviceList] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [pastTests, setPastTests] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [assignedTraining, setAssignedTraining] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All Time)

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All Time") {
        // If all time, use the master set riskScoreOverAllTime.

        let scoreChange = 0;

        if (
          riskScoreOverAllTime.length &&
          riskScoreOverAllTime[0].riskScore &&
          riskScore
        ) {
          scoreChange = riskScore - riskScoreOverAllTime[0].riskScore;
        }

        setScoreChange(scoreChange);

        setRiskScoreOverTime(riskScoreOverAllTime);
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          },
        );

        let scoreChange = 0;

        if (
          filteredRiskScoreOverTime.length > 0 &&
          filteredRiskScoreOverTime[0].riskScore &&
          riskScore
        ) {
          scoreChange = riskScore - filteredRiskScoreOverTime[0].riskScore;
        }
        setScoreChange(scoreChange);

        setRiskScoreOverTime(filteredRiskScoreOverTime);
      }
    },
    [riskScoreOverAllTime, riskScore],
  );

  const loadTestHTML = async (id) => {
    if (!(profileData && profileData.email)) {
      const errorMessage = "Target email not found";
      const testHTML = {
        result: {
          testHTMLCode: "",
        },
        error: {
          message: errorMessage,
        },
      };

      console.error(errorMessage);

      return testHTML;
    } else if (profileData.email.endsWith("@dune.demo")) {
      // Demo account
      let testHTMLDemo = {};

      if (
        profileData.email === "aaron.chavez@dune.demo" ||
        profileData.email === "michael.waite@dune.demo"
      ) {
        testHTMLDemo = await getTestHTMLDemo(id, profileData.email);
      } else {
        testHTMLDemo = await getTestHTMLDemo(id);
      }

      return testHTMLDemo;
    }

    const testHTML = await getTestHTML(id, profileData.email);

    return testHTML;
  };

  useEffect(() => {
    // const loadDeviceList = async (targetEmail) => {
    //   const getDeviceListResponse = await getDeviceList(targetEmail);

    //   if (Object.keys(getDeviceListResponse.error).length > 0) {
    //     console.error(getDeviceListResponse.error.message);
    //   } else {
    //     const { deviceList } = getDeviceListResponse.result;

    //     setDeviceList(deviceList);
    //   }
    // };

    const loadInsightsUserAssignedTraining = async (targetEmail) => {
      const getInsightsUserAssignedTrainingResponse =
        await getInsightsUserAssignedTraining(targetEmail);

      if (
        Object.keys(getInsightsUserAssignedTrainingResponse.error).length > 0
      ) {
        console.error(getInsightsUserAssignedTrainingResponse.error.message);
      } else {
        const { assignedTraining } =
          getInsightsUserAssignedTrainingResponse.result;

        setAssignedTraining(assignedTraining);
      }
    };

    const loadInsightsUserChangeFactors = async (targetEmail) => {
      const getInsightsUserChangeFactorsResponse =
        await getInsightsUserChangeFactors(targetEmail);

      if (Object.keys(getInsightsUserChangeFactorsResponse.error).length > 0) {
        console.error(getInsightsUserChangeFactorsResponse.error.message);
      } else {
        const { changeFactors } = getInsightsUserChangeFactorsResponse.result;

        setChangeFactors(changeFactors);
      }
    };

    const loadInsightsUserPastTests = async (targetEmail) => {
      const getInsightsUserPastTestsResponse =
        await getInsightsUserPastTests(targetEmail);

      if (Object.keys(getInsightsUserPastTestsResponse.error).length > 0) {
        console.error(getInsightsUserPastTestsResponse.error.message);
      } else {
        const { pastTests } = getInsightsUserPastTestsResponse.result;

        setPastTests(pastTests);
      }
    };

    const loadInsightsUserProfileData = async (targetEmail) => {
      const getInsightsUserProfileDataResponse =
        await getInsightsUserProfileData(targetEmail);

      if (Object.keys(getInsightsUserProfileDataResponse.error).length > 0) {
        console.error(getInsightsUserProfileDataResponse.error.message);
      } else {
        const { profileData } = getInsightsUserProfileDataResponse.result;

        setProfileData(profileData);
      }
    };

    const loadInsightsUserRiskScore = async (targetEmail) => {
      const getInsightsUserRiskScoreResponse =
        await getInsightsUserRiskScore(targetEmail);

      if (Object.keys(getInsightsUserRiskScoreResponse.error).length > 0) {
        console.error(getInsightsUserRiskScoreResponse.error.message);
      } else {
        const { riskScore, riskScoreOverTime, scoreChange } =
          getInsightsUserRiskScoreResponse.result;

        setRiskScore(riskScore);
        setRiskScoreOverTime(riskScoreOverTime);
        setRiskScoreOverAllTime(riskScoreOverTime);
        setScoreChange(scoreChange);
      }
    };

    // const loadProtectionStatuses = async (targetEmail) => {
    //   const getProtectionStatusesUserResponse =
    //     await getProtectionStatusesUser(targetEmail);

    //   if (Object.keys(getProtectionStatusesUserResponse.error).length > 0) {
    //     console.error(getProtectionStatusesUserResponse.error.message);
    //   } else {
    //     const { protectionStatuses } = getProtectionStatusesUserResponse.result;

    //     setProtectionStatuses(protectionStatuses);
    //   }
    // };

    const loadUserActivities = async (targetEmail) => {
      const getUserActivitiesResponse =
        await getUserActivityTicker(targetEmail);

      if (Object.keys(getUserActivitiesResponse.error).length > 0) {
        console.error(getUserActivitiesResponse.error.message);
      } else {
        const { userActivities } = getUserActivitiesResponse.result;

        setUserActivities(userActivities);
      }
    };

    const updateComponent = async () => {
      if (state && state.email) {
        const targetEmail = state.email;

        if (targetEmail === DEMO_HIGH_RISK_END_USER) {
          setRiskScoreOverTime(
            ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE_OVER_TIME,
          );
          setRiskScoreOverAllTime(
            ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE_OVER_TIME,
          );

          setProfileData(ADMIN_INSIGHTS_USER_HIGH_RISK_PROFILE_DATA);
          setRiskScore(ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE);
          setChangeFactors(ADMIN_INSIGHTS_USER_HIGH_RISK_CHANGE_FACTORS);
          setPastTests(ADMIN_INSIGHTS_USER_HIGH_RISK_PAST_TESTS);
          setAssignedTraining(ADMIN_INSIGHTS_USER_HIGH_RISK_ASSIGNED_TRAINING);
          setComplianceFrameworks(
            ADMIN_INSIGHTS_USER_HIGH_RISK_COMPLIANCE_FRAMEWORKS,
          );
        } else if (targetEmail === DEMO_LOW_RISK_END_USER) {
          setRiskScoreOverTime(
            ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE_OVER_TIME,
          );
          setRiskScoreOverAllTime(
            ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE_OVER_TIME,
          );

          setProfileData(ADMIN_INSIGHTS_USER_LOW_RISK_PROFILE_DATA);
          setRiskScore(ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE);
          setChangeFactors(ADMIN_INSIGHTS_USER_LOW_RISK_CHANGE_FACTORS);
          setPastTests(ADMIN_INSIGHTS_USER_LOW_RISK_PAST_TESTS);
          setAssignedTraining(ADMIN_INSIGHTS_USER_LOW_RISK_ASSIGNED_TRAINING);
          setComplianceFrameworks(
            ADMIN_INSIGHTS_USER_LOW_RISK_COMPLIANCE_FRAMEWORKS,
          );
        } else {
          // Special case with sandbox
          if (email === "lilasmith@dune.dynamics") {
            loadUserActivities(targetEmail);
            // loadProtectionStatuses(state.email);
            // loadDeviceList(state.email);

            setProtectionStatuses(PROTECTION_STATUSES);
            setDeviceList(DEVICE_LIST);
          }

          loadInsightsUserAssignedTraining(targetEmail);
          loadInsightsUserPastTests(targetEmail);
          loadInsightsUserProfileData(targetEmail);
          loadInsightsUserRiskScore(targetEmail);
          await loadInsightsUserChangeFactors(targetEmail);
        }
      } else {
        // If there is no email in location, redirect the user to the search employee page.
        navigate("/insights/users/search/");
      }
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email, state, navigate]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  // Update the page title to be the employee's first and last name.
  useEffect(() => {
    if (profileData && (profileData.firstName || profileData.lastName)) {
      const userName = `${profileData.firstName && profileData.firstName}
      ${profileData.firstName && profileData.lastName && " "}
      ${profileData.lastName && profileData.lastName}`;

      setNewTitle(
        <TitleWithBadge
          title={userName}
          avatarContent={profileData.initials}
        />,
      );

      setBreadcrumbs([
        {
          name: "Users",
          path: "/insights/users/search/",
        },
        {
          name: userName,
        },
      ]);
    }
  }, [profileData, setNewTitle, setBreadcrumbs]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.USERS}
      profileData={profileData}
      riskScore={riskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={changeFactors}
      userActivities={userActivities}
      protectionStatuses={protectionStatuses}
      deviceList={deviceList}
      pastTests={pastTests}
      selectedTest={selectedTest}
      setSelectedTest={setSelectedTest}
      learningModulesActivity={assignedTraining}
      complianceFrameworks={complianceFrameworks}
      onChangeSelectedDays={handleChangeSelectedDays}
      onLoadTestHTML={loadTestHTML}
      email={email}
    />
  );
}

export default InsightsUsers;
