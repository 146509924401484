import React, { useEffect, useState } from "react";
import {
  ButtonContainedGreen,
  ButtonOutlinedGray,
  CustomLink,
  FadeInSection,
  GreenItalicText,
} from "../../../components";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { constants } from "../../../constants";
import isEmail from "validator/lib/isEmail";
import { getInfographics } from "../../../api";
import { postContactMessage } from "../../../constants/apis";
import {
  SearchIcon,
  LoadingSpinner,
  ResourcesIllustration,
} from "../../../utils/icons";
import { Translate } from "react-auto-translate";
import { useNavigate } from "react-router-dom";
import { formatDateFive } from "../../../utils/helper-functions/formatDate";
import { ResourceCard } from "./utils/";
import { ViewAll } from "./utils";
import { extractTitleFromKey } from "../../../utils/helper-functions/infographic";

const Resources = () => {
  const [fetchedBootcamp, setFetchedBootcamp] = useState([]);
  const [fetchedWebinar, setFetchedWebinar] = useState([]);
  const [fetchedWhitepaper, setFetchedWhitepaper] = useState([]);
  const [mostRecentResources, setMostRecentResources] = useState([]);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    title: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const [infographics, setInfographics] = useState([]);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const navigate = useNavigate();

  const handleClickSubmitSubscribe = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setMessageError("");
    setMessageSuccess("");
    setIsErrorEmail(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError("Please enter a valid email.");
    } else {
      const requestBody = {
        first_name: "",
        last_name: "",
        email,
        subject: "Subscription request",
        message: `${email} signed up for a monthly newsletter highlighting the newest social engineering hacks to watch out for and how to best prepare for them.`,
      };

      const contactMessage = await postContactMessage(requestBody);

      if (Object.keys(contactMessage.error).length > 0) {
        setMessageError(constants.ERROR_DEFAULT);
      } else {
        setMessageSuccess("Your message has been sent.");
      }
    }

    setIsLoading(false);
  };

  const handleCloseSnackbarSuccess = () => {
    setMessageSuccess("");
  };

  const handleCloseSnackbarError = () => {
    setMessageError("");
  };

  const redirectToInfographic = (svgUrl, pdfUrl, title) => {
    if (svgUrl) {
      navigate(
        `/resources/infographics/details?url=${encodeURIComponent(
          svgUrl,
        )}&pdf=${encodeURIComponent(pdfUrl)}&title=${encodeURIComponent(title)}`,
      );
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
      const client = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      });

      const fetchResources = async () => {
        try {
          const bootcampResponse = await client.getEntries({
            content_type: "resourcesBootcamp",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const teamResponse = await client.getEntries({
            content_type: "resourcesTeam",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const webinarResponse = await client.getEntries({
            content_type: "webinarRecapBlog",
            limit: 3,
            order: "-fields.date,fields.title",
          });
          const whitepaperResponse = await client.getEntries({
            content_type: "whitepaper",
            limit: 3,
            order: "-fields.date,fields.title",
          });

          // Map and extract relevant data for each type of resource
          const bootcampResources = bootcampResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: `/resources/bootcamp/${item.fields.resourceID}`,
          }));

          const teamResources = teamResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: item.fields.resourceURL,
          }));

          const webinarResources = webinarResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            href: `/resources/webinar-recap-blog/${item.fields.resourceID}`,
          }));

          const whitepaperResources = whitepaperResponse.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            date: item.fields.date,
            thumbnailURL: item.fields.thumbnail?.fields?.file?.url
              ? item.fields.thumbnail.fields.file.url.startsWith("//")
                ? `https:${item.fields.thumbnail.fields.file.url}`
                : item.fields.thumbnail.fields.file.url
              : "",
            coverImage: item.fields.coverImage?.fields?.file?.url
              ? item.fields.coverImage.fields.file.url.startsWith("//")
                ? `https:${item.fields.coverImage.fields.file.url}`
                : item.fields.coverImage.fields.file.url
              : "",
            href: `/resources/whitepaper/${item.fields.referenceId}`,
          }));

          setFetchedBootcamp(bootcampResources);
          setFetchedWebinar(webinarResources);
          setFetchedWhitepaper(whitepaperResources);

          // Combine all fetched resources into one array
          const allResources = [
            ...bootcampResources,
            ...teamResources,
            ...webinarResources,
            ...whitepaperResources,
          ];

          // Sort by date (descending)
          const sortedResources = allResources.sort(
            (a, b) => new Date(b.date) - new Date(a.date),
          );

          // Set the top 3 most recent resources
          setMostRecentResources(sortedResources.slice(0, 3));
        } catch (error) {
          console.error(error);
        }
      };

      fetchResources();
    }
  }, []);

  useEffect(() => {
    const getInfographicsData = async () => {
      const infographicData = await getInfographics();
      if (infographicData) {
        setInfographics(infographicData.result.files.slice(0, 3));
      }
    };

    getInfographicsData();
  }, []);

  const filteredMostRecent = mostRecentResources.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredWebinar = fetchedWebinar.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredBootcamp = fetchedBootcamp.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const filteredWhitepaper = fetchedWhitepaper.filter((resource) =>
    resource.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleModal = () => {
    const emailPaywallCookie = getCookie("emailPayWall");
    if (emailPaywallCookie !== "true") {
      setIsOpen(true);
    } else {
      navigate(`/resources/whitepaper/${currentId}`);
    }
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    setCookie("emailPayWall", "true", 999);

    if (currentId !== null && isFormSubmitted) {
      navigate(`/resources/whitepaper/${currentId}`);
    } else {
      console.log("Error");
    }
  };

  return (
    <div className="text-white max-w-78.75 mx-auto px-4">
      <FadeInSection>
        <div className="my-5 mb-2.5 mx-auto text-center">
          <div
            role="heading"
            aria-level="4"
            className="text-green text-h4 uppercase flex justify-center text-2xl tracking-custom-1.6"
          >
            Resource Hub
          </div>
          <div
            role="heading"
            aria-level="3"
            className="mt-2.5 text-h3 font-medium md:w-8/12 w-full text-center mx-auto"
          >
            <Translate>Everything you </Translate>{" "}
            <GreenItalicText text=" need to know " />{" "}
            <Translate>
              to combat social engineering at your enterprise
            </Translate>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="relative mx-auto mb-2 md:w-1/2">
          <span className="absolute inset-y-0 flex items-center pl-3 -left-1">
            <SearchIcon />
          </span>
          <input
            type="text"
            className="w-full pl-5 bg-transparent border border-gray border-opacity-15 focus:border-green focus:outline-none rounded-full p-1.25"
            placeholder="Search for security tips, cyber news, and more"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </FadeInSection>

      <FadeInSection>
        <div role="heading" aria-level="2" className="mt-10 mb-5 text-h2">
          Most Recent
        </div>
        {filteredMostRecent.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 items-center gap-4">
            {filteredMostRecent.map((resource) => (
              <ResourceCard
                badge={{
                  title: resource.href.includes("whitepaper")
                    ? "Whitepaper"
                    : resource.href.includes("bootcamp")
                      ? "Bootcamp"
                      : resource.href.includes("team")
                        ? "From the team"
                        : resource.href.includes("webinar-recap-blog")
                          ? "Event"
                          : resource.href.includes("infographics")
                            ? "Infographic"
                            : "",
                  color: resource.href.includes("whitepaper")
                    ? "#7B3F61"
                    : resource.href.includes("bootcamp")
                      ? "#31708E"
                      : resource.href.includes("team")
                        ? "#4A7C59"
                        : resource.href.includes("webinar-recap-blog")
                          ? "#2D9C8F"
                          : resource.href.includes("infographics")
                            ? "#A67C3B"
                            : "",
                }}
                resourceId={resource.id}
                resourceHref={resource.href}
                imgSrc={resource.thumbnailURL || resource.coverImage}
                title={resource.title}
                date={formatDateFive(resource.date)}
              />
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
      </FadeInSection>

      <FadeInSection>
        <div role="heading" aria-level="2" className="mt-10 mb-5 text-h2">
          Dune Security Bootcamp
        </div>
        {filteredBootcamp.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredBootcamp.map((resource) => (
              <ResourceCard
                badge={{ title: "Bootcamp", color: "#31708E" }}
                resourceId={resource.id}
                resourceHref={resource.href}
                imgSrc={resource.thumbnailURL}
                title={resource.title}
                date={formatDateFive(resource.date)}
              />
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <ViewAll href="/resources/bootcamp/" />
      </FadeInSection>

      <FadeInSection>
        <div role="heading" aria-level="2" className="mt-10 mb-5 text-h2">
          Whitepapers
        </div>
        {filteredWhitepaper.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredWhitepaper.map((resource) => (
              <div
                key={resource.id}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer group"
                onClick={() => {
                  if (getCookie("emailPayWall") !== "true") {
                    navigate(
                      `/resources/whitepaper/${resource.href.split("/")[3]}`,
                    );
                  } else {
                    const id = resource.href.split("/")[3];
                    setCurrentId(id);
                    handleModal(id);
                  }
                }}
              >
                <ResourceCard
                  badge={{ title: "Whitepaper", color: "#7B3F61" }}
                  imgSrc={resource.coverImage}
                  title={resource.title}
                  date={formatDateFive(resource.date)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <ViewAll href="/resources/whitepaper" />

        {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center text-white bg-black bg-opacity-70">
            <div className="bg-black py-2 px-2 md:px-6.5 rounded-lg max-w-40.625 w-full">
              <div className="flex items-center justify-between mb-1">
                <div role="heading" aria-level="3" className="text-h3">
                  Enter Contact Info
                </div>
                <button
                  aria-label="close"
                  onClick={() => setIsOpen(false)}
                  className="font-bold text-white text-h3"
                >
                  &times;
                </button>
              </div>
              <form>
                <div className="flex flex-col items-center justify-center md:flex-row">
                  <div className="p-1">
                    <label className="block mb-0.25">First Name</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.firstName}
                      onChange={(e) =>
                        setForm({ ...form, firstName: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="p-1">
                    <label className="block mb-0.25">Last Name</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.lastName}
                      onChange={(e) =>
                        setForm({ ...form, lastName: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center md:flex-row">
                  <div className="p-1 mb-0.25">
                    <label className="block">Email</label>
                    <input
                      type="email"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="p-1">
                    <label className="block mb-0.25">Company</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.company}
                      onChange={(e) =>
                        setForm({ ...form, company: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center md:flex-row">
                  <div className="p-1">
                    <label className="block">Title</label>
                    <input
                      type="text"
                      className="bg-transparent text-white border rounded-small px-1 py-0.25 border-gray border-opacity-35"
                      value={form.title}
                      onChange={(e) =>
                        setForm({ ...form, title: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-center mt-2 space-x-1">
                  <ButtonOutlinedGray
                    title="Cancel"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </ButtonOutlinedGray>
                  <ButtonContainedGreen
                    title="Submit"
                    onClick={handleFormSubmission}
                  >
                    Submit
                  </ButtonContainedGreen>
                </div>
              </form>
            </div>
          </div>
        )}
      </FadeInSection>

      <FadeInSection>
        <div role="heading" aria-level="2" className="mt-10 mb-5 text-h2">
          Infographics
        </div>
        {infographics.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {infographics.map((infographic) => (
              <div
                key={infographic.key}
                onClick={() =>
                  redirectToInfographic(
                    infographic.svg_url,
                    infographic.pdf_url,
                    extractTitleFromKey(infographic.key),
                  )
                }
              >
                <ResourceCard
                  badge={{ title: "Infographic", color: "#A67C3B" }}
                  imgSrc={infographic.svg_url}
                  title={extractTitleFromKey(infographic.key)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <ViewAll href="/resources/infographics/" />
      </FadeInSection>

      <FadeInSection>
        <div role="heading" aria-level="2" className="mt-10 mb-5 text-h2">
          Events
        </div>
        {filteredWebinar.length > 0 ? (
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {filteredWebinar.map((resource) => (
              <ResourceCard
                badge={{ title: "Event", color: "#2D9C8F" }}
                resourceId={resource.id}
                resourceHref={resource.href}
                imgSrc={resource.thumbnailURL}
                title={resource.title}
                date={formatDateFive(resource.date)}
              />
            ))}
          </div>
        ) : (
          <div>No resources found</div>
        )}
        <ViewAll href="/resources/webinar-recap-blog/" />
      </FadeInSection>

      <FadeInSection>
        <Box
          className="resources-box-subscribe"
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 28px"
        >
          <Box maxWidth="1152px" width="100%" padding="40px 0 144px">
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              alignItems="flex-end"
            >
              <Box
                maxWidth={{
                  xs: "100%",
                  md: "800px",
                }}
                width="100%"
              >
                <Typography
                  role="heading"
                  aria-level="2"
                  component="h2"
                  className="dune-text-header-main"
                >
                  <Translate>Get our</Translate>{" "}
                  <GreenItalicText text="best content " />
                  <Translate> delivered in your inbox</Translate>
                </Typography>
                <Typography variant="body" component="p" marginTop="40px">
                  <Translate>
                    Sign up for a monthly newsletter highlighting the newest
                    social engineering hacks to watch out for and how to best
                    prepare for them.
                  </Translate>
                </Typography>
                <Box
                  component="form"
                  noValidate
                  marginTop="40px"
                  onSubmit={handleClickSubmitSubscribe}
                >
                  <Stack direction="row" alignItems="end">
                    <Box maxWidth="520px" width="100%">
                      <TextField
                        variant="filled"
                        className="resources-text-field-email"
                        name="email"
                        placeholder={"Email"}
                        type="text"
                        error={isErrorEmail}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Box>
                    <Box marginLeft="20px">
                      <ButtonContainedGreen
                        disabled={isPrivacyPolicyAccepted ? false : true}
                        title="Subscribe"
                      >
                        Subscribe
                      </ButtonContainedGreen>
                    </Box>
                  </Stack>
                </Box>
                <Box maxWidth="720px" width="100%" marginTop="20px">
                  <p className="resources-text-privacy text-h6">
                    <Translate>
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          className="mr-0.5 accent-green"
                          checked={isPrivacyPolicyAccepted}
                          onChange={() =>
                            setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)
                          }
                        />
                        By subscribing to our newsletter, I agree to receive
                        marketing communications from Dune Security and
                        acknowledge that I have read and accept the{" "}
                        <CustomLink href="/privacy-policy/">
                          Privacy Policy
                        </CustomLink>
                      </div>
                    </Translate>{" "}
                    <Link
                      className="resources-link-privacy-policy"
                      to="/privacy-policy/"
                      title="Go to the Privacy Policy page."
                    >
                      <Translate>privacy policy</Translate>
                    </Link>
                    .
                  </p>
                </Box>
              </Box>
              <Box
                maxWidth="400px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: "80px",
                }}
                marginTop={{
                  xs: "40px",
                  md: 0,
                }}
              >
                <ResourcesIllustration />
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>

      <Snackbar
        open={messageSuccess !== ""}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert className="dune-alert-success" severity="success">
          {messageSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={messageError !== ""} onClose={handleCloseSnackbarError}>
        <Alert className="dune-alert-error" severity="error">
          {messageError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Resources;
