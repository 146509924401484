import React from "react";

import { RiskScoreLineChart } from "./utils";

import { CardWithLoadingState } from "../../../../../common";

function CardRiskScoreOverTime({
  riskScoreOverTime,
  color,
  pastTests,
  setSelectedTest,
}) {
  return (
    <CardWithLoadingState
      loadingVariable={riskScoreOverTime}
      minHeight="326.17px"
    >
      <div>
        <div className="text-center card-header">Risk Score Over Time</div>
      </div>
      <div className="mt-2">
        <RiskScoreLineChart
          riskScoreOverTime={riskScoreOverTime}
          color={color}
          pastTests={pastTests}
          setSelectedTest={setSelectedTest}
        />
      </div>
    </CardWithLoadingState>
  );
}

export default CardRiskScoreOverTime;
