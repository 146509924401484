const formatOktaLoginAnomalies = (apiResponseList) => {
  if (!Array.isArray(apiResponseList)) {
    console.error("Expected an array but received:", apiResponseList);
    return { anomalies: [] };
  }

  return apiResponseList.map((apiResponse) => ({
    email: apiResponse.email || null,
    riskScore: apiResponse.riskScore ? Math.floor(apiResponse.riskScore) : 0,
    riskLevel: apiResponse.riskLevel || "Unknown",
    anomalyReasoning: apiResponse.anomaly_reasoning || "Not Provided",
    duration: apiResponse.duration || 0,
    failures: apiResponse.failures || {},
    failureCount: apiResponse.failure_count || 0,
    ipAddresses: Array.isArray(apiResponse.ip_address)
      ? apiResponse.ip_address
      : [],
    locations: Array.isArray(apiResponse.location) ? apiResponse.location : [],
    sequence: Array.isArray(apiResponse.sequence) ? apiResponse.sequence : [],
    firstName: apiResponse.first_name || "",
    lastName: apiResponse.last_name || "",
    role: apiResponse.role || "Unknown",
  }));
};

export default formatOktaLoginAnomalies;
