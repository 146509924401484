import React from "react";

const HealthEdgeLogo = () => {
  return (
    <img
      src="https://d3oo9a669kwmx7.cloudfront.net/logos/HealthEdgeLogo.png"
      alt="HealthEdge Logo"
      className="max-w-12 w-full"
    />
  );
};

export default HealthEdgeLogo;
