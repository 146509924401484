import {
  formatRiskScore,
  formatRiskScoreOverTime,
  formatScoreChange,
  getResponseGet,
} from "../utils";

const getInsightsUserRiskScore = async (email) => {
  let getInsightsUserRiskScoreResponse = {
    result: {
      riskScore: 0,
      riskScoreOverTime: [],
      scoreChange: 0,
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/other-user-risk-data/risk-score/?target_email=${email}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      let riskScore = 0;
      let scoreChange = 0;

      if (data.risk_score_data && data.risk_score_data.current_risk_score) {
        riskScore = formatRiskScore(data.risk_score_data.current_risk_score);

        if (data.time_series) {
          scoreChange = formatScoreChange(
            data.risk_score_data.current_risk_score,
            data.time_series,
          );
        }
      }

      getInsightsUserRiskScoreResponse.result = {
        riskScore,
        riskScoreOverTime: formatRiskScoreOverTime(data.time_series),
        scoreChange,
      };
    }
  } catch (error) {
    getInsightsUserRiskScoreResponse.error = error;
  }

  return getInsightsUserRiskScoreResponse;
};

export default getInsightsUserRiskScore;
