import React, { useEffect, useState } from "react";
import { DataTable, DonutChart, Legend, Tabs } from "..";

const defaultHeaders = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "left",
  },
];

const DonutChartWithTable = ({
  donutChartData = {},
  tableData = [],
  selectedTab,
  setSelectedTab,
  filterField = "",
  title,
  minHeight,
  defaultOrderBy = "name",
  noDataMessage = "No users found",
  headers = defaultHeaders,
}) => {
  const [filteredTableData, setFilteredTableData] = useState([]);

  const handleClickCell = (name) => {
    setSelectedTab({ name });
  };

  useEffect(() => {
    if (
      selectedTab &&
      selectedTab.name &&
      Array.isArray(tableData) &&
      filterField
    ) {
      const filteredTableData = tableData.filter(
        (user) =>
          user[filterField]?.toLowerCase() === selectedTab.name.toLowerCase(),
      );
      setFilteredTableData(filteredTableData);
    } else {
      setFilteredTableData(Array.isArray(tableData) ? tableData : []);
    }
  }, [selectedTab, tableData, filterField]);

  useEffect(() => {
    if (
      donutChartData &&
      Object.keys(donutChartData).length > 0 &&
      !(selectedTab && selectedTab.name)
    ) {
      setSelectedTab({ name: Object.keys(donutChartData)[0] });
    }
  }, [donutChartData, selectedTab, setSelectedTab]);

  return (
    <div className="flex flex-col gap-0 md:flex-row md:gap-2">
      <div className="flex flex-col w-full md:justify-start md:mt-2 md:max-w-[200px] md:min-w-[200px]">
        <div className="md:max-w-[200px]">
          <DonutChart
            data={donutChartData}
            title={title}
            onClickCell={handleClickCell}
            minHeight={minHeight}
          />
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col mt-1">
            <Legend
              data={Object.fromEntries(
                Object.entries(donutChartData).map(([key, value]) => [
                  key.replace(/_/g, " "),
                  value,
                ]),
              )}
            />
          </div>
        </div>
      </div>

      <div className="w-full mt-1 md:mt-0 overflow-hidden">
        <div>
          {donutChartData && Object.keys(donutChartData).length > 0 && (
            <Tabs
              data={Object.keys(donutChartData).map((key) => ({
                name: key.replace(/_/g, " "),
              }))}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
        </div>

        {Array.isArray(filteredTableData) && (
          <div className="w-full mt-1">
            <DataTable
              data={filteredTableData}
              headers={headers}
              defaultOrderBy={defaultOrderBy}
              rowsPerPage={5}
              disableFilters
              noDataMessage={noDataMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DonutChartWithTable;
