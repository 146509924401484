import React from "react";

import { CardWithLoadingState, List } from "../../../..";

function CardChangeFactors({ changeFactors, isEndUser }) {
  return (
    <CardWithLoadingState loadingVariable={changeFactors}>
      <div>
        <div className="text-center card-header">
          {isEndUser ? "Change Factors" : "High Risk Areas"}
        </div>
      </div>
      <div className="mt-1.25">
        {changeFactors && (
          <>
            {Array.isArray(changeFactors) && changeFactors.length > 0 ? (
              <List data={changeFactors} keyName="name" />
            ) : (
              <p className="description">No factors to improve</p>
            )}
          </>
        )}
      </div>
    </CardWithLoadingState>
  );
}

export default CardChangeFactors;
