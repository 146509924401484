import {
  CrowdStrikeBirdIcon,
  MicrosoftPurviewColorIcon,
  OktaIcon,
} from "../../icons";

export const anomalousActivityIcons = [
  {
    icon: <MicrosoftPurviewColorIcon />,
  },
];

export const loginActivityIcons = [
  {
    icon: <OktaIcon />,
  },
  {
    icon: <CrowdStrikeBirdIcon />,
  },
];

export const anomalousActivityDataHeaders = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "documentName",
    label: "Document Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "fileType",
    label: "File Type",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "activityTime",
    label: "Activity Time",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];

export const loginActivityDataHeaders = [
  {
    id: "email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "272px",
  },
  {
    id: "anomalyReasoning",
    label: "Anomaly Cause",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "464px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];
