import React, { useEffect } from "react";

const SankeyChart = ({
  selectedInteraction,
  totalInteractionsClicked = 0,
  totalInteractionsQRCodesScanned = 0,
  totalInteractionsHybridTests = 0,
  totalInteractionsSMSLinkClicked = 0,
  totalInteractionsKeyDown = 0,
  totalInteractionsDataEntered = 0,
  totalInteractionsMFAEntered = 0,
  totalInteractionsDownloadAttachments = 0,
  totalInteractionsResponded = 0,
  totalInteractionsNoEngagement = 0,
  totalInteractionsReported = 0,
  onClickInteraction,
}) => {
  const handleClickInteraction = (interactionType, total) => {
    onClickInteraction(interactionType, total);
  };

  const renderText = (x, y, text, interactions, interactionType) => {
    return (
      <text
        tabIndex="0"
        className={`${
          interactionType !== "no_action" ? "cursor-pointer" : "cursor-auto"
        } focus:outline-none font-medium text-[10px] uppercase`}
        x={x}
        y={y}
        fill="#000000"
        role="button"
        onClick={
          interactionType !== "no_action"
            ? () =>
                handleClickInteraction(
                  { id: interactionType, name: text },
                  interactions,
                )
            : null
        }
      >
        <tspan className="font-bold text-[12px]">
          {interactions.toLocaleString("en-US")}
        </tspan>{" "}
        {text}
      </text>
    );
  };

  useEffect(() => {
    handleClickInteraction(
      { id: "phished", name: "Clicked Link" },
      totalInteractionsClicked,
    ); // Default selected interaction

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalInteractionsClicked]);

  return (
    <svg
      role="presentation"
      width="100%"
      height="100%"
      viewBox="0 0 952 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Beginning node */}
      <rect y="67" width="6" height="146" rx="3" fill="#DCDADA" />
      {/* Clicked Link */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30469 74.7362V83.1162C76.5438 83.1162 76.5438 19.3399 143.783 19.3399V8.86499C76.5438 8.86499 76.5438 74.7362 9.30469 74.7362Z"
        fill="url(#paint10_linear_4700_81393)"
      />
      <rect
        x="148"
        y="3.32495"
        width="6.20668"
        height="22.1645"
        rx="3.81103"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="158.42368"
        width="143.681"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction(
            { id: "phished", name: "Clicked Link" },
            totalInteractionsClicked,
          )
        }
      />
      {renderText(
        "164",
        "17",
        "Clicked Link",
        totalInteractionsClicked,
        "phished",
      )}
      {/* Scanned QR Code */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30469 95.2447V103.625C76.5438 103.625 76.5438 52.5001 143.783 52.5001V43.0001C76.5438 43.0001 76.5438 95.2447 9.30469 95.2447Z"
        fill="url(#paint9_linear_4700_81393)"
      />
      <rect
        x="148"
        y="38.7014"
        width="6.20668"
        height="22.1645"
        rx="3.10334"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="158.42368"
        y="35.377"
        width="146"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction(
            { id: "scanned_qr", name: "Scanned QR Code" },
            totalInteractionsQRCodesScanned,
          )
        }
      />
      {renderText(
        "164",
        "53",
        "Scanned QR Code",
        totalInteractionsQRCodesScanned,
        "scanned_qr",
      )}
      {/* Hybrid Tests */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 116.178V121.678C76.2456 121.678 77.7544 88 145 88V79.5C77.7544 79.5 76.2456 116.178 9 116.178Z"
        fill="url(#paint13_linear_4700_81393)"
      />
      <g clip-path="url(#clip3_4700_81393)">
        <rect
          x="148"
          y="76.1204"
          width="6.20668"
          height="22.1645"
          rx="3.10334"
          fill="#949494"
        />
        <rect
          className="cursor-pointer"
          x="158.42368"
          y="72"
          width="143.859"
          height="26"
          rx="8"
          fill="#949494"
          onClick={() =>
            handleClickInteraction(
              { id: "hybrid", name: "Hybrid Tests" },
              totalInteractionsHybridTests,
            )
          }
        />
      </g>
      {renderText(
        "164",
        "89",
        "Hybrid Tests",
        totalInteractionsHybridTests,
        "hybrid",
      )}
      {/* SMS Link Clicked */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.60742 131V136.5C76.853 136.5 76.853 123.243 144.099 123.243V114.822C76.853 114.822 76.853 131 9.60742 131Z"
        fill="url(#paint4_linear_4700_81393)"
      />
      <g clip-path="url(#clip4_4700_81393)">
        <rect
          x="148"
          y="111.12"
          width="6.20668"
          height="22.1645"
          rx="3.10334"
          fill="#949494"
        />
        <rect
          className="cursor-pointer"
          x="158.42368"
          y="107"
          width="143.859"
          height="26"
          rx="8"
          fill="#949494"
          onClick={() =>
            handleClickInteraction(
              { id: "quished", name: "SMS Link Clicked" },
              totalInteractionsSMSLinkClicked,
            )
          }
        />
      </g>
      {renderText(
        "164",
        "125",
        "SMS Link Clicked",
        totalInteractionsSMSLinkClicked,
        "quished",
      )}
      {/* Entered Credentials */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M304 9V15C340.5 15 355.5 73 392 73V64C355.5 64 340.5 9 304 9Z"
        fill="url(#paint5_linear_4700_81393)"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M304 45V51C340.5 51 355.5 73 392 73V64C355.5 64 340.5 45 304 45Z"
        fill="url(#paint6_linear_4700_81393)"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M304 82V88C340.5 88 355.5 73 392 73V64C355.5 64 340.5 82 304 82Z"
        fill="url(#paint7_linear_4700_81393)"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M305 117V123C341.5 123 355.5 73 392 73V64C355.5 64 341.5 117 305 117Z"
        fill="url(#paint8_linear_4700_81393)"
      />
      <rect
        x="394"
        y="57.3248"
        width="6.20668"
        height="22.1645"
        rx="3.10334"
        fill="#FEE442"
      />
      <rect
        className="cursor-pointer"
        x="403.311"
        y="54"
        width="160"
        height="26"
        rx="8"
        fill="#FEE442"
        onClick={() =>
          handleClickInteraction(
            { id: "key_down", name: "Entered Credentials" },
            totalInteractionsKeyDown,
          )
        }
      />
      {renderText(
        "410",
        "71",
        "Entered Credentials",
        totalInteractionsKeyDown,
        "key_down",
      )}
      {/* Submitted Credentials */}
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M610.654 75.3013L610.654 61.5726C577.905 61.5726 577.905 61.5726 545.155 61.5726L545.155 75.3013C577.905 75.3013 577.905 75.3013 610.654 75.3013Z"
        fill="url(#paint11_linear_4700_81393)"
      />
      <rect
        x="613.309"
        y="57.3248"
        width="6.20668"
        height="22.1645"
        rx="3.10334"
        fill="#F59A31"
      />
      <rect
        className="cursor-pointer"
        x="622.617"
        y="54"
        width="165"
        height="26"
        rx="8"
        fill="#F59A31"
        onClick={() =>
          handleClickInteraction(
            { id: "data_entered", name: "Submitted Credentials" },
            totalInteractionsDataEntered,
          )
        }
      />
      {renderText(
        "629",
        "71",
        "Submitted Credentials",
        totalInteractionsDataEntered,
        "data_entered",
      )}
      {/* Entered MFA */}
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M834.223 75.3013L834.223 61.5726C801.473 61.5726 801.473 61.5726 768.724 61.5726L768.724 75.3013C801.473 75.3013 801.473 75.3013 834.223 75.3013Z"
        fill="url(#paint12_linear_4700_81393)"
      />
      <rect
        x="836.744"
        y="57.3248"
        width="6.20668"
        height="22.1645"
        rx="3.10334"
        fill="#EF3C1F"
      />
      <rect
        className="cursor-pointer"
        x="846.053"
        y="54"
        width="105"
        height="26"
        rx="8"
        fill="#EF3C1F"
        onClick={() =>
          handleClickInteraction(
            { id: "mfa_entered", name: "Entered MFA" },
            totalInteractionsMFAEntered,
          )
        }
      />
      {renderText(
        "853",
        "71",
        "Entered MFA",
        totalInteractionsMFAEntered,
        "mfa_entered",
      )}
      {/* Downloaded Attachment */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.60742 148.5V155.5C76.853 155.5 76.853 177.225 144.099 177.225V167.115C76.853 167.115 76.853 148.5 9.60742 148.5Z"
        fill="url(#paint3_linear_4700_81393)"
      />
      <rect
        x="148"
        y="161.745"
        width="6.20668"
        height="22.1645"
        rx="3.10334"
        fill="#949494"
      />
      <rect
        className="cursor-pointer"
        x="158.42368"
        y="158.42"
        width="191"
        height="26"
        rx="8"
        fill="#949494"
        onClick={() =>
          handleClickInteraction(
            { id: "download_attachment", name: "Download Attachments" },
            totalInteractionsDownloadAttachments,
          )
        }
      />
      {renderText(
        "164",
        "176",
        "Download Attachments",
        totalInteractionsDownloadAttachments,
        "download_attachment",
      )}
      {/* Responded to Phishing */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30469 161V168.5C76.5438 168.5 77.7601 211.5 144.999 211.5V202.5C77.7601 202.5 76.5438 161 9.30469 161Z"
        fill="url(#paint1_linear_4700_81393)"
      />
      <g clip-path="url(#clip2_4700_81393)">
        <rect
          x="148"
          y="198.12"
          width="6.20668"
          height="22.1645"
          rx="3.10334"
          fill="#949494"
        />
        <rect
          className="cursor-pointer"
          x="158.42368"
          y="194"
          width="174.497"
          height="26"
          rx="8"
          fill="#949494"
          onClick={() =>
            handleClickInteraction(
              { id: "smtp_reply", name: "Responded To Phishing" },
              totalInteractionsResponded,
            )
          }
        />
      </g>
      {renderText(
        "164",
        "211",
        "Responded to Phishing",
        totalInteractionsResponded,
        "smtp_reply",
      )}
      {/* Ignored Phishing */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 179V187C76.5 187 76.5 269 144 269V259.5C76.5 259.5 76.5 179 9 179Z"
        fill="url(#paint2_linear_4700_81393)"
      />
      <g clip-path="url(#clip0_4700_81393)">
        <rect
          x="148"
          y="255.12"
          width="6.20668"
          height="22.1645"
          rx="3.10334"
          fill="#EBE9E0"
        />
        <rect
          x="158.42368"
          y="251"
          width="139"
          height="26"
          rx="8"
          fill="white"
        />
      </g>
      {renderText(
        "164",
        "268",
        "Ignored Phishing",
        totalInteractionsNoEngagement,
        "no_action",
      )}
      {/* Reported to Watchtower */}
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 195.5V204.5C76.5 204.5 76.5 304.5 144 304.5V295C76.5 295 76.5 195.5 9 195.5Z"
        fill="url(#paint0_linear_4700_81393)"
      />
      <g clip-path="url(#clip1_4700_81393)">
        <rect
          x="148"
          y="291.12"
          width="6.20668"
          height="22.1645"
          rx="3.10334"
          fill="#00FF82"
        />
        <rect
          className="cursor-pointer"
          x="158.42368"
          y="287"
          width="185"
          height="26"
          rx="8"
          fill="#00FF82"
          onClick={() =>
            handleClickInteraction(
              { id: "watchtower_reported", name: "Reported to Watchtower" },
              totalInteractionsResponded,
            )
          }
        />
      </g>
      {renderText(
        "164",
        "304",
        "Reported to Watchtower",
        totalInteractionsReported,
        "watchtower_reported",
      )}
      <defs>
        <linearGradient
          id="paint0_linear_4700_81393"
          x1="79.4348"
          y1="154.413"
          x2="79.4348"
          y2="263.806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFECE3" />
          <stop offset="1" stop-color="#00FF82" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4700_81393"
          x1="79.4672"
          y1="128.591"
          x2="79.4672"
          y2="212.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFECE3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4700_81393"
          x1="79.4348"
          y1="132.954"
          x2="79.4348"
          y2="255.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFECE3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4700_81393"
          x1="86.7633"
          y1="129.624"
          x2="67.3122"
          y2="175.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4700_81393"
          x1="91.9046"
          y1="87.1777"
          x2="69.1784"
          y2="160.467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4700_81393"
          x1="363.67"
          y1="34.457"
          x2="325.486"
          y2="96.9992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4700_81393"
          x1="363.67"
          y1="70.457"
          x2="325.486"
          y2="132.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4700_81393"
          x1="363.67"
          y1="107.457"
          x2="325.486"
          y2="169.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4700_81393"
          x1="364.67"
          y1="142.457"
          x2="326.486"
          y2="204.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4700_81393"
          x1="79.4672"
          y1="61.2428"
          x2="79.4672"
          y2="137.156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4700_81393"
          x1="21.9595"
          y1="85.6227"
          x2="149.4"
          y2="40.7346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4700_81393"
          x1="611.059"
          y1="68.4369"
          x2="545.56"
          y2="68.4369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F59A31" />
          <stop offset="1" stop-color="#FEE442" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4700_81393"
          x1="834.627"
          y1="68.4369"
          x2="769.128"
          y2="68.4369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EF3C1F" />
          <stop offset="1" stop-color="#F59A31" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4700_81393"
          x1="91.2972"
          y1="72.356"
          x2="68.571"
          y2="145.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9C9C9C" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_4700_81393">
          <rect
            width="174"
            height="32.4496"
            fill="white"
            transform="translate(147 251)"
          />
        </clipPath>
        <clipPath id="clip1_4700_81393">
          <rect
            width="198"
            height="32"
            fill="white"
            transform="translate(147 287)"
          />
        </clipPath>
        <clipPath id="clip2_4700_81393">
          <rect
            width="186"
            height="32"
            fill="white"
            transform="translate(148 194)"
          />
        </clipPath>
        <clipPath id="clip3_4700_81393">
          <rect
            width="190"
            height="32"
            fill="white"
            transform="translate(147 72)"
          />
        </clipPath>
        <clipPath id="clip4_4700_81393">
          <rect
            width="155.362"
            height="32.4496"
            fill="white"
            transform="translate(148 107)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SankeyChart;
