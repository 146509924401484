import { formatOktaHeatmap, getResponseGet } from "../utils";

const getOktaHeatmap = async () => {
  let oktaHeatMapData = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponseGet("/okta-heatmap");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      oktaHeatMapData.result = formatOktaHeatmap(data);
    }
  } catch (error) {
    oktaHeatMapData.error = error;
  }

  return oktaHeatMapData;
};

export default getOktaHeatmap;
