import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { saveAs } from "file-saver";

import ArrowBackIosNewOutlined from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Box } from "@mui/material";

import { FadeInSection } from "../../../components";
import { DownloadIcon } from "../../../utils/icons";

const InfographicDetails = () => {
  const [infographicUrl, setInfographicUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [title, setTitle] = useState("");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const url = params.get("url");
    const pdf = params.get("pdf");
    const title = params.get("title");

    if (url) {
      setInfographicUrl(decodeURIComponent(url));
    }
    if (pdf) {
      setPdfUrl(decodeURIComponent(pdf));
    }
    if (title) {
      setTitle(decodeURIComponent(title));
    }
  }, [location.search]);

  if (!infographicUrl) {
    return (
      <div className="flex items-center justify-center mt-10 text-white">
        Loading...
      </div>
    );
  }

  const downloadInfographic = () => {
    if (pdfUrl) {
      saveAs(pdfUrl, `${title}.pdf`);
    } else {
      alert("PDF not available for download.");
    }
  };

  return (
    <div className="overflow-hidden text-white bg-transparent max-w-screen">
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{ xs: "60px", md: "-80px" }}
          padding="0 28px"
        >
          <div className="w-full md:max-w-78.75 mx-auto md:pt-10 md:pl-3">
            <Link to="/resources/infographics">
              <div className="duration-200 ease-in transform hover:text-gray">
                <span className="pr-2.5">
                  <ArrowBackIosNewOutlined className="resource-icon-resource-hub" />
                </span>
                Resource Hub
              </div>
            </Link>
            <div role="heading" aria-level="2" className="text-h2 my-2.5">
              {title ? title : "Infographic Details"}
            </div>

            <div className="py-10">
              <div className="flex justify-center my-10 scale-150">
                <img src={infographicUrl} alt={title} />
              </div>
            </div>

            <div className="flex items-center justify-end">
              {/* <div
                role="heading"
                aria-level="5"
                className="flex items-center text-h5"
              >
                <span className="pr-1">
                  <CalendarTodayOutlined className="resource-icon-info" />
                </span>
                {formatDateFive(new Date())}
              </div> */}
              <div
                tabIndex="0"
                role="button"
                onClick={downloadInfographic}
                className="flex items-center space-x-0.25 cursor-pointer"
              >
                <DownloadIcon />
                <div>Download PDF</div>
              </div>
            </div>
          </div>
        </Box>
      </FadeInSection>
    </div>
  );
};

export default InfographicDetails;
