import React, { useEffect, useState } from "react";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { AutoGraphOutlinedIcon } from "../../../../../../../../utils";

const CustomDot = ({ cx, cy, payload, pastTests, setSelectedTest }) => {
  const [associatedTest, setAssociatedTest] = useState(null);
  const [showDot, setShowDot] = useState(false); // Default is false.

  const handleClickCustomDot = () => {
    if (associatedTest && setSelectedTest) {
      setSelectedTest(associatedTest);
    }

    const element = document.getElementById("card-test-calendar");

    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.scrollY - 100; // Adjust scroll position 400px before the element

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (pastTests) {
      const matchedTests = pastTests.filter((test) => {
        const testDateObj = new Date(test.date.replace(/-/g, "/"));
        const payloadDateObj = new Date(payload.date.replace(/-/g, "/"));

        return (
          testDateObj.getDate() === payloadDateObj.getDate() &&
          testDateObj.getMonth() === payloadDateObj.getMonth() &&
          testDateObj.getFullYear() === payloadDateObj.getFullYear()
        );
      });

      if (matchedTests.length > 0) {
        setShowDot(true);
        setAssociatedTest(matchedTests[0]); // Store the first matching test.
      } else {
        setShowDot(false);
      }
    }
  }, [pastTests, payload.date]);

  if (!cx || !cy || !payload || !pastTests) return null; // Prevent errors.

  if (!showDot) {
    return null; // Only render dots for matching dates.
  }

  return (
    <g
      className="cursor-pointer"
      style={{ cursor: "pointer" }}
      transform={`translate(${cx - 6}, ${cy - 6})`} // Adjust for icon positioning
      pointerEvents="all"
      onClick={handleClickCustomDot}
    >
      <circle cx={6} cy={6} r={16} fill="transparent" />

      {/* Circle as a background for visibility */}
      <circle
        cx={6}
        cy={6}
        r={12}
        fill="#232221"
        stroke="white"
        strokeWidth={2}
      />

      {/* Render the custom SVG icon inside the group */}
      <AutoGraphOutlinedIcon />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, color }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="bg-green py-[2px] px-[8px] rounded-small"
        style={{ background: color }}
      >
        <p className="font-semibold text-black body-emphasized">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const CustomYAxisTick = ({ x, y, payload }) => {
  if (x !== null && y !== null && payload.value !== null) {
    return (
      <g transform={`translate(0, ${y + 4})`}>
        <text
          x={0}
          y={0}
          fill="#C2BFB8"
          fontSize="11px"
          fontWeight="500"
          letterSpacing="1.32px"
        >
          {payload.value}
        </text>
      </g>
    );
  }
  return null;
};

const RiskScoreLineChart = ({
  riskScoreOverTime,
  color,
  pastTests,
  setSelectedTest,
}) => {
  const [lineChartData, setLineChartData] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const getXAxisTicks = () => {
    if (lineChartData.length === 0 || !lineChartData[0]) {
      return [];
    }

    let ticks = [lineChartData[0].date];

    for (let i = 1; i < riskScoreOverTime.length; i++) {
      const matches = riskScoreOverTime[i].date.match(/\d+/);
      const date = matches ? parseInt(matches[0], 10) : null;

      if (date && date === 1) {
        ticks.push(riskScoreOverTime[i].date);
      }
    }

    return ticks;
  };

  useEffect(() => {
    if (riskScoreOverTime && lineChartData !== riskScoreOverTime) {
      setLineChartData(riskScoreOverTime);
    }
  }, [riskScoreOverTime, lineChartData]);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%" minHeight="240px">
        <LineChart data={lineChartData}>
          <CartesianGrid stroke="rgba(194, 191, 184, 0.35)" vertical={false} />
          <XAxis
            dataKey="date"
            axisLine={false}
            stroke="#C2BFB8"
            tick={{
              fontSize: "11px",
              fontWeight: "500",
              letterSpacing: "1.32px",
            }}
            tickLine={false}
            ticks={getXAxisTicks()}
            tickFormatter={(value) => {
              const date = new Date(value + ", 2020");

              const month = date
                .toLocaleString("en-US", { month: "short" })
                .toUpperCase();
              const year = date.getFullYear().toString().slice(2); // Extract last two digits of year.

              return `${month} ${year}`;
            }}
          />
          <YAxis
            axisLine={false}
            domain={[0, 100]}
            tick={<CustomYAxisTick />}
            tickLine={false}
            tickCount={6}
          />
          <Tooltip
            content={<CustomTooltip color={color} />}
            cursor={{
              stroke: color,
              strokeDasharray: 8,
              strokeWidth: 3,
            }}
          />
          <Line
            type="monotone"
            dataKey="riskScore"
            stroke={color}
            strokeWidth="3px"
            dot={(props) => (
              <CustomDot
                {...props}
                onDotClick={setSelectedPoint}
                pastTests={pastTests}
                setSelectedTest={setSelectedTest}
              />
            )}
            activeDot={{
              stroke: color,
              r: 0,
            }}
            animationDuration={1000}
            animationEasing="linear"
          />
        </LineChart>
      </ResponsiveContainer>
      {selectedPoint && <></>}
    </>
  );
};

export default RiskScoreLineChart;
