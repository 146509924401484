import React from "react";

import { SectionHeader } from "../../common";

const HeaderRiskInsights = ({ isEndUser, secondColumn }) => {
  return (
    <SectionHeader
      title={!isEndUser ? "Risk Insights" : "Your Security Insights"}
      secondColumn={secondColumn}
    />
  );
};

export default HeaderRiskInsights;
