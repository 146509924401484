const formatCrowdstrikeLogUsers = (apiResponse) => {
  if (!apiResponse || Object.keys(apiResponse).length === 0) {
    return [];
  }

  let formattedUsers = [];

  if (
    apiResponse.provisioned_records &&
    apiResponse.provisioned_records.length > 0
  ) {
    const provisionedUsers = apiResponse.provisioned_records.map((user) => ({
      host_id: user.host_id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.last_login_email,
      initials: `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`,
      provision_status: "Provisioned",
      os_version: user.os_version || "N/A",
      os_product_name: user.os_product_name || "N/A",
      last_login_timestamp: user.last_login_timestamp || "N/A",
    }));

    formattedUsers = formattedUsers.concat(provisionedUsers);
  }

  if (
    apiResponse.not_provisioned_records &&
    apiResponse.not_provisioned_records.length > 0
  ) {
    const notProvisionedUsers = apiResponse.not_provisioned_records.map(
      (user) => ({
        host_id: user.host_id,
        email: user.last_login_email,
        firstName: user.first_name,
        lastName: user.last_name,
        initials: `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`,
        provision_status: "Not Provisioned",
        os_version: user.os_version || "N/A",
        os_product_name: user.os_product_name || "N/A",
        last_login_timestamp: user.last_login_timestamp || "N/A",
      }),
    );

    formattedUsers = formattedUsers.concat(notProvisionedUsers);
  }

  return formattedUsers;
};
export default formatCrowdstrikeLogUsers;
