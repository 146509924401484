import React, { useEffect, useState } from "react";

import { SankeyChart } from "./utils";

import {
  CardHeader,
  CardWithLoadingState,
  DataTable,
  LoadingState,
  Tabs,
} from "../../../../../common";

import { headers } from "../../../../../../utils/constants/platform/card-interactions";

function CardInteractions({
  interactions,
  usersInteraction,
  selectedDays,
  onClickInteraction,
  email,
}) {
  const [animatedTotalInteractions, setAnimatedTotalInteractions] = useState(0);
  const [selectedInteraction, setSelectedInteraction] = useState({
    id: "phished",
    name: "Clicked Link",
  }); // phished is default.
  const [selectedInteractionTotal, setSelectedInteractionTotal] = useState(0);

  // All interactions (not including Links Clicked/phished)
  const [allInteractions, setAllInteractions] = useState([]);

  const handleClickInteraction = async (interaction, total) => {
    setSelectedInteraction(interaction);
    setSelectedInteractionTotal(total);
    await onClickInteraction(interaction.id, selectedDays);
  };

  const handleClickInteractionTab = (tab) => {
    handleClickInteraction(tab, tab.total);
  };

  useEffect(() => {
    if (interactions && interactions.totalInteractions) {
      const allInteractions = [
        {
          id: "phished",
          name: "Clicked Link",
          total: interactions.totalInteractionsClicked
            ? interactions.totalInteractionsClicked
            : 0,
        },
        {
          id: "scanned_qr",
          name: "Scanned QR Code",
          total: interactions.totalInteractionsQRCodesScanned
            ? interactions.totalInteractionsQRCodesScanned
            : 0,
        },
        {
          id: "hybrid",
          name: "Hybrid Tests",
          total: interactions.totalInteractionsHybridTests
            ? interactions.totalInteractionsHybridTests
            : 0,
        },
        {
          id: "quished",
          name: "SMS Link Clicked",
          total: interactions.totalInteractionsSMSLinkClicked
            ? interactions.totalInteractionsSMSLinkClicked
            : 0,
        },
        {
          id: "key_down",
          name: "Entered Credentials",
          total: interactions.totalInteractionsKeyDown
            ? interactions.totalInteractionsKeyDown
            : 0,
        },
        {
          id: "data_entered",
          name: "Submitted Credentials",
          total: interactions.totalInteractionsDataEntered
            ? interactions.totalInteractionsDataEntered
            : 0,
        },
        {
          id: "mfa_entered",
          name: "Entered MFA",
          total: interactions.totalInteractionsMFAEntered
            ? interactions.totalInteractionsMFAEntered
            : 0,
        },
        {
          id: "download_attachment",
          name: "Download Attachments",
          total: interactions.totalInteractionsDownloadAttachments
            ? interactions.totalInteractionsDownloadAttachments
            : 0,
        },
        {
          id: "smtp_reply",
          name: "Responded To Phishing",
          total: interactions.totalInteractionsResponded
            ? interactions.totalInteractionsResponded
            : 0,
        },
        {
          id: "watchtower_reported",
          name: "Reported to Watchtower",
          total: interactions.totalInteractionsReported
            ? interactions.totalInteractionsReported
            : 0,
        },
      ]; // Do not include tests ignored for clicking on that interaction.

      setAllInteractions(allInteractions);

      let start = 0;
      const duration = 1000;
      const increment =
        Math.abs(interactions.totalInteractions) / (duration / 50);

      const interval = setInterval(() => {
        start += increment;

        if (start >= Math.abs(interactions.totalInteractions)) {
          setAnimatedTotalInteractions(interactions.totalInteractions);
          clearInterval(interval);
        } else {
          setAnimatedTotalInteractions(start);
        }
      }, 50);

      return () => clearInterval(interval);
    } else if (
      (!interactions || !interactions.totalInteractions) &&
      animatedTotalInteractions !== 0
    ) {
      setAnimatedTotalInteractions(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);

  useEffect(() => {
    if (email === "david@dune.demo" && !selectedInteraction) {
      setSelectedInteraction({ id: "phished", name: "Links Clicked" });
      setSelectedInteractionTotal(2735);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <>
      <CardWithLoadingState
        loadingVariable={interactions}
        minHeight={interactions ? 0 : "739.73px"}
      >
        <CardHeader
          header="User Interaction"
          tooltip="View real-time notifications of user activities, including clicks, submitted credentials, document downloads, and more."
        />
        {interactions &&
          interactions.totalInteractions !== null &&
          interactions.totalInteractions !== undefined && (
            <div>
              <div>
                <p
                  role="heading"
                  aria-level="2"
                  className="font-medium leading-none h2"
                >
                  {Math.round(
                    Math.abs(animatedTotalInteractions),
                  ).toLocaleString("en-US")}
                </p>
              </div>
              <div className="mt-0.625">
                <p className="card-header">
                  Total Interaction
                  {interactions && interactions.totalInteractions !== 1
                    ? "s"
                    : ""}
                </p>
              </div>
            </div>
          )}
        {interactions && (
          <div className="mt-0.5">
            <SankeyChart
              selectedInteraction={selectedInteraction}
              totalInteractionsClicked={interactions.totalInteractionsClicked}
              totalInteractionsQRCodesScanned={
                interactions.totalInteractionsQRCodesScanned
              }
              totalInteractionsHybridTests={
                interactions.totalInteractionsHybridTests
              }
              totalInteractionsSMSLinkClicked={
                interactions.totalInteractionsSMSLinkClicked
              }
              totalInteractionsKeyDown={interactions.totalInteractionsKeyDown}
              totalInteractionsDataEntered={
                interactions.totalInteractionsDataEntered
              }
              totalInteractionsMFAEntered={
                interactions.totalInteractionsMFAEntered
              }
              totalInteractionsDownloadAttachments={
                interactions.totalInteractionsDownloadAttachments
              }
              totalInteractionsResponded={
                interactions.totalInteractionsResponded
              }
              totalInteractionsNoEngagement={
                interactions.totalInteractionsNoEngagement
              }
              totalInteractionsReported={interactions.totalInteractionsReported}
              onClickInteraction={handleClickInteraction}
            />
          </div>
        )}
        <div className="mt-1">
          <Tabs
            data={allInteractions}
            selectedTab={selectedInteraction}
            setSelectedTab={handleClickInteractionTab}
          />
        </div>
        <div className="mt-0.5 min-h-[347px] flex items-center justify-center">
          {usersInteraction ? (
            <DataTable
              data={usersInteraction}
              headers={headers}
              defaultOrder="desc"
              defaultOrderBy="riskScore"
              rowsPerPage={5}
              fixedTotal={
                email === "david@dune.demo" && selectedInteractionTotal
                  ? selectedInteractionTotal
                  : null
              }
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <LoadingState message="Loading" />
            </div>
          )}
        </div>
      </CardWithLoadingState>
    </>
  );
}

export default CardInteractions;
