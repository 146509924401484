import React, { useEffect, useRef, useState } from "react";

import { getUser } from "../../../api";

import {
  CardAnomalousActivityTracker,
  CardAnomalousFileSharing,
  CardAverageHourlyUserLogin,
  CardChangeFactors,
  CardConfigureTesting,
  CardDeviceList,
  CardDeviceProtectionStatus,
  CardEmployeesByDepartment,
  CardHeadsUp,
  CardInteractions,
  CardLoginActivity,
  CardMFAProtectionStatus,
  CardProfileDepartment,
  CardProfileUser,
  CardProtectionStatusUser,
  CardQuickInsights,
  CardRecentTrainingActivity,
  CardRiskEmployees,
  CardRiskScore,
  CardRiskScoreOverTime,
  CardScoreChange,
  CardTestingCalendarForUser,
  CardTestTracker,
  CardTrainingStatus,
  CardUserActivityHeatMap,
  CardUserActivityTicker,
  HeaderRiskInsights,
  HeaderTesting,
  HeaderTestingAndTraining,
  HeaderTraining,
  HeaderUsers,
  TwoColumn,
} from "./utils";

import { headers } from "../../../utils/constants/platform/dashboard";

import { DataTable, Select } from "..";

import {
  pagesPlatform,
  pagesPlatformEndUser,
  pagesPlatformInitialTesting,
  pagesPlatformManager,
} from "../../../constants";

function Dashboard({
  // All pages
  pageTitle,
  riskScore,
  scoreChange,
  riskScoreOverTime, // Excluding direct reports
  areasToImprove, // Excluding direct reports
  onChangeSelectedDays,
  email,

  // Initial testing
  weekNumber,
  totalWeeks,
  totalSimulationsSent,
  initialTestWeeks,
  initialTestsPerWeek,

  // Organization
  interactions,
  usersInteraction,
  highestRiskUsers,
  lowestRiskUsers,
  departments, // Also for department
  testDates,
  selectedDateForTestTracker,
  onChangeSelectedDateForTestTracker,
  testAssets,
  trainingCompletion, // Also for department
  anomalousActivityStatuses,
  loginActivity,
  anomalousFileSharingActivity,
  oktaMFAProtectionStatuses,
  mfaProtectionStatuses,
  deviceProtectionStatuses,
  userActivityHeatmap,
  oktaHeatMapData,
  averageHourlyUserLogin,
  oktaHourlyUserLogin,
  anomalousLoginBehavior,
  inactiveUsers,
  onClickInteraction,

  // Departments
  totalUsers,
  employeesByDepartment,

  // Users
  profileData,
  deviceList,
  learningModulesActivity,
  onLoadTestHTML, // Also for end user
  pastTests,
  selectedTest,
  setSelectedTest,
  protectionStatuses,
  userActivities,

  // End user
  showHeadsUp,

  // Manager
  users,
}) {
  const sections = [
    {
      name: "Risk Insights",
      ref: useRef(null),
    },
    {
      name: "User Activity",
      ref: useRef(null),
    },
    {
      name: "Testing and Training",
      ref: useRef(null),
    },
  ]; // Only used for organization

  const userActivityUsers = [
    { email: "david@dune.demo" },
    { email: "lilasmith@dune.dynamics" },
  ];

  const isUserActivityUser = userActivityUsers.some(
    (user) => user.email === email,
  );

  // Design
  const [color, setColor] = useState(null);
  const [riskLevel, setRiskLevel] = useState("");

  // Days filter
  const [selectedDays, setSelectedDays] = useState(90); // Default value is 90.

  // End user booleans
  const [isCloseHeadsUp, setIsCloseHeadsUp] = useState(false);

  // Tabs
  // const [selectedTab, setSelectedTab] = useState(sections[0]); // Default value is Risk Insights.

  const handleChangeSelectedDays = async (event) => {
    console.log(event.target.value);
    setSelectedDays(event.target.value);
    await onChangeSelectedDays(event.target.value);
  };

  const handleClickCloseHeadsUp = () => {
    setIsCloseHeadsUp(true);
  };

  const [organization, setOrganization] = useState("");

  // Only for organization
  // const handleClickTabOrganization = (tab) => {
  //   setSelectedTab(tab);

  //   // Scroll to the corresponding section with an offset
  //   const targetSection = sections.find((section) => section.name === tab.name);

  //   if (targetSection?.ref?.current) {
  //     const sectionTop = targetSection.ref.current.offsetTop;

  //     window.scrollTo({
  //       top: sectionTop - 140,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   const handleIntersection = (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const visibleSection = sections.find(
  //           (section) => section.ref.current === entry.target,
  //         );
  //         if (visibleSection) {
  //           setSelectedTab(visibleSection);
  //         }
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, {
  //     root: null,
  //     threshold: 0,
  //   });

  //   sections.forEach((section) => {
  //     if (section.ref.current) {
  //       observer.observe(section.ref.current);
  //     }
  //   });

  //   return () => {
  //     sections.forEach((section) => {
  //       if (section.ref.current) {
  //         observer.unobserve(section.ref.current);
  //       }
  //     });
  //   };
  // }, []);

  useEffect(() => {
    if (riskScore) {
      if (riskScore > 75) {
        // Severe
        setColor("#EF3C1F");
        setRiskLevel("Severe");
      } else if (riskScore > 50) {
        // High
        setColor("#F59A31");
        setRiskLevel("High");
      } else if (riskScore > 25) {
        // Moderate
        setColor("#FEE442");
        setRiskLevel("Moderate");
      } else {
        // Low
        setColor("#00FF82");
        setRiskLevel("Low");
      }
    }
  }, [riskScore]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getUser();
      if (response) {
        setOrganization(response.result.organization);
      }
    };

    fetchOrganization();
  }, []);

  return (
    <div>
      {
        // <div className="sticky z-40 -mt-2 pt-1 top-[69px] bg-gray-dark hidden md:block">
        //   <Tabs
        //     data={sections}
        //     selectedTab={selectedTab}
        //     setSelectedTab={handleClickTabOrganization}
        //   />
        // </div>
      }
      {/* Department Information (Department Profile) Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mb-2">
          <CardProfileDepartment totalUsers={totalUsers} />
        </div>
      )}
      {/* Heads Up Card */}
      {pageTitle === pagesPlatformEndUser.INSIGHTS &&
        showHeadsUp &&
        !isCloseHeadsUp && (
          <div className="mb-2">
            <CardHeadsUp onClickClose={handleClickCloseHeadsUp} />
          </div>
        )}
      {/* User Profile Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && (
        <div className="mb-2">
          <CardProfileUser profileData={profileData} />
        </div>
      )}
      {/* Current Date and Days Filter */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div
          className="flex items-center justify-between -mt-2"
          ref={sections[0].ref}
        >
          <HeaderRiskInsights
            isEndUser={pageTitle === pagesPlatformEndUser.INSIGHTS}
            secondColumn={
              <div>
                <div className="flex items-center justify-end">
                  <div className="relative ml-2">
                    <Select
                      value={selectedDays}
                      options={[
                        {
                          name: "Last 30 days",
                          value: 30,
                        },
                        {
                          name: "Last 60 days",
                          value: 60,
                        },
                        {
                          name: "Last 90 days",
                          value: 90,
                        },
                        {
                          name: "All Time",
                          value: "All Time",
                        },
                      ]}
                      onChange={handleChangeSelectedDays}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}
      {/* Risk Insights and Trends (Risk Score, Score Change, Risk Score Over Time, Areas to Improve Cards) */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.USERS ||
        pageTitle === pagesPlatformManager.YOUR_REPORTS ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="flex flex-col w-full mt-2 md:flex-row">
          <div className="flex flex-col max-w-full md:max-w-[210px] mb-2 md:mb-0 md:mr-1 w-full">
            <div className="flex justify-between flex-grow w-full md:flex-col">
              <div className="w-full">
                <CardRiskScore
                  riskScore={riskScore}
                  riskLevel={riskLevel}
                  description={
                    riskScore !== 0
                      ? pageTitle ===
                        pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                        ? riskLevel === "Low"
                          ? "The organization has a low risk score, reflecting strong security overall. Keep up the training."
                          : riskLevel === "Moderate"
                            ? "The organization is doing well, but focused training across teams can help lower risk further."
                            : riskLevel === "High"
                              ? "The organization's risk is high. Targeted training is necessary to reduce vulnerabilities quickly."
                              : "The organization is at severe risk. Immediate action is needed to address these vulnerabilities."
                        : pageTitle ===
                            pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                          ? riskLevel === "Low"
                            ? "Great job! This department is showing strong security practices. Keep up the training to maintain this level."
                            : riskLevel === "Moderate"
                              ? "The department is on track. A bit more focused training can help lower their risk further."
                              : riskLevel === "High"
                                ? "This department's risk is high. Prioritizing targeted training will help reduce their vulnerabilities."
                                : "This department faces severe risks. Immediate training is needed to improve their security posture."
                          : pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                            ? riskLevel === "Low"
                              ? "This user has a low risk score. Encourage them to maintain their good habits by continuing regular training."
                              : riskLevel === "Moderate"
                                ? "This user's risk score is moderate. Additional training will help them strengthen their security posture."
                                : riskLevel === "High"
                                  ? "This user's risk score is high. Prompt them to complete targeted training to reduce risks effectively."
                                  : "Immediate action is needed and recommend focused training to mitigate their vulnerabilities."
                            : pageTitle === pagesPlatformManager.YOUR_REPORTS
                              ? riskLevel === "Low"
                                ? "The team has a low risk score, reflecting strong security overall. Keep up the training."
                                : riskLevel === "Moderate"
                                  ? "The team is doing well, but focused training can help lower risk further."
                                  : riskLevel === "High"
                                    ? "The team's risk is high. Targeted training is necessary to reduce vulnerabilities quickly."
                                    : "The team is at severe risk. Immediate action is needed to address these vulnerabilities."
                              : pageTitle === pagesPlatformEndUser.INSIGHTS
                                ? riskLevel === "Low"
                                  ? "Your score is low, reflecting strong security habits. Stay engaged with training to maintain this level."
                                  : riskLevel === "Moderate"
                                    ? "You're on the right track. A bit more focus on training can help reduce your risk further."
                                    : riskLevel === "High"
                                      ? "Your score is high. Completing targeted training will help you quickly lower your risk."
                                      : "Your score is severe, but with focused training, you can significantly improve."
                                : ""
                      : "Risk scores will be available on completion of the initial testing period."
                  }
                />
              </div>
              <div className="w-full ml-0.5 md:ml-0 md:mt-0.5">
                <CardScoreChange
                  scoreChange={scoreChange}
                  selectedDays={selectedDays}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow w-full">
            <div className="flex flex-col flex-grow md:flex-row">
              <div className="w-full mb-0.5 md:mb-0">
                <CardRiskScoreOverTime
                  riskScoreOverTime={riskScoreOverTime}
                  color={color}
                  pastTests={pastTests} // This is for users and end users
                  setSelectedTest={setSelectedTest} // This is for users and end users
                />
              </div>
              <div className="ml-0 md:ml-0.5 md:max-w-[228px] w-full">
                <CardChangeFactors
                  changeFactors={areasToImprove}
                  isEndUser={pageTitle === pagesPlatformEndUser.INSIGHTS}
                />
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {/* Risk Insights for Initial Testing Insights (Risk Score, Quick Insights, Configure Testing Cards) */}
      {pageTitle ===
        pagesPlatformInitialTesting.INSIGHTS.subpages.ORGANIZATION && (
        <div className="flex flex-col w-full md:flex-row">
          <div className="flex flex-col max-w-full md:max-w-[210px] mb-2 md:mb-0 md:mr-1 w-full">
            <div className="flex justify-between flex-grow w-full md:flex-col">
              <CardRiskScore
                riskScore={
                  pageTitle ===
                  pagesPlatformInitialTesting.INSIGHTS.subpages.ORGANIZATION
                    ? 0
                    : riskScore
                }
                riskLevel={riskLevel}
                description="Risk scores will be available on completion of the initial testing period."
              />
            </div>
          </div>
          <div className="flex flex-col flex-grow w-full">
            <div className="flex flex-col flex-grow md:flex-row">
              <div className="w-full mb-0.5 md:max-w-[400px] md:mb-0">
                <CardQuickInsights
                  weekNumber={weekNumber}
                  totalWeeks={totalWeeks}
                  totalSimulationsSent={totalSimulationsSent}
                />
              </div>
              <div className="w-full ml-0 md:ml-1">
                <CardConfigureTesting
                  initialTestWeeks={initialTestWeeks}
                  initialTestsPerWeek={initialTestsPerWeek}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Highest Risk Users and Lowest Risk Users Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <TwoColumn
          firstCard={
            <CardRiskEmployees users={highestRiskUsers} enableDescendingOrder />
          }
          secondCard={<CardRiskEmployees users={lowestRiskUsers} />}
        />
      )}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        (isUserActivityUser || organization === "Synovus") && (
          <div className="mt-3 text-white text-h2">User Activity</div>
        )}
      {/* Anomalous Activity Tracker Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        isUserActivityUser && (
          <div className="mt-2">
            <CardAnomalousActivityTracker
              anomalousActivityStatuses={anomalousActivityStatuses}
            />
          </div>
        )}
      {/* Login Activity and Anomalous File Sharing Activity */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        organization === "Synovus" && (
          <TwoColumn
            firstCard={<CardLoginActivity loginActivity={loginActivity} />}
            secondCard={
              <CardAnomalousFileSharing
                anomalousFileSharingActivity={anomalousFileSharingActivity}
              />
            }
          />
        )}
      {/* MFA Protection Status and Device Protection Status Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        (isUserActivityUser || organization === "Synovus") && (
          <TwoColumn
            firstCard={
              <CardMFAProtectionStatus
                mfaProtectionStatuses={
                  organization === "Synovus"
                    ? oktaMFAProtectionStatuses
                    : mfaProtectionStatuses
                }
              />
            }
            secondCard={
              <CardDeviceProtectionStatus
                deviceProtectionStatuses={deviceProtectionStatuses}
              />
            }
          />
        )}
      {/* User Activity Heat Map and Average Hourly User Login Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        (isUserActivityUser || organization === "Synovus") && (
          <TwoColumn
            firstCard={
              <CardUserActivityHeatMap
                userActivityHeatmap={
                  organization === "Synovus"
                    ? oktaHeatMapData
                    : userActivityHeatmap
                }
              />
            }
            secondCard={
              <CardAverageHourlyUserLogin
                averageHourlyUserLogin={
                  organization === "Synovus"
                    ? oktaHourlyUserLogin
                    : averageHourlyUserLogin
                }
              />
            }
          />
        )}
      {/* Anomalous Login Behavior and Inactive Users Cards */}
      {/* {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        isUserActivityUser && (
          <TwoColumn
            firstCard={
              <CardAnomalousLoginBehavior
                anomalousLoginBehavior={anomalousLoginBehavior}
              />
            }
            secondCard={<CardInactiveUsers inactiveUsers={inactiveUsers} />}
          />
        )} */}
      {/* User Activity Ticker Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
        email === "lilasmith@dune.dynamics" && (
          <div className="mt-2">
            <CardUserActivityTicker userActivities={userActivities} />
          </div>
        )}
      {/* MFA Protection Status and Device Protection Status Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
        email === "lilasmith@dune.dynamics" && (
          <TwoColumn
            firstCard={
              <CardProtectionStatusUser
                protectionStatuses={protectionStatuses}
              />
            }
            secondCard={<CardDeviceList deviceList={deviceList} />}
          />
        )}
      {/* Users Header */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <HeaderUsers />
      )}
      {/* Users by Department Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mt-2">
          <CardEmployeesByDepartment users={employeesByDepartment} />
        </div>
      )}
      {/* Testing and Training Header */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        (email !== "david@dune.demo" &&
          pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS)) && (
        <div ref={sections[2].ref}>
          <HeaderTestingAndTraining />
        </div>
      )}
      {/* User Interactions Card */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle ===
          pagesPlatformInitialTesting.INSIGHTS.subpages.ORGANIZATION) && (
        <div className="mt-2">
          <CardInteractions
            interactions={interactions}
            usersInteraction={usersInteraction}
            selectedDays={selectedDays}
            onClickInteraction={onClickInteraction}
            email={email}
          />
        </div>
      )}
      {/* Test Tracker Card */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pagesPlatformInitialTesting.INSIGHTS.subpages.ORGANIZATION) &&
        testDates && (
          <div className="mt-2">
            <CardTestTracker
              testDates={testDates}
              selectedDate={selectedDateForTestTracker}
              onChangeSelectedDate={onChangeSelectedDateForTestTracker}
              testAssets={testAssets}
            />
          </div>
        )}
      {/* Training Status Card (for organization and departments) */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        (email !== "david@dune.demo" &&
          pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS)) && (
        <TwoColumn
          firstCard={
            <CardTrainingStatus trainingCompletion={trainingCompletion} />
          }
          // secondCard={<CardRiskEmployees users={lowestRiskUsers} />}
        />
      )}
      {/* Testing Header */}
      {((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && <HeaderTesting />}
      {/* Testing Activity Card */}
      {((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="mt-2">
          <CardTestingCalendarForUser
            pastTests={pastTests}
            selectedTest={selectedTest}
            setSelectedTest={setSelectedTest}
            onLoadTestHTML={onLoadTestHTML}
          />
        </div>
      )}
      {/* Training Header */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
        Array.isArray(learningModulesActivity) &&
        learningModulesActivity.length > 0 && <HeaderTraining />}
      {/* Testing Activity Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
        Array.isArray(learningModulesActivity) &&
        learningModulesActivity.length > 0 && (
          <div className="mt-2">
            <CardRecentTrainingActivity
              learningModulesActivity={learningModulesActivity}
            />
          </div>
        )}
      {/* Users (Manager) */}
      {pageTitle === pagesPlatformManager.YOUR_REPORTS && (
        <>
          <div className="mt-2">
            <div className="text-2xl font-bold">Users</div>
          </div>
          {users && (
            <div className="mt-0.5">
              <DataTable data={users} headers={headers} defaultOrderBy="name" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
