import React from "react";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const Legend = ({ data }) => {
  const COLORS = ["#00cc66", "#ffb800", "#ff7733", "#e64545"];

  const total = Object.values(data).reduce((sum, value) => sum + value, 0);

  return (
    <div className="flex flex-col gap-0.25">
      {Object.entries(data).map(([name, count], index) => {
        const percentage = total ? ((count / total) * 100).toFixed(1) : 0;

        return (
          <div
            className="flex items-center gap-0.25"
            key={`legend-item-${index}`}
          >
            <div
              className="w-[16px] h-[8px] rounded-[1px]"
              style={{ background: COLORS[index % COLORS.length] }}
            />
            <span className="detailed">
              {capitalizeFirstWords(name.replace(/_/g, " "))}
            </span>
            <span className="detailed">{percentage}%</span>{" "}
            {/* Show percentage */}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
