import React, { useEffect, useRef, useState } from "react";

import {
  getDeviceProtectionStatuses,
  getDevicesByProtectionStatus,

  // For Synovus
  getCrowdstrikeLogCount,
  getCrowdstrikeLogUsers,
  getUser,
} from "../../../api";

import { CardWithLoadingState, DonutChartWithTable } from "../../../components";

const DeviceProtectionStatus = ({
  isInitialLoading,
  email,
  setBreadcrumbs,
}) => {
  const [deviceProtectionStatuses, setDeviceProtectionStatuses] =
    useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    name: "not provisioned",
  });
  const [users, setUsers] = useState(null);

  const [organization, setOrganization] = useState(null);
  const [crowdstrikeLogCount, setCrowdstrikeLogCount] = useState();
  const [crowdstrikeLogUsers, setCrowdstrikeLogUsers] = useState();

  const devicesCacheRef = useRef({});

  useEffect(() => {
    const loadDeviceProtectionStatuses = async () => {
      const getDeviceProtectionStatusesResponse =
        await getDeviceProtectionStatuses();

      if (Object.keys(getDeviceProtectionStatusesResponse.error).length > 0) {
        console.error(getDeviceProtectionStatusesResponse.error.message);
      } else {
        const { deviceProtectionStatuses } =
          getDeviceProtectionStatusesResponse.result;

        setDeviceProtectionStatuses(deviceProtectionStatuses);
      }
    };

    if (!isInitialLoading && email) {
      loadDeviceProtectionStatuses();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const loadDevicesByProtectionStatus = async () => {
      if (selectedStatus.name && devicesCacheRef.current[selectedStatus.name]) {
        setUsers(devicesCacheRef.current[selectedStatus.name].users);

        return;
      }

      const getUsersByMFAProtectionStatusResponse =
        await getDevicesByProtectionStatus(selectedStatus.name);

      if (Object.keys(getUsersByMFAProtectionStatusResponse.error).length > 0) {
        console.error(getUsersByMFAProtectionStatusResponse.error.message);
      } else {
        const { users } = getUsersByMFAProtectionStatusResponse.result;

        setUsers(users);

        // Store the users data in the cache.
        devicesCacheRef.current = {
          ...devicesCacheRef.current,
          [selectedStatus.name]: {
            users,
          },
        };
      }
    };

    if (!isInitialLoading && email) {
      loadDevicesByProtectionStatus();
    }
  }, [isInitialLoading, email, selectedStatus]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "Device Protection Status",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getUser();
      setOrganization(response.result.organization);
    };

    if (!isInitialLoading && email) {
      fetchOrganization();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const fetchCrowdstrikeLogCount = async () => {
      const response = await getCrowdstrikeLogCount();
      // console.log(response.result);
      setCrowdstrikeLogCount(response.result);
    };

    if (!isInitialLoading && email) {
      fetchCrowdstrikeLogCount();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const fetchCrowdstrikeLogUsers = async () => {
      const response = await getCrowdstrikeLogUsers();
      console.log("crowdstrikeuser", response.result);
      setCrowdstrikeLogUsers(response.result);
    };

    if (!isInitialLoading && email) {
      fetchCrowdstrikeLogUsers();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const selectDefaultTab = () => {
      if (organization === "Synovus" && crowdstrikeLogCount) {
        const firstKey = Object.keys(crowdstrikeLogCount)[0];
        if (firstKey && !selectedStatus) {
          setSelectedStatus({ name: firstKey });
        }
      } else if (deviceProtectionStatuses) {
        const firstKey = Object.keys(deviceProtectionStatuses)[0];
        if (firstKey && !selectedStatus) {
          setSelectedStatus({ name: firstKey });
        }
      }
    };

    selectDefaultTab();
  }, [
    crowdstrikeLogCount,
    deviceProtectionStatuses,
    organization,
    selectedStatus,
  ]);

  return (
    <div>
      <div className="text-gray text-h5 mb-2">
        The <span className="font-medium">"Last User"</span> column indicates
        the most recent login on the device but does not indicate device
        ownership.
      </div>
      <CardWithLoadingState loadingVariable={deviceProtectionStatuses}>
        <DonutChartWithTable
          donutChartData={
            organization === "Synovus"
              ? crowdstrikeLogCount || []
              : deviceProtectionStatuses || []
          }
          tableData={
            organization === "Synovus" ? crowdstrikeLogUsers || [] : users
          }
          selectedTab={selectedStatus}
          setSelectedTab={setSelectedStatus}
          title="Total Devices"
          filterField="provision_status"
          defaultOrderBy="email"
          noDataMessage="No devices found"
          headers={[
            {
              id: "name",
              label: "Last User",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "os_version",
              label: "OS Version",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "os_product_name",
              label: "OS Product Name",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "host_id",
              label: "Crowdstrike Host ID",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
          ]}
        />
      </CardWithLoadingState>
    </div>
  );
};

export default DeviceProtectionStatus;
