import { getResponseGet } from "../utils";

const getInteractionsOrganization = async (days) => {
  let interactionsOrganization = {
    result: {
      totalInteractions: 0,
      totalInteractionsClicked: 0,
      totalInteractionsScannedQRCode: 0,
      totalInteractionsHybridTests: 0,
      totalInteractionsSMSLinkClicked: 0,
      totalInteractionsKeyDown: 0,
      totalInteractionsDataEntered: 0,
      totalInteractionsMFAEntered: 0,
      totalInteractionsDownloadAttachments: 0,
      totalInteractionsResponded: 0,
      totalInteractionsNoEngagement: 0,
      totalInteractionsReported: 0,
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/organization_sankey_incidents/${
        days !== null ? `?time_window=${days}` : ""
      }`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      interactionsOrganization.result.totalInteractions = data.total_incidents
        ? data.total_incidents
        : 0;
      interactionsOrganization.result.totalInteractionsClicked =
        data.total_phished_incidents ? data.total_phished_incidents : 0;
      interactionsOrganization.result.totalInteractionsScannedQRCode =
        data.total_scanned_qr_incidents ? data.total_scanned_qr_incidents : 0;
      interactionsOrganization.result.totalInteractionsHybridTests =
        data.total_hybrid_incidents ? data.total_hybrid_incidents : 0;
      interactionsOrganization.result.totalInteractionsSMSLinkClicked =
        data.total_quished_incidents ? data.total_quished_incidents : 0;
      interactionsOrganization.result.totalInteractionsKeyDown =
        data.total_key_down_incidents ? data.total_key_down_incidents : 0;
      interactionsOrganization.result.totalInteractionsDataEntered =
        data.total_data_entered_incidents
          ? data.total_data_entered_incidents
          : 0;
      interactionsOrganization.result.totalInteractionsMFAEntered =
        data.total_mfa_entered_incidents ? data.total_mfa_entered_incidents : 0;
      interactionsOrganization.result.totalInteractionsDownloadAttachments =
        data.total_download_attachment_incidents
          ? data.total_download_attachment_incidents
          : 0;
      interactionsOrganization.result.totalInteractionsResponded =
        data.total_smtp_reply_incidents ? data.total_smtp_reply_incidents : 0;
      interactionsOrganization.result.totalInteractionsNoEngagement =
        data.total_no_action ? data.total_no_action : 0;
      interactionsOrganization.result.totalInteractionsReported =
        data.total_watchtower_reported_incidents
          ? data.total_watchtower_reported_incidents
          : 0;
    }
  } catch (error) {
    interactionsOrganization.error = error;
  }

  return interactionsOrganization;
};

export default getInteractionsOrganization;
