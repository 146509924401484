import { DEMO_LOW_RISK_END_USER } from "../accounts";

const getCompletionDateForAssignedTraining = () => {
  const completionDate = new Date();
  completionDate.setDate(completionDate.getDate() - 7);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const formattedCompletionDate = completionDate
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "/");

  return formattedCompletionDate;
};

const getPastTests = () => {
  let pastTests = [
    {
      id: "1",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Adobe",
    },
    {
      id: "2",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "microsoft",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "Internal",
      difficulty: "Easy",
      attackVector: "Email phishing",
      methodOfAttack: "Impersonation of internal processes",
      impersonator: "Chase",
    },
    {
      id: "3",
      passed: true,
      source: {
        isURLClicked: null,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "ConEdison",
    },
    {
      id: "4",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Easy",
      attackVector: "Email phishing",
      methodOfAttack: "Unexpected financial transactions",
      impersonator: "Delta",
    },
    {
      id: "5",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Display name deception",
      impersonator: "Google",
    },
    {
      id: "6",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Link manipulation",
      impersonator: "Intuit",
    },
    {
      id: "7",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Microsoft",
    },
    {
      id: "8",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Display name deception",
      impersonator: "PayPal",
    },
    {
      id: "9",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Ticketmaster",
    },
    {
      id: "10",
      passed: true,
      source: {
        isURLClicked: false,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "microsoft",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Request for action",
      impersonator: "1Password",
    },
  ];

  const dateForActivity = new Date();
  dateForActivity.setDate(dateForActivity.getDate() - 30);

  const optionsForActivity = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  for (let i = 0; i < pastTests.length; i++) {
    const randomDays = Math.floor(Math.random() * 3 + 1); // Generates 1, 2, or 3 days
    dateForActivity.setDate(dateForActivity.getDate() + randomDays);

    const dayOfWeek = dateForActivity.getDay();

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      dateForActivity.setDate(
        dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2),
      );
    }

    pastTests[i].date = dateForActivity.toLocaleDateString(
      "en-US",
      optionsForActivity,
    );
  }

  // Make the date of the last test today's date.
  const today = new Date();
  pastTests[pastTests.length - 1].date = today.toLocaleDateString(
    "en-US",
    optionsForActivity,
  );

  return pastTests;
};

const getRiskScoreOverTime = () => {
  let riskScoreOverTime = [];

  const currentDate = new Date(); // Start with today's date.
  currentDate.setDate(currentDate.getDate() - 90);

  let riskScore = 78; // Start with a risk score of 78.

  const targetScore = ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE;
  const totalDays = 90;

  const options = {
    month: "short",
    day: "numeric",
  };

  for (let i = 0; i < totalDays; i++) {
    const formattedDate = currentDate.toLocaleDateString("en-US", options);

    let stepChange = (riskScore - targetScore) / (totalDays - i);

    let variability = (Math.random() - 0.5) * stepChange;

    let fluctuation = Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

    riskScore -= stepChange + variability + fluctuation;

    if (i === totalDays - 1 && riskScore > targetScore) {
      riskScore = targetScore;
    } else if (riskScore < targetScore) {
      riskScore = targetScore + Math.random();
    }

    riskScoreOverTime.push({
      date: formattedDate,
      riskScore: Math.round(riskScore),
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return riskScoreOverTime;
};

export const ADMIN_INSIGHTS_USER_LOW_RISK_PROFILE_DATA = {
  firstName: "Aaron",
  lastName: "Chavez",
  initials: "AC",
  email: DEMO_LOW_RISK_END_USER,
  department: "Capital",
  role: "Chief Financial Officer",
  directManager: "",
};

export const ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE = 14;

export const ADMIN_INSIGHTS_USER_LOW_RISK_RISK_SCORE_OVER_TIME =
  getRiskScoreOverTime();

export const ADMIN_INSIGHTS_USER_LOW_RISK_CHANGE_FACTORS = [];

export const ADMIN_INSIGHTS_USER_LOW_RISK_PAST_TESTS = getPastTests();

export const ADMIN_INSIGHTS_USER_LOW_RISK_ASSIGNED_TRAINING = [
  {
    id: "1",
    trainingName: "Domain Spoofing",
    status: "completed",
    completionDate: getCompletionDateForAssignedTraining(),
    justificationSentiment:
      "The user's response is positive and shows engagement with the topic.",
    justificationUnderstanding:
      "The user has a good understanding of the key points discussed in the training, such as verifying sender's email address, avoiding clicking on links in emails, and using strong passwords with two-factor authentication.",
    scoreOverall: 9,
    scoreSentiment: 8,
    userAnswer:
      "Keep an eye out for phishing attempts. Verify senders email address matches the senders name. Do not click on links in email, go to bookmarks or type in address myself. Keep personal data safe. Use strong passwords and 2 factor authentication.",
  },
];

export const ADMIN_INSIGHTS_USER_LOW_RISK_COMPLIANCE_FRAMEWORKS = [
  {
    name: "SOC 2 Type 2",
    progress: true,
  },
  {
    name: "HIPAA",
    progress: true,
  },
  {
    name: "ISO 27001",
    progress: true,
  },
];
