export const customerEmailMap = {
  "H.I.G. Capital": ["hig.com", "higcapital.com"],
  "Stevens IT": ["stevens.edu"],
  HealthEdge: ["healthedge.com"],
  "Scrut VAPT": ["scrut.io"],
  "Culligan Australia": [
    "zipwater.com",
    "zenithwater.co.nz",
    "culligan.com.au",
  ],
  "ALEC Engineering & Contracting": ["alec.ae"],
  "OSF HealthCare": ["osfhealthcare.org", "pointcore.com"],
  "Acme Corporation": ["acme.com", "acme.org"],
  "Akuna Capital": ["akunacaptial.com"],
  AccentCare: ["accentcare.com"],
  "Hugo Boss": ["hugoboss.com"],
  "Dune Security (MICROSOFT)": ["dune.security"],
  "WellLife Network": ["welllifenetwork.org", "psch.org"],
  Culligan: [
    "culligan.com",
    "culligan.net",
    "culligan.it",
    "culligan.co.uk",
    "aquacure.co.uk",
    "culliganwaterco.com",
    "culliganflorida.com",
    "oasis.ie",
    "quenchwater.com",
    "culliganwater.ca",
  ],
  "Morgan & Morgan": ["forthepeople.com"],
  Netlify: ["netlify.com"],
  "GoTo Foods": ["gotofoods.com"],
  "J&J Air Conditioning": ["jjair.net"],
  "European Rolling Shutters": ["errshading.com"],
  "Renascence IT Consulting": ["renitconsulting.com"],
  Invisible: ["invisible.co", "invisible.email"],
  "Airosmith Development": [
    "airosmithdevelopment.com",
    "airosmithengineering.com",
    "aitowers.com",
    "asdwireless.com",
  ],
  "Dune Demo": ["dune.demo"],
  "Stone Keep": ["thestonekeep.com"],
  Synovus: ["synovus.com"],
  Kantata: ["kantata.com"],
  OktaGroupTest: ["dunesecurity.ai"],
  "Craft Ventures": ["craftventures.com"],
  "Warner Music Group": [
    "holisticyber.com",
    "sirerecords.com",
    "wmg.com",
    "atrido.com",
    "rhino.com",
    "getin.com.mx",
    "yroyalties.co.uk",
    "sheer.co.za",
    "arvato-scs.com",
    "geodis.com",
    "bct14.de",
    "cobaltmusic.gr",
    "atlanticstudiowest.com",
    "levelmusic.com",
    "darecordings.com",
    "gcohencorp.com",
    "theroyaltyconsultancy.com",
    "navisite.com",
    "300elektra.com",
    "kainos.com",
    "wmgis.com",
    "warnerchappell.com",
    "schubertmusic.com",
    "clckwrk.com",
    "borlas.ru",
    "asylumrecords.com",
    "wikadiscos.com",
    "parlophone.com",
    "tamnoon.io",
    "publicconsumption.co",
    "presidio.com",
    "parlophonemusic.com",
    "coleskeartists.co.za",
    "musou.gr",
    "canvasbackmusic.com",
    "openplay.co",
    "warnerchappellpm.com",
    "roadrunnerrecords.com",
    "ada-music.com",
    "helicon.co.il",
    "curb.com",
    "nonesuch.com",
    "grfllp.com",
    "nksf.com",
    "ensisrecords.com",
    "tindrummusic.net",
    "getin.es",
    "pacgenesis.com",
    "fabermusic.com",
    "rsmbr.com",
    "long-de.com.tw",
    "ada-music.co.uk",
    "alfremusic.com",
    "dancingbear.hr",
    "spartadistribution.com",
    "fujipacific.co.jp",
    "imgn.media",
    "atlaticrecords.com",
    "warnermusic.ru",
    "decibelsprod.com",
    "warnerrecords.com",
    "300ent.com",
    "threehundred.biz",
    "africori.com",
    "spinninrecords.com",
    "elektra.com",
    "wmgqa.testinator.com",
    "nika.si",
    "alfred.com",
    "10kprojects.com",
    "spinninrecords.nl",
    "atlanticrecords.co.uk",
    "warnermusic.com",
    "arvato.hk",
    "timesgroup.com",
    "afrikaansisgroot.co.za",
    "nichion.co.jp",
    "technicolor.com",
    "dif.productions",
    "wmg.onmicrosoft.com",
    "atlanticrecords.de",
    "uproxx.com",
    "sa-nash.com",
    "12tonemusicgroup.com",
    "craigmandigital.com",
    "p92.hu",
    "themazegroup.com",
    "tastethefloor.es",
    "sonymusic.com",
    "citrincooperman.com",
    "asylumrecords.co.uk",
    "music.wmgis.com",
    "kblmp.com",
    "pragermetis.com",
    "newiscom.net",
    "backoffice-ms.com",
    "glenwoodstudios.com",
    "maxmulia.com",
    "bertelsmann.de",
  ],
  Paychex: ["paychexhcm.com"],
  "Cascade Environmental": ["cascade-env.com"],
  Antler: ["antler.co"],
  "Dune Security": ["dunesecurity.io"],
};

export const motivationalFactors = [
  {
    incomingMotivationalFactor: "fear",
    formattedMotivationalFactor: "Fear",
    info: "Exploits anxiety/emotions by making the users believe their security, finances, or personal information are at risk, prompting them to act impulsively.",
  },
  {
    incomingMotivationalFactor: "urgency",
    formattedMotivationalFactor: "Urgency",
    info: "Creates pressure by imposing a short deadline, making the users feel they must act quickly before they lose access, money, or opportunities.",
  },
  {
    incomingMotivationalFactor: "curiosity",
    formattedMotivationalFactor: "Curiosity",
    info: "Leverages intrigue by presenting unexpected or enticing information that tempts the users to click a link or open an attachment.",
  },
  {
    incomingMotivationalFactor: "Authority",
    formattedMotivationalFactor: "Authority",
    info: "Exploits trust in figures of power, such as executives or IT personnel, making the users feel obligated to comply with a request.",
  },
  {
    incomingMotivationalFactor: "reward",
    formattedMotivationalFactor: "Reward",
    info: "Promises incentives like money, prizes, or exclusive benefits, making the users more likely to engage without verifying legitimacy.",
  },
  {
    incomingMotivationalFactor: "authority",
    formattedMotivationalFactor: "Authority",
    info: "Exploits trust in figures of power, such as executives or IT personnel, making the users feel obligated to comply with a request.",
  },
];
