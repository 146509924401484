import axios from "axios";

const getResponsePostNoAuth = async (
  path,
  requestBody,
  contentType = "application/json",
) => {
  const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}${path}`;
  const headers = {
    "Content-Type": contentType,
  };

  const response = await axios.post(url, requestBody, { headers });

  return response;
};

export default getResponsePostNoAuth;
