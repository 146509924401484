import { DEMO_HIGH_RISK_END_USER } from "../accounts";

const getPastTests = () => {
  let pastTests = [
    {
      id: "1",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: true,
        isKeyDown: true,
        isDataEntered: true,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Adobe",
    },
    {
      id: "2",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "microsoft",
        isVisited: true,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: true,
      internalOrExternal: "Internal",
      difficulty: "Easy",
      attackVector: "Email phishing",
      methodOfAttack: "Impersonation of internal processes",
      impersonator: "Chase",
    },
    {
      id: "3",
      passed: true,
      source: {
        isURLClicked: null,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "ConEdison",
    },
    {
      id: "4",
      passed: true,
      source: {
        isURLClicked: null,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Easy",
      attackVector: "Email phishing",
      methodOfAttack: "Unexpected financial transactions",
      impersonator: "Delta",
    },
    {
      id: "5",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: true,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Display name deception",
      impersonator: "Google",
    },
    {
      id: "6",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: true,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Link manipulation",
      impersonator: "Intuit",
    },
    {
      id: "7",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: true,
        isKeyDown: true,
        isDataEntered: true,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Microsoft",
    },
    {
      id: "8",
      passed: true,
      source: {
        isURLClicked: null,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: false,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Display name deception",
      impersonator: "PayPal",
    },
    {
      id: "9",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "google",
        isVisited: true,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Hard",
      attackVector: "Email phishing",
      methodOfAttack: "Fake technical notifications",
      impersonator: "Ticketmaster",
    },
    {
      id: "10",
      passed: false,
      source: {
        isURLClicked: true,
        isQRScanned: null,
      },
      imposterPortal: {
        subdomain: "microsoft",
        isVisited: true,
        isKeyDown: false,
        isDataEntered: false,
      },
      hasReplied: false,
      internalOrExternal: "External",
      difficulty: "Medium",
      attackVector: "Email phishing",
      methodOfAttack: "Request for action",
      impersonator: "1Password",
    },
  ];

  const dateForActivity = new Date();
  dateForActivity.setDate(dateForActivity.getDate() - 30);

  const optionsForActivity = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  for (let i = 0; i < pastTests.length; i++) {
    const randomDays = Math.floor(Math.random() * 3 + 1); // Generates 1, 2, or 3 days
    dateForActivity.setDate(dateForActivity.getDate() + randomDays);

    const dayOfWeek = dateForActivity.getDay();

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      dateForActivity.setDate(
        dateForActivity.getDate() + (dayOfWeek === 0 ? 1 : 2),
      );
    }

    pastTests[i].date = dateForActivity.toLocaleDateString(
      "en-US",
      optionsForActivity,
    );
  }

  // Make the date of the last test today's date.
  const today = new Date();
  pastTests[pastTests.length - 1].date = today.toLocaleDateString(
    "en-US",
    optionsForActivity,
  );

  return pastTests;
};

const getRiskScoreOverTime = () => {
  let riskScoreOverTime = [];

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 90);

  let riskScore = 50;

  const targetScore = ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE;
  const totalDays = 90;

  const options = {
    month: "short",
    day: "numeric",
  };

  for (let i = 0; i < totalDays; i++) {
    const formattedDate = currentDate.toLocaleDateString("en-US", options);

    let stepChange = (targetScore - riskScore) / (totalDays - i);

    let variability = (Math.random() - 0.5) * stepChange;

    let fluctuation = Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

    riskScore += stepChange + variability + fluctuation;

    if (i === totalDays - 1 && riskScore < targetScore) {
      riskScore = targetScore;
    } else if (riskScore > targetScore) {
      riskScore = targetScore - Math.random();
    }

    riskScoreOverTime.push({
      date: formattedDate,
      riskScore: Math.round(riskScore),
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return riskScoreOverTime;
};

export const ADMIN_INSIGHTS_USER_HIGH_RISK_PROFILE_DATA = {
  firstName: "Michael",
  lastName: "Waite",
  initials: "MW",
  email: DEMO_HIGH_RISK_END_USER,
  department: "Capital",
  role: "Capital Director",
  directManager: "",
};

export const ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE = 97;

export const ADMIN_INSIGHTS_USER_HIGH_RISK_RISK_SCORE_OVER_TIME =
  getRiskScoreOverTime();

export const ADMIN_INSIGHTS_USER_HIGH_RISK_CHANGE_FACTORS = [
  {
    name: "Display Name Deception",
  },
  {
    name: "Fake Technical Notifications",
  },
  {
    name: "Impersonation of Internal Processes",
  },
  {
    name: "Link Manipulation",
  },
];

export const ADMIN_INSIGHTS_USER_HIGH_RISK_PAST_TESTS = getPastTests();

export const ADMIN_INSIGHTS_USER_HIGH_RISK_ASSIGNED_TRAINING = [
  {
    id: "1",
    trainingName: "Domain Spoofing",
    status: "Not Started",
    completionDate: "",
    justificationSentiment: "",
    justificationUnderstanding: "",
    scoreOverall: 0,
    scoreSentiment: 0,
    userAnswer: "",
  },
  {
    id: "2",
    trainingName: "Malicious Attachments",
    status: "Not Started",
    completionDate: "",
    justificationSentiment: "",
    justificationUnderstanding: "",
    scoreOverall: 0,
    scoreSentiment: 0,
    userAnswer: "",
  },
  {
    id: "3",
    trainingName: "Business Email Compromise",
    status: "Not Started",
    completionDate: "",
    justificationSentiment: "",
    justificationUnderstanding: "",
    scoreOverall: 0,
    scoreSentiment: 0,
    userAnswer: "",
  },
];

export const ADMIN_INSIGHTS_USER_HIGH_RISK_COMPLIANCE_FRAMEWORKS = [
  {
    name: "SOC 2 Type 2",
    progress: false,
  },
  {
    name: "HIPAA",
    progress: false,
  },
  {
    name: "ISO 27001",
    progress: false,
  },
];
