import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardHeader,
  CardWithLoadingState,
} from "../../../..";

import { VisMap } from "../../../../../platform/maps-visualization";

const CardUserActivityHeatMap = ({ userActivityHeatmap }) => {
  const navigate = useNavigate();

  const handleClickDetails = () => {
    navigate("/insights/organization/user-login-activity/");
  };

  return (
    <CardWithLoadingState
      loadingVariable={userActivityHeatmap}
      minHeight="517px"
    >
      <CardHeader header="User Activity Heatmap" />
      <div>
        <p className="description text-gray-surface-contrast">
          Visualize login activity across your organization to identify
          geographic patterns and detect unusual access attempts.
        </p>
      </div>
      <div className="flex h-full mt-2.125">
        <VisMap scrollwheel={false} data={userActivityHeatmap} />
      </div>
      <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
          <ButtonOutlinedGreen isSmall onClick={handleClickDetails}>
            Details
          </ButtonOutlinedGreen>
        </div>
      </div>
    </CardWithLoadingState>
  );
};

export default CardUserActivityHeatMap;
