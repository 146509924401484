import React, { useEffect, useState } from "react";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const dayColors = {
  Monday: "rgba(61, 66, 132, 0.80)",
  Tuesday: "rgba(124, 54, 42, 0.80)",
  Wednesday: "rgba(12, 129, 98, 0.80)",
  Thursday: "rgba(127, 76, 182, 0.80)",
  Friday: "rgba(162, 106, 64, 0.80)",
  Saturday: "rgba(26, 114, 130, 0.80)",
  Sunday: "#823d51",
};

const CustomTooltip = ({ active, payload, label, color }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="bg-green py-[2px] px-[8px] rounded-small"
        style={{ background: color }}
      >
        <p className="font-semibold text-black body-emphasized">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const LineChartLogin = ({ data }) => {
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    if (data && lineChartData !== data) {
      setLineChartData(data);
    }
  }, [data, lineChartData]);

  // Get all day fields except "name" and "average".
  const daysOfWeek = Object.keys(data[0]).filter(
    (key) => key !== "time" && key !== "average",
  );

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="320px">
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 8, bottom: -4, left: -10 }}
      >
        <CartesianGrid stroke="rgba(102, 102, 102, 0.50)" />
        <XAxis
          dataKey="time"
          stroke="#ffffff"
          interval={0}
          tickLine={{ stroke: "rgba(102, 102, 102, 0.50)", strokeWidth: 1 }}
          tick={({ x, y, payload }) => {
            const [hour, period] = payload.value.split(" ");

            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  x="0"
                  y="8"
                  dy="16"
                  textAnchor="middle"
                  fill="#ffffff"
                  fontSize="8px"
                >
                  <tspan x="0" dy="0">
                    {hour}
                  </tspan>
                  <tspan x="0" dy="1.2em">
                    {period}
                  </tspan>
                </text>
              </g>
            );
          }}
        />
        <YAxis
          stroke="#ffffff"
          tickLine={false}
          tickCount={4}
          tick={({ x, y, payload }) => {
            return (
              <g transform={`translate(${x}, ${y})`}>
                <text
                  x={-5}
                  y={0}
                  dy="4"
                  textAnchor="end"
                  fill="#ffffff"
                  fontSize="14px"
                >
                  {payload.value}
                </text>
              </g>
            );
          }}
        />
        <Tooltip
          content={<CustomTooltip color="#00ff82" />}
          cursor={{
            stroke: "#00ff82",
            strokeDasharray: 8,
            strokeWidth: 3,
          }}
        />
        {daysOfWeek.map((day, index) => (
          <Line
            type="monotone"
            dataKey={day}
            stroke={dayColors[day]}
            strokeWidth={index === 0 ? "2px" : "1px"}
            dot={false}
            activeDot={{
              stroke: dayColors[day],
              r: 6,
            }}
            animationDuration={1000}
            animationEasing="linear"
            key={`line-login-day-${index}`}
          />
        ))}
        <Line
          type="monotone"
          dataKey="average"
          stroke="#00ff82"
          strokeWidth="3px"
          dot={false}
          activeDot={{
            stroke: "#00ff82",
            r: 6,
          }}
          animationDuration={1000}
          animationEasing="linear"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartLogin;
