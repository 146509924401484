export const leadership = [
  {
    name: "David DellaPelle",
    role: "Co-founder & CEO",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/david.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/daviddellapelle/",
  },
  {
    name: "Michael Waite",
    role: "Co-founder & CTO",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/michael.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/mr-michael-waite/",
  },
  {
    name: "James Alvarez",
    role: "VP of Revenue",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/james.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/james-alvarez/",
  },
  {
    name: "Daniel Redfern",
    role: "Head of Engineering",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/daniel.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/danieljredfern/",
  },
  {
    name: "Kyle Ryan",
    role: "Head of AI & Backend Engineering",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/kyle.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/kylejohnryan/",
  },
  {
    name: "Kaila Mathis",
    role: "Head of Growth",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/kaila.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/kaila-mathis-687395175/",
  },
  {
    name: "Claudia Dalmau Gomez",
    role: "Chief of Staff",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/claudia.jpg",
    linkLinkedIn:
      "https://www.linkedin.com/in/claudia-dalmau-knownas-clauderocks/",
  },
  {
    name: "Rogelio Victorio Jr.",
    role: "Head of Frontend Engineering",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/rogelio.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/rogelio-victorio-jr/",
  },
  {
    name: "Zack Bagliore",
    role: "Head of Channel Partnerships",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/zack.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/zacharybagliore/",
  },
  {
    name: "Casey Zduniak",
    role: "Senior Software Engineer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/casey.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/casey-zduniak-858764169/",
  },
  {
    name: "Jack Vaughan",
    role: "Customer Success Engineer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/jack.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/jack-vaughan-52575b17a/",
  },
  {
    name: "Tarun Ramesh",
    role: "Senior Software Engineer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/tarun.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/tarunaravind2000/",
  },
  {
    name: "Shivam Shekhar",
    role: "Frontend Software Engineer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/shivam.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/shivamshekhar19/",
  },
  {
    name: "Juliëtte van Ravenswaay Claasen",
    role: "Cybersecurity Analyst",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/juliette.png",
    linkLinkedIn:
      "https://www.linkedin.com/in/juli%C3%ABttevanravenswaayclaasen/",
  },
  {
    name: "Paola Di Leo",
    role: "Content Producer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/paola.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/paoladileo/",
  },
  {
    name: "Aaditya Shete",
    role: "Product Designer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/aaditya.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/aadityashete/",
  },
  {
    name: "Jordan Migneault",
    role: "Data Engineer",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/jordan.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/jordan-migneault/",
  },
  {
    name: "Grace Gately",
    role: "Marketing Lead",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/headshots/grace.jpg",
    linkLinkedIn: "https://www.linkedin.com/in/gracegately/",
  },
];

export const investors = [
  {
    name: "Craft",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/craft-logo.png",
  },
  {
    name: "Alumni Ventures",
    picture:
      "https://d3oo9a669kwmx7.cloudfront.net/logos/alumni-ventures-logo.png",
  },
  {
    name: "Antler",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/antler-logo.png",
  },
  {
    name: "Sequoia Scout",
    picture:
      "https://d3oo9a669kwmx7.cloudfront.net/logos/sequoia-scout-logo.png",
  },
  {
    name: "Firestreak",
    picture: "https://d3oo9a669kwmx7.cloudfront.net/logos/firestreak-logo.png",
  },
];
