import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Translate } from "react-auto-translate";

import { getInfographics } from "../../../api/database";
import { FadeInSection, Tabs } from "../../../components";
import { ResourceCard } from "../resources/utils";
import { extractTitleFromKey } from "../../../utils/helper-functions/infographic";

const ResourceInfographics = () => {
  const [categorizedInfographics, setCategorizedInfographics] = useState({});
  const [selectedTab, setSelectedTab] = useState({
    name: "Compliance Training",
  });

  const navigate = useNavigate();

  const redirectToInfographic = (svgUrl, pdfUrl, title) => {
    if (svgUrl) {
      navigate(
        `/resources/infographics/details?url=${encodeURIComponent(
          svgUrl,
        )}&pdf=${encodeURIComponent(pdfUrl)}&title=${encodeURIComponent(title)}`,
      );
    }
  };

  const categorizeInfographics = (data = []) => {
    const categories = {
      "Compliance Training": [],
      "Functional Specific Training": [],
      "Industry Specific Training": [],
      "Security Awareness Training": [],
    };

    data.forEach((infographic) => {
      const key = infographic.key || "";

      if (key.startsWith("svg/Compliance_Training")) {
        categories["Compliance Training"].push(infographic);
      } else if (key.startsWith("svg/Functional_Specific_Training")) {
        categories["Functional Specific Training"].push(infographic);
      } else if (key.startsWith("svg/Industry_Specific_Training")) {
        categories["Industry Specific Training"].push(infographic);
      } else if (key.startsWith("svg/Security_Awareness_Training")) {
        categories["Security Awareness Training"].push(infographic);
      }
    });

    return categories;
  };

  useEffect(() => {
    const getAllInfographicsData = async () => {
      const data = await getInfographics();
      const categorizedData = categorizeInfographics(data.result.files);
      setCategorizedInfographics(categorizedData);

      if (!selectedTab.name && Object.keys(categorizedData).length > 0) {
        setSelectedTab({ name: Object.keys(categorizedData)[0] });
      }
    };

    getAllInfographicsData();
  }, [selectedTab.name]);

  const tabsData = Object.keys(categorizedInfographics).map((category) => ({
    name: category,
  }));

  return (
    <>
      <FadeInSection>
        <div className="flex justify-center mt-2.5 md:mt-5 py-0 px-1.75 text-white">
          <div className="text-center max-w-[800px] w-full">
            <div
              aria-level="1"
              role="heading"
              className="tracking-[1.6px] uppercase text-body"
            >
              <Translate>Resource Hub</Translate>
            </div>
            <div className="text-h2 mt-2.5">
              <Translate>Infographics</Translate>
            </div>
          </div>
        </div>
      </FadeInSection>

      <div className="flex justify-center items-center border border-transparent border-opacity-35 w-fit mx-auto my-4">
        <Tabs
          data={tabsData}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>

      {selectedTab?.name && (
        <FadeInSection key={selectedTab.name}>
          <div className="h-full  text-white max-w-78.75 mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {categorizedInfographics[selectedTab.name]?.map((infographic) => (
                <div
                  key={infographic.key}
                  onClick={() =>
                    redirectToInfographic(
                      infographic.svg_url,
                      infographic.pdf_url,
                      extractTitleFromKey(infographic.key),
                    )
                  }
                >
                  <ResourceCard
                    badge={{ title: "Infographic", color: "#A67C3B" }}
                    imgSrc={infographic.svg_url}
                    title={extractTitleFromKey(infographic.key)}
                  />
                </div>
              ))}
            </div>
          </div>
        </FadeInSection>
      )}
    </>
  );
};

export default ResourceInfographics;
