import { getResponseGet } from "../utils";

const getDLPEvents = async () => {
  const DLPEvents = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponseGet("/dlp-events");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      DLPEvents.result = data;
    }
  } catch (error) {
    DLPEvents.error = error;
  }

  return DLPEvents;
};

export default getDLPEvents;
