import React from "react";

const CrowdStrikeBirdIcon = () => {
  return (
    <svg
      width="37"
      height="28"
      viewBox="0 0 37 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_924_7964)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.636 22.4433C34.8318 22.3815 33.4401 22.1959 31.6463 23C29.8834 23.8351 29.172 23.866 28.3061 23.7732C28.5535 24.2062 29.0793 24.7938 30.6875 24.9175C32.2958 25.0103 33.0999 25.0722 32.2339 26.9588C32.2648 26.4021 32.1102 25.2887 30.5019 25.4743C28.8937 25.6598 28.5226 27.0206 30.2545 27.6701C29.6978 27.7629 28.4916 27.8248 27.6566 25.7835C27.069 26.031 26.172 26.4949 24.5019 25.3196C25.0896 25.5052 25.8009 25.5361 25.8009 25.5361C24.3473 24.8866 22.9246 23.6804 22.0586 22.5361C22.77 23.031 23.5432 23.5258 24.3473 23.6186C23.4195 22.567 21.2545 20.433 18.5947 18.2681C20.2958 19.2887 22.3679 20.9279 25.7081 20.5567C29.0793 20.1856 31.337 19.4743 35.636 22.4433Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.4399 22.0411C19.1822 21.2061 18.7183 21.0205 15.8111 20.402C12.9348 19.7525 10.0894 18.4535 8.17188 16.3813C9.50177 17.2473 12.2543 18.9484 15.0688 18.7628C14.6358 18.2061 13.8626 17.804 12.9038 17.371C13.9554 17.5875 17.1719 18.2989 21.4399 22.0411Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.7903 20.1549C34.7697 20.3405 34.6769 20.6188 34.6769 21.1136C33.8419 20.4951 32.7903 20.1549 32.7903 20.1549ZM20.8522 9.91777C12.3161 7.47447 8.91402 4.41262 6.28516 1.22705C7.49134 4.90746 10.3367 6.23736 13.3986 8.71159C16.4604 11.1858 16.646 12.5157 17.5429 14.0002C19.5532 17.2786 19.8625 17.8044 21.8728 19.2271C24.2233 20.7734 27.0687 19.7219 30.1924 20.2167C33.3161 20.7116 35.8831 23.0621 36.4398 23.959C37.0893 22.8147 35.5429 21.1446 35.1099 20.7116C35.3264 19.1961 31.7388 18.5157 30.347 18.0209C30.0687 17.9281 29.4192 17.7734 29.9759 16.4126C30.7491 14.526 31.5841 12.8868 20.8522 9.91777Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7081 14.4639C16.1514 12.9485 15.1927 11.031 10.5535 8.15466C8.26483 6.70106 4.95555 4.90724 0.594727 0.268066C0.904005 1.50518 2.29576 4.7526 9.22359 8.98972C11.5123 10.5052 14.4813 11.433 16.7081 14.4639Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7082 17.3095C16.1825 16.0415 15.0691 14.4023 10.832 12.0827C8.85258 10.9693 5.51237 9.23733 2.48145 5.95898C2.7598 7.13424 4.15155 9.73218 10.1825 12.9796C11.8526 13.9384 14.667 14.8043 16.7082 17.3095Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_924_7964">
          <rect
            width="36"
            height="27.4639"
            fill="white"
            transform="translate(0.594727 0.268066)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CrowdStrikeBirdIcon;
