import React, { useEffect, useState } from "react";

import { getDLPEvents, getOktaLoginAnomalies, getUser } from "../../../api";

import {
  CardHeader,
  CardWithLoadingState,
  DataTable,
} from "../../../components";
import { anomalousActivityDataHeaders } from "../../../utils/constants/platform/login-activity";
import {
  anomalousActivityIcons,
  loginActivityDataHeaders,
  loginActivityIcons,
} from "../../../utils/constants/platform/anomalous-activity";

const AnomalousActivity = ({ isInitialLoading, setBreadcrumbs }) => {
  const [nonCompliantDocumentSharingData, setNonCompliantDocumentSharingData] =
    useState(null);

  const [organization, setOrganization] = useState(null);
  const [oktaLoginAnomalies, setOktaLoginAnomalies] = useState(null);
  const [dlpEvents, setDlpEvents] = useState(null);

  //! TODO: Replace with API data
  const dummyLoginActivityData = [
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },

    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },

    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },

    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
    {
      firstName: "Abigail",
      lastName: "Hamilton",
      initials: "AH",
      department: "HR",
      email: "abigail.hamilton@test.com",
      riskScore: 36,
      frequency: "33",
    },
  ];

  useEffect(() => {
    const dummyNonCompliantDocumentSharingData = [
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
      {
        firstName: "Abigail",
        lastName: "Hamilton",
        initials: "AH",
        riskScore: 36,
        documentName: "patient_records_2024",
        fileType: "PDF",
        activityTime: "2021-09-01T12:00:00Z",
      },
    ];

    const loadData = () => {
      setNonCompliantDocumentSharingData(dummyNonCompliantDocumentSharingData);
    };

    if (!isInitialLoading) {
      loadData();
    }
  }, [isInitialLoading, setNonCompliantDocumentSharingData]);

  useEffect(() => {
    const fetchOrganization = async () => {
      const response = await getUser();
      setOrganization(response.result.organization);
    };

    if (!isInitialLoading) {
      fetchOrganization();
    }
  }, [isInitialLoading]);

  useEffect(() => {
    const fetchingOktaLoginAnomalies = async () => {
      const response = await getOktaLoginAnomalies();
      setOktaLoginAnomalies(response.result);
    };

    if (!isInitialLoading) {
      fetchingOktaLoginAnomalies();
    }
  }, [isInitialLoading]);

  useEffect(() => {
    const fetchDLPEvents = async () => {
      const response = await getDLPEvents();
      console.log("dlp events response", response.result.records);
      setDlpEvents(response.result.records);
    };

    if (!isInitialLoading) {
      fetchDLPEvents();
    }
  }, [isInitialLoading]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "Anomalous Activity",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  return (
    <div className="text-white">
      <div>
        <CardWithLoadingState
          loadingVariable={
            organization === "Synovus"
              ? oktaLoginAnomalies
              : nonCompliantDocumentSharingData
          }
        >
          <CardHeader header="Anomalous Login Activity" />
          <div className="flex justify-between items-center mb-2.125">
            <p className="text-gray-surface-contrast max-w-[55%]">
              Monitors irregular login behavior, such as unauthorized locations,
              unusual times, or multiple failed attempts, using real-time IAM
              and EDR data to detect potential security threats.
            </p>
            <div className="flex justify-center items-center">
              {loginActivityIcons.map((icon, index) => (
                <div key={index} className="flex items-center ml-1">
                  {icon.icon}
                </div>
              ))}
            </div>
          </div>
          <DataTable
            data={
              organization === "Synovus"
                ? oktaLoginAnomalies
                : dummyLoginActivityData
            }
            headers={loginActivityDataHeaders}
            enableEmailLink={true}
            rowsPerPage={10}
            searchPlaceholder="Search for Anomalous Login Activity"
            defaultOrderBy="riskScore"
            defaultOrder="desc"
          />
        </CardWithLoadingState>
      </div>
      <div className="mt-3">
        <CardWithLoadingState
          loadingVariable={
            organization === "Synovus"
              ? dlpEvents
              : nonCompliantDocumentSharingData
          }
        >
          <CardHeader header="Anomalous File Sharing" />
          <div className="flex justify-between items-center mb-2.125">
            <p className="text-gray-surface-contrast max-w-[55%]">
              Tracks sensitive documents shared within or outside the
              organization, monitored according to your Data Loss Prevention
              (DLP) policies.
            </p>
            <div className="flex justify-center items-center">
              {anomalousActivityIcons.map((icon, index) => (
                <div key={index} className="flex items-center ml-1">
                  {icon.icon}
                </div>
              ))}
            </div>
          </div>
          <DataTable
            data={
              organization === "Synovus"
                ? dlpEvents
                : nonCompliantDocumentSharingData
            }
            headers={anomalousActivityDataHeaders}
            rowsPerPage={10}
            enableEmailLink={true}
            searchPlaceholder="Search for Anomalous File Sharing Activity"
            defaultFilters={[]}
            onExportSelectedReports={() => {}}
            defaultOrderBy="name"
          />
        </CardWithLoadingState>
      </div>
    </div>
  );
};

export default AnomalousActivity;
